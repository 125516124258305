<template>
  <div class="hotzone" :style="res.style">
    <div class="wrap" :style="res.boxStyle">
      <img v-if="res.img" :src="res.img" class="cover" />
      <div v-else class="empty">
        <img class="icon" src="@/assets/icn-banner-default@3x.png" />
      </div>
      <div
        class="zone"
        v-for="(row, index) in res.rows"
        :key="index"
        :style="{
          width: getZoneStyle(row.zone.widthPer),
          height: getZoneStyle(row.zone.heightPer),
          top: getZoneStyle(row.zone.topPer),
          left: getZoneStyle(row.zone.leftPer)
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  methods: {
    getZoneStyle(val) {
      return `${(val || 0) * 100}%`;
    }
  }
};
</script>

<style lang="scss">
.hotzone {
  .wrap {
    position: relative;
    border-style: solid;

    img {
      background: transparent;
      width: 100%;
      height: 100% !important;
      user-select: none;
      -webkit-user-drag: none;
      -webkit-touch-callout: none;
    }

    .zone {
      position: absolute;
      border: 1px dashed blue;
    }

    .empty {
      min-height: 180px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .icon {
        width: 46px;
        height: 60px;
      }
    }
  }
}
</style>
