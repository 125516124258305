<template>
  <div v-if="element" class="config-panel">
    <header>
      <img class="icon" :src="getImageUrl(element.cls)" />
      <span class="name">{{ element.name }}</span>
      <span class="pending"></span>
      <span @click="remove(current)" class="del-btn">
        <a-icon type="delete" />移除
      </span>
    </header>
    <div class="body">
      <component :is="element.cls" :res="element.res" />
    </div>
  </div>
  <div v-else class="config-empty">
    <div class="desc">请点击左侧的模块后再进行配置</div>
    <img src="@/assets/icn-module-empty@3x.png" />
  </div>
</template>

<script>
import Banner from "./banner.vue";
import Card from "./card.vue";
import Notice from "./notice.vue";
import Friend from "./friend.vue";
import Live from "./live.vue";
import Coupon from "./coupon.vue";
import Group from "./group.vue";
import Category from "./category.vue";
import Navi from "./navi.vue";
import Product from "./product.vue";
import TitleText from "./title-text.vue";
import Spacer from "./spacer.vue";
import Search from "./search.vue";
import Richtext from "./richtext.vue";
import Movie from "./movie.vue";
import LiveBar from "./live-bar.vue";
import TimeSale from "./time-sale.vue";
import Counter from "./counter.vue";
import Cat from "./cat.vue";
import Pin from "./pin.vue";
import Kan from "./kan.vue";
import Vip from "./vip.vue";
import HotZone from "./hotzone.vue";
import Share from "./share.vue";
import Qun from "./qun.vue";
import Cube from "./cube.vue";
import Prize from "./prize.vue";

import { getImageUrl } from "@/utils/tool.js";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapState({
      current: state => state.design.current
    }),
    ...mapGetters({
      element: "currentElement"
    })
  },

  methods: {
    ...mapMutations(["deleteElement"]),

    getImageUrl,

    remove(index) {
      this.$confirm({
        title: "操作提示",
        content: "确认移除此模块？",

        onOk: () => {
          this.deleteElement(index);
        },
        onCancel() {}
      });
    }
  },

  components: {
    Banner,
    Card,
    Notice,
    Friend,
    Live,
    Coupon,
    Group,
    Category,
    Navi,
    Product,
    TitleText,
    Spacer,
    Search,
    Richtext,
    Movie,
    LiveBar,
    TimeSale,
    Counter,
    Cat,
    Pin,
    Kan,
    Vip,
    HotZone,
    Share,
    Qun,
    Cube,
    Prize
  }
};
</script>

<style lang="scss">
.config-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;

  .desc {
    font-size: 14px;
    line-height: 1;
  }

  img {
    width: 244px;
    height: 280px;
    margin-top: 30px;
  }
}

.config-panel {
  padding-bottom: 100px;

  > header {
    display: flex;
    align-items: center;
    margin: -10px -30px 20px;
    padding: 10px;
    background: #f5f6f7;

    position: sticky;
    position: --webkit-sticky;
    top: -10px;
    z-index: 9;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
    .name {
      font-size: 16px;
      font-weight: 600;
    }
    .pending {
      flex: 1 1 auto;
    }
    .del-btn {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.66);
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 12px;
        height: 14px;
        margin-right: 6px;
      }
    }
  }

  > .body {
    width: 340px;
    margin-top: 20px;

    .not-need-config {
      margin-top: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: 72px;
        height: 64px;
      }
      .desc {
        margin-top: 38px;
        font-size: 14px;
        line-height: 1;
      }
      .tip {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 1;
        margin-top: 20px;
      }
    }
  }
}

.config-panel section {
  margin-bottom: 30px;

  header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.group {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      padding: 8px 0;
      margin-top: 16px;
      border-top: 1px solid #f4f4f4;
      margin-bottom: 0;
    }

    .drag-handler {
      margin: 0 10px;
      cursor: all-scroll;
    }

    .pending {
      flex: 1 1 auto;
    }

    .opt {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.66);
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 600;

    &.light {
      color: rgba(0, 0, 0, 0.66);
      font-weight: 400;
    }
  }

  .ant-form-item {
    margin: 0;
  }

  /* 紧凑模式 */
  &.compact {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    margin: 0 -10px 10px;
  }
}

.config-panel .config-product {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f6f7f8;
  padding: 10px;
  margin-bottom: 10px;
  user-select: none;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.8;
    .del-icon {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .del-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -10px;
    right: -10px;
    display: none;
  }

  .main {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;

    .cover {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      flex: 0 0 auto;
    }

    .name {
      flex: 1 1 auto;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .sub {
    flex: 0 0 auto;

    .setbig {
      padding-left: 10px;
      height: 40px;
      line-height: 40px;
      position: relative;
      right: -10px;
      font-size: 16px;
      transform: scale(0.625);
      white-space: nowrap;
      font-weight: 600;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      text-align: right;

      &:after {
        content: "设为大图";
      }
    }

    .setbig.active {
      padding-left: 10px;
      color: #549f06;
      &:after {
        content: "已设为大图";
      }
    }
  }
}

.config-panel .upload-big .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 120px;
}
.config-panel .upload-big .ant-upload.ant-upload-select-picture-card img {
  width: 100%;
  max-height: 120px;
  object-fit: cover;
}
</style>
