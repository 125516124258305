<template>
  <div
    class="group-list"
    :class="res.title ? 'has-header' : ''"
    :style="myStyle"
  >
    <div v-if="res.title" class="header">
      <span class="label">{{ res.title }}</span>
      <span class="desc">{{ res.subTitle }}</span>
    </div>
    <div class="list" :class="res.title ? 'has-header' : ''">
      <template v-for="item in rows">
        <div class="item" :key="item.id" :style="res.boxStyle">
          <div class="cover" :style="`background-image:url(${item.img});`" />
          <div class="content">
            <div v-if="res.showTitle" class="name" :style="res.titleStyle">
              {{ item.title }}
            </div>
            <div class="pending"></div>
            <div class="p">
              <div v-if="res.showPrice" class="price" :style="res.priceStyle">
                ￥{{ item.price }}
              </div>
              <div class="pending"></div>
              <template v-if="res.showBuyButton">
                <div v-if="btn.plain" class="buy" :style="res.btnStyle">
                  立即购买
                </div>
                <div
                  v-else
                  class="ic-buy"
                  :class="btn.iconClass"
                  :style="btn.iconStyle"
                />
              </template>
            </div>
          </div>
          <div
            v-if="res.showFlag"
            class="flag"
            :class="`type${res.flagStyle.type}`"
            :style="flag.style"
          >
            {{ res.flagStyle.text }}
            <div class="flag-arrow" :style="flag.arrow" />
          </div>
        </div>
      </template>
      <div v-if="rows.length <= 0" class="empty">
        <img src="@/assets/icn-list-default-1@3x.png" />
      </div>
    </div>
    <div v-if="showMore" class="more">
      <div class="more-btn">显示更多</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object,
    flag: Object,

    rows: Array,
    showMore: Boolean,
    btn: Object,
    myStyle: Object
  },

  computed: {}
};
</script>

<style lang="scss">
.group-list {
  padding: 10px;
  background: #fff;

  .list {
    &.has-header {
      margin-top: 15px;
    }

    .item {
      display: flex;
      position: relative;
      border-radius: 6px;
      border: 1px solid #f5f5f5;
      background-color: #fff;
      margin-bottom: 10px;
      overflow: hidden;

      .cover {
        width: 120px;
        height: 120px;
        flex: 0 0 auto;
        margin-right: 15px;
        background: transparent;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        padding-bottom: 10px;
        overflow: hidden;
        padding: 15px 0;

        .name {
          font-size: 14px;
          line-height: 1.29;
          color: rgba(0, 0, 0, 0.9);

          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 2.6;
        }

        .pending {
          flex: 1 1 auto;
        }

        .p {
          display: flex;
          align-items: center;

          .price {
            font-size: 16px;
            font-weight: 600;
            color: #ec4b30;
          }

          .buy {
            width: 72px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 600;
            color: #fff;
            border-radius: 6px;
            background-color: #ec4b30;
            border: 1px solid #fff;
          }
        }
      }
    }

    .empty img {
      width: 100%;
    }
  }
}
</style>
