<template>
  <div :class="'category-type-' + col">
    <div v-for="item in rows" :key="item.key" class="item">
      <img v-if="item.icon" :src="item.icon" class="cover" />
      <div v-else class="cover space" />
      <div class="name">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    rows() {
      return this.res.rows || [];
    },
    style() {
      return this.res.style || {};
    },

    col() {
      let col = 2;
      const cnt = this.rows.length;
      if (cnt === 4 || cnt >= 7) {
        col = 3;
      }
      return col;
    }
  }
};
</script>

<style lang="scss">
.category-type-2 {
  padding: 15px 10px 0;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 27px 0;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    flex: 0 0 auto;
    width: 33.3%;

    .cover {
      width: 54px;
      height: 54px;

      &.space {
        border-radius: 8px;
        transition: transform 0.25s ease;
        background-color: #edeef0;
      }
    }

    .name {
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: rgba(0, 0, 0, 0.66);
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 62px;
      text-align: center;
    }

    &:nth-child(3n + 1) {
      -ms-flex-align: start;
      align-items: flex-start;
    }
    &:nth-child(3n + 2) {
      -ms-flex-align: center;
      align-items: center;
    }

    &:nth-child(3n) {
      -ms-flex-align: end;
      align-items: flex-end;
    }
  }
}

.category-type-3 {
  padding: 15px 10px 0;
  background: #fff;
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    flex: 0 0 auto;
    width: 25%;

    .cover {
      width: 54px;
      height: 54px;

      &.space {
        border-radius: 8px;
        transition: transform 0.25s ease;
        background-color: #edeef0;
      }
    }

    .name {
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: rgba(0, 0, 0, 0.66);
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 62px;
      text-align: center;
    }
  }
}
</style>
