<template>
  <div class="livebar-wrap" :style="res.style">
    <div class="content" :style="res.boxStyle">
      <div class="icon">
        <img v-if="res.icon" :src="res.icon" />
      </div>
      <div class="title">{{ res.content }}</div>
      <div class="btn" :style="res.btnStyle">查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  }
};
</script>

<style lang="scss">
.livebar-wrap {
  background: #fff;
  padding: 10px;

  .content {
    border-radius: 10px;
    overflow: hidden;
    background: #f00;
    padding: 12px;

    display: flex;
    align-items: center;

    .icon {
      width: 50px;
      height: 50px;
      background-size: cover;
      border-radius: 50%;
      border: 1px solid #fff;
    }

    .title {
      flex: 1;
      font-size: 16px;
      margin: 0 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .btn {
      font-size: 14px;
      width: 60px;
      line-height: 30px;
      text-align: center;
      background: #f00;
      color: #fff;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
</style>
