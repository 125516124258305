<template>
  <div class="qun" :style="res.style">
    <div class="block" :style="res.imageStyle">
      <img v-if="res.img" class="cover" :src="res.img" />
      <div v-else class="empty">
        <img class="icon" src="@/assets/icn-banner-default@3x.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  }
};
</script>

<style lang="scss">
.qun {
  .empty {
    min-height: 180px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .icon {
      width: 46px;
      height: 60px;
    }
  }

  .block {
    overflow: hidden;
    border-style: solid;
  }

  .cover {
    width: 100%;
    background: transparent;
  }
}
</style>
