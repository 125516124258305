<template>
  <div class="prize-wrap" :style="res.style">
    <div class="prize-box" :style="res.boxStyle">
      <Style2 v-if="res.type == 2" :res="res" />
      <Style1 v-else :res="res" />
    </div>
  </div>
</template>

<script>
import Style1 from "./style1.vue";
import Style2 from "./style2.vue";

export default {
  props: {
    res: Object
  },
  components: { Style1, Style2 }
};
</script>

<style lang="scss">
.prize-wrap {
  background: #fff;
  padding: 10px;

  .prize-box {
    background: #fff;
    padding: 18px 10px;
    border-radius: 6px;
    border-style: solid;

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .desc {
      font-size: 14px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 2.6;
      overflow: hidden;
    }

    .btn {
      flex: 0 0 auto;
      width: 85px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      margin-left: 15px;
      background: #30992e;
    }
  }
}
</style>
