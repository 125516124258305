<template>
  <a-form-model class="time-sale-panel">
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
    >
      <p slot="description">
        首次使用请前往「营销互动」 ->
        「限时秒杀」，创建秒杀活动，此组件用于促销展示，不会直接修改商品价格。<router-link
          :to="{ name: 'LimitedDiscountList' }"
          >点击前往限时秒杀 >>
        </router-link>
      </p>
    </a-alert>
    <div></div>
    <section>
      <a-form-model-item label="样式" v-bind="horizonLayout">
        <a-radio-group v-model="res.type" button-style="solid">
          <a-radio-button :value="1">列表</a-radio-button>
          <a-radio-button :value="2">宫格</a-radio-button>
          <a-radio-button :value="3">三列</a-radio-button>
          <a-radio-button :value="4">滚动</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="标题" v-bind="horizonLayout">
        <a-input
          v-model="res.title"
          :maxLength="10"
          placeholder="请输入标题"
          type="text"
        />
      </a-form-model-item>

      <header class="group">数据源</header>
      <a-form-model-item label="数据源" v-bind="horizonLayout">
        <a-select v-model="res.ds" placeholder="请选择">
          <a-select-option :value="0">挑选商品</a-select-option>
          <a-select-option :value="1">挑选活动</a-select-option>
          <a-select-option :value="2">进行中的秒杀活动</a-select-option>
        </a-select>
      </a-form-model-item>

      <template v-if="res.ds === 0">
        <a-form-model-item label="开始时间" v-bind="horizonLayout">
          <a-date-picker
            class="time-picker"
            valueFormat="YYYY-MM-DD HH:mm"
            v-model="res.startTime"
            :disabled-date="disabledStartDate"
            :show-time="{ format: 'h:mm' }"
            format="YYYY-MM-DD HH:mm"
            placeholder="活动开始时间"
          />
        </a-form-model-item>
        <a-form-model-item label="结束时间" v-bind="horizonLayout">
          <a-date-picker
            class="time-picker"
            valueFormat="YYYY-MM-DD HH:mm"
            v-model="res.endTime"
            :disabled-date="disabledEndDate"
            :show-time="{ format: 'h:mm' }"
            format="YYYY-MM-DD HH:mm"
            placeholder="活动结束时间"
          />
        </a-form-model-item>
      </template>
    </section>

    <section v-if="res.ds === 0">
      <header>
        <div class="label light">添加商品（拖动可排序）</div>
      </header>

      <draggable
        v-model="res.rows"
        handle="header"
        @start="drag = true"
        @end="drag = false"
        v-bind="{
          animation: 200
        }"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <section
            v-for="(row, index) in res.rows"
            :key="row.id"
            class="compact"
          >
            <header>
              <div class="label">
                <a-icon type="menu" class="drag-handler" /> 商品
              </div>
              <div class="pending"></div>
              <div @click="delItem(index)" class="opt">
                <a-icon type="close" />
              </div>
            </header>
            <div class="config-product">
              <div class="main" :title="row.title">
                <img class="cover" :src="row.img" />
                <div class="attr">
                  <div class="name">{{ row.title }}</div>
                  <div class="price-row">
                    <div class="price">￥{{ row.price }}</div>

                    <span>
                      <span>秒杀价：</span>
                      <a-input
                        v-model="row.new_price"
                        :maxLength="8"
                        suffix="元"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </transition-group>
      </draggable>

      <a-form-model-item>
        <a-button style="width: 100%" @click="openProductDialog">
          <a-icon type="plus" /> 添加商品
        </a-button>
      </a-form-model-item>
    </section>

    <section v-if="res.ds === 1">
      <header>
        <div class="label light">添加秒杀活动（拖动可排序）</div>
      </header>

      <draggable
        v-model="res.discountRows"
        handle="header"
        @start="drag = true"
        @end="drag = false"
        v-bind="{
          animation: 200
        }"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <section
            v-for="(row, index) in res.discountRows"
            :key="row.id"
            class="compact"
          >
            <header>
              <div class="label">
                <a-icon type="menu" class="drag-handler" /> 商品
              </div>
              <div class="pending"></div>
              <div @click="delDiscountItem(index)" class="opt">
                <a-icon type="close" />
              </div>
            </header>
            <div class="config-product">
              <div class="main" :title="row.title">
                <img class="cover" :src="row.img" />
                <div class="attr">
                  <div class="name">{{ row.title }}</div>
                  <div class="price-row">
                    <div class="price">￥{{ row.price }}</div>
                    <span> 秒杀价：{{ row.new_price }} </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </transition-group>
      </draggable>

      <a-form-model-item>
        <a-button style="width: 100%" @click="openLimitedDiscountDialog">
          <a-icon type="plus" /> 添加秒杀活动
        </a-button>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">促销卡片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <header class="group">
        <div class="label">
          卡片颜色
        </div>
        <div class="pending"></div>
        <div class="opt">
          <a-switch v-model="res.bgColorEnable" />
        </div>
      </header>
      <template v-if="res.bgColorEnable">
        <a-form-model-item label="进行中" v-bind="horizonLayout">
          <color-picker v-model="res.bgColorRunning" />
        </a-form-model-item>
        <a-form-model-item label="未开始" v-bind="horizonLayout">
          <color-picker v-model="res.bgColorStart" />
        </a-form-model-item>
        <a-form-model-item label="已结束" v-bind="horizonLayout">
          <color-picker v-model="res.bgColorEnd" />
        </a-form-model-item>
      </template>

      <header class="group">图片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input
          v-model="res.imageStyle.borderRadius"
          :min="0"
          :max="25"
        />
      </a-form-model-item>

      <header class="group">活动标题</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="斜体" v-bind="horizonLayout">
        <a-radio-group v-model="res.titleStyle.fontStyle" button-style="solid">
          <a-radio-button value="normal">无</a-radio-button>
          <a-radio-button value="italic">
            <a-icon type="italic" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>

    <!-- 商品选择器 -->
    <ProductDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="true"
      only-self-product
    />
    <!-- 秒杀选择器 -->
    <LimitedDiscountDialog
      id="limitedDiscountDialog"
      ref="limitedDiscountDialog"
      v-on:selected="addDiscountItem"
      :multiple="true"
    />
  </a-form-model>
</template>

<script>
import draggable from "vuedraggable";

import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";
import uploadMixin from "@/mixins/upload";

import { randomKey } from "@/utils/tool";

export default {
  data() {
    return {
      drag: false,

      tipsKey: "hide-timesale-tips"
    };
  },
  props: {
    res: Object
  },
  computed: {},

  mixins: [textMixin, tipsMixin, uploadMixin],

  methods: {
    /**
     * 选择商品
     */
    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.res.rows.findIndex(x => x.id === val.id) > -1) {
          continue;
        }

        this.res.rows.push({ ...val, new_price: val.price, key: randomKey() });
      }
    },
    delItem(index) {
      this.res.rows.splice(index, 1);
    },

    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.res.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.res.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    /**
     * 秒杀活动
     */
    openLimitedDiscountDialog() {
      this.$refs.limitedDiscountDialog.show();
    },

    addDiscountItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.res.discountRows.findIndex(x => x.id === val.productId) > -1) {
          continue;
        }

        this.res.discountRows.push({
          ...val,
          id: val.productId
        });
      }
    },
    delDiscountItem(index) {
      this.res.discountRows.splice(index, 1);
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable,

    SliderInput,
    ColorPicker
  }
};
</script>

<style lang="scss">
.time-sale-panel {
  .time-picker {
    width: 100%;
  }

  .config-panel & .config-product {
    margin: 0;
    padding: 0 10px;
    cursor: auto;
  }

  .config-panel & .config-product:hover {
    opacity: 1;
  }

  .attr {
    flex: 1;
    overflow: hidden;

    .price-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;

      .ant-input-affix-wrapper {
        width: 100px;
      }
    }
  }
}
</style>
