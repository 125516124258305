<template>
  <div class="cube-box" :style="res.style">
    <div class="wrap" :style="boxStyle">
      <div
        class="cube"
        v-for="(item, index) in res.cubeList"
        :key="`cube-${index}`"
        :style="{
          width: getCubeSelectedWidth(item) + 'px',
          height: getCubeSelectedHeight(item) + 'px',
          top: getCubeSelectedTop(item) + 'px',
          left: getCubeSelectedLeft(item) + 'px'
        }"
      >
        <img v-if="item.img" :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    res: Object
  },

  computed: {
    cubeWidth() {
      let width = 375;

      const {
        style: { paddingLeft }
      } = this.res;

      width = 375 - parseInt(paddingLeft) * 2;

      return width;
    },
    cubeHeight() {
      return this.cubeWidth;
    },

    // 单元魔方高度
    cubeItemHeight() {
      return (
        (this.cubeHeight - (this.res.density - 1) * parseInt(this.res.gap)) /
        this.res.density
      );
    },

    // 单元魔方宽度
    cubeItemWidth() {
      return (
        (this.cubeWidth - (this.res.density - 1) * parseInt(this.res.gap)) /
        this.res.density
      );
    },

    boxStyle() {
      return {
        ...this.res.boxStyle,

        width: this.cubeWidth + "px",
        height: this.cubeHeight + "px"
      };
    }
  },

  methods: {
    // 计算选中层的宽度
    getCubeSelectedWidth(item) {
      const gap = parseInt(this.res.gap);
      const dist = parseInt(item.end.y) - parseInt(item.start.y);
      return (dist + 1) * this.cubeItemWidth + dist * gap;
    },

    // 计算选中层的高度
    getCubeSelectedHeight(item) {
      const gap = parseInt(this.res.gap);
      const dist = parseInt(item.end.x) - parseInt(item.start.x);
      return (dist + 1) * this.cubeItemHeight + dist * gap;
    },

    // 计算选中层的上边距离
    getCubeSelectedTop(item) {
      const gap = parseInt(this.res.gap);
      return (item.start.x - 1) * (this.cubeItemHeight + gap);
    },

    // 计算选中层的左边距离
    getCubeSelectedLeft(item) {
      const gap = parseInt(this.res.gap);
      return (item.start.y - 1) * (this.cubeItemWidth + gap);
    }
  }
};
</script>

<style lang="scss">
.cube-box {
  .wrap {
    position: relative;

    &:after {
      content: "";
      clear: both;
    }

    .cube {
      position: absolute;
      background-color: #fff;
      border: 1px solid #ebedf0;
      text-align: center;
      color: #7d7e80;
      cursor: pointer;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
