<template>
  <a-form-model>
    <a-form-model-item label="样式">
      <a-radio-group v-model="res.type" button-style="solid">
        <a-radio-button :value="3">横向滚动</a-radio-button>
        <a-radio-button :value="4">纵向滚动</a-radio-button>
        <a-radio-button :value="1">标准</a-radio-button>
        <a-radio-button :value="2">紧凑</a-radio-button>
      </a-radio-group>
    </a-form-model-item>

    <section>
      <a-form-model-item
        label="公告内容"
        :help="res.type === 3 ? '建议150字以内' : null"
      >
        <a-textarea
          :maxLength="200"
          placeholder="请填写公告内容"
          v-model="res.content"
          :auto-size="{ minRows: 4, maxRows: 8 }"
        />
      </a-form-model-item>

      <a-form-model-item label="跳转至">
        <a-row :gutter="10">
          <a-col :span="8">
            <a-select v-model="res.target" placeholder="请选择">
              <a-select-option v-for="t in targetList" :key="t.id" :value="t.id"
                >{{ t.name }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="16">
            <!-- 商品 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="res.target == 1"
              v-model="res.productId"
              placeholder="请选择"
            >
              <a-select-option
                v-for="p in productList"
                :key="p.id"
                :value="p.id"
                >{{ p.title }}
              </a-select-option>
            </a-select>

            <!-- 带货商品 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="res.target == 6"
              v-model="res.productId"
              placeholder="请选择"
            >
              <a-select-option
                v-for="p in productList2"
                :key="p.id"
                :value="p.id"
                >{{ p.title }}
              </a-select-option>
            </a-select>

            <!-- 优惠券 -->
            <a-select
              v-if="res.target == 2"
              v-model="res.couponId"
              placeholder="请选择"
              notFoundContent="请在小商店后台创建优惠券"
            >
              <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>

            <!-- 分类 -->
            <a-tree-select
              v-if="res.target == 3"
              v-model="res.categoryId"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="categoryTree"
              placeholder="请选择"
              notFoundContent="请在小商店后台创建店铺分类"
              tree-default-expand-all
            >
            </a-tree-select>

            <!-- 页面 -->
            <a-select
              v-if="res.target == 4"
              v-model="res.pageId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in pageList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>

            <!-- 拼团 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="res.target == 7"
              v-model="res.pinId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in pinList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 砍价 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="res.target == 8"
              v-model="res.kanId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in kanList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 分享有礼 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="res.target == 9"
              v-model="res.shareId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in shareList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 抽奖 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="res.target == 14"
              v-model="res.prizeId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in prizeList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 招募推荐官 -->
            <a-input
              v-if="res.target == 11"
              v-model="res.planId"
              :max-length="10"
              type="number"
              placeholder="请复制招募计划 planId"
            />

            <!-- 小程序 -->
            <a-input
              v-if="res.target == 15"
              v-model="res.minapp"
              :max-length="255"
              placeholder="appid#query 或 #小程序链接"
            />

            <!-- 网页 -->
            <a-input
              v-if="res.target == 12"
              v-model="res.url"
              :max-length="200"
              placeholder="请输入网页地址"
            />

            <!-- 拨打电话 -->
            <a-input
              v-if="res.target == 20"
              v-model="res.tel"
              :max-length="100"
              placeholder="请输入电话号码"
            />
            <!-- 复制文本 -->
            <a-input
              v-if="res.target == 21"
              v-model="res.text"
              :max-length="100"
              placeholder="请输入文本"
            />
            <a-upload
              v-if="res.target == 22"
              :data="{ index: 100 }"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <img
                v-if="res.qrcode"
                class="upload-image-small"
                :src="res.qrcode"
              />
              <a-button-group>
                <a-button>
                  <a-icon type="upload" />
                  {{ res.qrcode ? "更换" : "上传" }}图片
                </a-button>
              </a-button-group>
            </a-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">卡片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.padding" :min="0" :max="15" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>
      <a-form-model-item label="卡片颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <template>
        <header class="group">图标</header>
        <a-form-model-item label="内置样式" v-bind="horizonLayout">
          <a-radio-group v-model="res.iconType" button-style="solid">
            <a-radio-button :value="0">样式一</a-radio-button>
            <a-radio-button :value="9">无</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-item label="图标" v-bind="horizonLayout">
          <a-upload
            :data="{ index: 1 }"
            listType="picture-card"
            :action="UPLOAD_URL"
            :headers="{ t: getToken }"
            withCredentials
            accept="image/*"
            :showUploadList="false"
            @change="handleUpload"
            :before-upload="beforeUpload"
          >
            <div v-if="res.icon" class="imageBox">
              <img :src="res.icon" style="width:80px" />
              <img
                @click.stop="delItem"
                class="del-icon highlight"
                src="@/assets/icn-delete-popup@3x.png"
              />
            </div>
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-form-item>
      </template>

      <header class="group">内容</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.style.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.style.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="行间距" v-bind="horizonLayout">
        <slider-input v-model="res.style.lineHeight" :min="10" :max="60" />
      </a-form-model-item>
      <a-form-model-item label="字间距" v-bind="horizonLayout">
        <slider-input v-model="res.style.letterSpacing" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="划线" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.textDecoration" button-style="solid">
          <a-radio-button value="auto">无</a-radio-button>
          <a-radio-button value="line-through"
            ><a-icon type="strikethrough"
          /></a-radio-button>
          <a-radio-button value="underline"
            ><a-icon type="underline"
          /></a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.color" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";
import uploadMixin from "@/mixins/upload";

import textMixin from "@/mixins/text";

import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import targetMixin from "@/mixins/target";

export default {
  props: { res: Object },

  mixins: [
    textMixin,
    uploadMixin,

    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin,
    targetMixin
  ],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        if (index == 100) {
          this.res.qrcode = url;
          return;
        }

        this.res.icon = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    delItem() {
      this.res.icon = "";
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style></style>
