<template>
  <div class="pin-list">
    <template v-for="item in rows">
      <div class="item" :key="item.id">
        <div class="cover" :style="imageStyle(item)" />
        <div class="content">
          <div class="name" :style="res.productTitleStyle">
            {{ item.title }}
          </div>
          <div class="pending"></div>
          <div class="origin-price">原价 ￥{{ item.price.toFixed(2) }}</div>
          <div class="p">
            <div class="price" :style="res.priceStyle">
              ￥{{ item.newPrice.toFixed(2) }}
            </div>
            <div class="pending"></div>
            <div class="buy" :style="res.btnStyle">
              {{ res.btnText }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="rows.length <= 0" class="empty">
      <img src="@/assets/icn-list-default-1@3x.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object,
    rows: Array
  },

  methods: {
    imageStyle(row) {
      const {
        imageStyle: { borderRadius }
      } = this.res;
      return {
        borderRadius: borderRadius,
        backgroundImage: `url(${row.img})`
      };
    }
  }
};
</script>

<style lang="scss">
.pin-list {
  .item {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }

    .cover {
      border-radius: 8px;
      width: 80px;
      height: 80px;
      flex: 0 0 auto;
      margin-right: 15px;
      background: transparent;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 5px 0;

      .name {
        font-size: 14px;
        line-height: 1.29;
        color: rgba(0, 0, 0, 0.9);

        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 2.6;
      }

      .pending {
        flex: 1 1 auto;
      }

      .origin-price {
        font-size: 12px;
        color: #7f7f7f;
        opacity: 0.5;
        text-decoration: line-through;
      }

      .p {
        display: flex;
        align-items: center;

        .price {
          font-size: 16px;
          font-weight: 600;
          color: #ec4b30;
        }

        .buy {
          width: 72px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
          color: #fff;
          border-radius: 6px;
          background-color: #ec4b30;
          border: 1px solid #fff;
        }
      }
    }
  }

  .empty img {
    width: 100%;
  }
}
</style>
