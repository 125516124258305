<template>
  <div class="prize2">
    <div class="img">
      <img :src="res.img" />
    </div>
    <section>
      <div class="title" :style="res.titleStyle">{{ res.title }}</div>
      <div class="desc" :style="res.descStyle">{{ res.prizeText }}</div>
    </section>
    <div class="btn" :style="res.btnStyle">
      <span>{{ res.btnTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {},

  methods: {}
};
</script>

<style lang="scss">
.prize2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  section {
    flex: 1 1 auto;
  }
}
</style>
