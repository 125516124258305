<template>
  <a-form-model>
    <section>
      <vue-editor
        class="quill-cn"
        id="editor"
        :editorToolbar="customToolbar"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="res.html"
      >
      </vue-editor>

      <header class="group">样式</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
// import { VueEditor } from "vue2-editor";

import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

import request from "../../service/request";

export default {
  props: { res: Object },

  mixins: [textMixin, uploadMixin],

  computed: {
    customToolbar() {
      return [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],

        [{ align: [] }],

        [{ list: "ordered" }, { list: "bullet" }],

        ["image"],

        ["clean"] //清除字体样式
      ];
    }
  },

  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("index", 1);

      try {
        const data = await request.post("/u/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        const { url } = data;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      } catch (err) {
        // console.log(err);
        this.$message.error("上传失败，错误：" + err.message);
      }
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    // VueEditor,

    ColorPicker,
    SliderInput
  }
};
</script>

<style lang="scss">
.quill-cn {
  .ql-picker.ql-size {
    .ql-picker-label::before {
      content: "中" !important;
    }
    .ql-picker-label[data-value="small"]::before {
      content: "小" !important;
    }
    .ql-picker-label[data-value="normal"]::before {
      content: "中" !important;
    }
    .ql-picker-label[data-value="large"]::before {
      content: "大" !important;
    }
    .ql-picker-label[data-value="huge"]::before {
      content: "最大" !important;
    }
  }
  .ql-picker.ql-size {
    .ql-picker-item::before {
      content: "中" !important;
    }
    .ql-picker-item[data-value="small"]::before {
      content: "小" !important;
    }
    .ql-picker-item[data-value="normal"]::before {
      content: "中" !important;
    }
    .ql-picker-item[data-value="large"]::before {
      content: "大" !important;
    }
    .ql-picker-item[data-value="huge"]::before {
      content: "最大" !important;
    }
  }
}
</style>
