<template>
  <div class="group-wrap" :class="darkClass">
    <List v-if="res.type == 1" :res="res" :flag="flag" :rows="rows" :show-more="showMore" :btn="btn" :my-style="style" />
    <Col3 v-else-if="res.type == 3" :res="res" :flag="flag" :rows="rows" :show-more="showMore" :btn="btn"
      :my-style="style" />
    <Scroll v-else-if="res.type == 4" :res="res" :flag="flag" :rows="rows" :show-more="showMore" :btn="btn"
      :my-style="style" />
    <Grid v-else :res="res" :flag="flag" :rows="rows" :show-more="showMore" :btn="btn" :my-style="style" />
  </div>
</template>

<script>
import Grid from "./grid.vue";
import List from "./list.vue";
import Col3 from "./col3.vue";
import Scroll from "./scroll.vue";

import { mapState } from "vuex";

import { isColorDark } from "@/utils/tool";

import categoryMixin from "@/mixins/category";

export default {
  props: {
    res: Object
  },

  mixins: [categoryMixin],

  computed: {
    ...mapState({
      productList: state => state.productList,
      productList2: state => state.productList2
    }),

    realProductList() {
      return this.productList.concat(this.productList2);
    },
    realRows() {
      switch (this.res.ds) {
        // 分类
        case 1: {
          // 汇总下级ID
          let cid = this.res.categoryId;
          let cidList = this.categoryList
            .filter(x => x.fid === cid)
            .map(x => x.id);
          cidList.push(cid);

          return this.realProductList.filter(
            x => (x.cid || []).filter(z => cidList.includes(z)).length > 0
          );
        }
        // 全部商品
        case 2:
          {
            return this.realProductList;
          }
        default:
          return this.res.rows;
      }
    },
    sortedRows() {
      if (this.res.ds > 0 && this.res.sort > 0) {
        switch (this.res.sort) {
          // 最新上架
          case 1:
            return this.realRows.slice().sort((a, b) => (a.id > b.id ? -1 : 1));
          // 价格从低到高
          case 2:
            return this.realRows
              .slice()
              .sort((a, b) => (a.price > b.price ? 1 : -1));
          // 价格从高到低
          case 3:
            return this.realRows
              .slice()
              .sort((a, b) => (a.price > b.price ? -1 : 1));
          default:
            return this.realRows;
        }
      } else {
        return this.realRows;
      }
    },
    rows() {
      let rows = this.sortedRows;
      if (this.res.type < 4 && this.res.lines > 0) {
        return rows.slice(0, this.res.lines * this.res.type);
      }

      // 最多显示10条，以免影响功能
      if (this.res.ds > 0) {
        const limit = this.res.type === 3 ? 12 : 10;
        return rows.slice(0, limit);
      }

      return rows;
    },
    showMore() {
      if (this.res.lines <= 0) {
        return false;
      }

      return this.realRows.length > this.res.lines * 2;
    },

    btn() {
      const index = this.res.btnStyle.index;
      const plain = index === 0;
      const iconClass = `ic-buy-${index}`;

      const { width, height, backgroundColor } = this.res.btnStyle;
      const iconStyle = {
        width,
        height,
        backgroundColor
      };
      return {
        plain,
        iconClass,
        iconStyle
      };
    },

    style() {
      let {
        style: { paddingTop },
        boxStyle: { marginBottom: rowMargin }
      } = this.res;
      paddingTop = parseInt(paddingTop);
      rowMargin = parseInt(rowMargin);

      let val = paddingTop - rowMargin;

      let marginBottom = "0px",
        paddingBottom = "0px";
      if (val > 0) {
        paddingBottom = val + "px";
        marginBottom = "0px";
      } else {
        paddingBottom = "0px";
        marginBottom = val + "px"; // Math.min(parseInt(value), 0) - 10 + 'px';
      }

      return { ...this.res.style, marginBottom, paddingBottom };
    },

    darkClass() {
      return isColorDark(this.res.style.backgroundColor) ? "dark" : "";
    },

    flag() {
      const { color, backgroundColor } = this.res.flagStyle;

      let arrow = {};
      switch (this.res.flagStyle.type) {
        case 1:
          arrow.background = `linear-gradient(205deg, ${backgroundColor} 50%, transparent 0) 0 0,linear-gradient(155deg, ${backgroundColor} 50%, transparent 0) 0 0`;
          break;
        case 2:
          arrow.background = `linear-gradient(115deg, ${backgroundColor} 50%, transparent 0) 0 0,linear-gradient(65deg, ${backgroundColor} 50%, transparent 0) 0 0`;
          break;
      }

      return {
        style: {
          color,
          backgroundColor
        },
        arrow
      };
    }
  },

  components: { Grid, List, Col3, Scroll }
};
</script>

<style lang="scss">
.group-wrap {
  .price-n-btn {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.row {
      flex-direction: row;
      margin: 10px 10px 0;
    }

    &.center {
      justify-content: center;
    }

    &.left {
      justify-content: flex-start;
    }

    &.right {
      justify-content: flex-end;
    }

    &.between {
      justify-content: space-between;
    }
  }

  .header {
    font-size: 17px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.66);
      font-weight: 400;
    }
  }

  &.dark {

    .header,
    .desc {
      color: #fff;
    }
  }

  &>div.has-header {
    padding: 20px 10px;
  }

  .more {
    padding: 10px 20px;
    display: flex;
    justify-content: center;

    .more-btn {
      text-align: center;
      width: 85px;
      height: 36px;
      border-radius: 2px;
      border: 1px solid #30992e;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #30992e;
    }
  }

  &.dark {
    .more-btn {
      border-color: #fff;
      color: #fff;
    }
  }

  .flag {
    position: absolute;
    left: 15px;
    top: 0;
    background: red;
    color: #fff;
    padding: 0 5px;
    font-weight: bold;
    line-height: 2em;

    &.type2,
    &.type5 {
      left: 0;
      top: 15px;
      background: red;
      color: #fff;
      padding-left: 5px;
    }

    &.type3 {
      transform: rotate(-45deg);
      left: -22px;
      top: 6px;
      width: 84px;
      text-align: center;
    }

    &.type1 .flag-arrow {
      position: absolute;
      bottom: -12px;
      left: 0;
      height: 20px;
      width: 100%;
      background: linear-gradient(205deg, red 50%, transparent 0) 0 0,
        linear-gradient(155deg, red 50%, transparent 0) 0 0;
    }

    &.type2 .flag-arrow {
      position: absolute;
      top: 0;
      right: -10px;
      height: 100%;
      width: 10px;
      background: linear-gradient(115deg, red 50%, transparent 0) 0 0,
        linear-gradient(65deg, red 50%, transparent 0) 0 0;
    }
  }
}
</style>
