export default {
  data() {
    return {
      tipsKey: "", // 提示框的键

      hideTips: true
    };
  },

  methods: {
    onCloseTips() {
      localStorage.setItem(this.tipsKey, 1);
    }
  },

  mounted() {
    // 提示显示
    this.hideTips = !!localStorage.getItem(this.tipsKey);
  }
};
