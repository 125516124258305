<template>
  <a-form-model>
    <section>
      <a-form-item label="图标" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 0 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <img v-if="res.icon" :src="res.icon" style="width:80px" />
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="标题">
        <a-input
          v-model="res.content"
          placeholder="请输入标题内容"
          type="text"
        />
      </a-form-model-item>
    </section>

    <section>
      <header class="group">卡片</header>
      <a-form-model-item label="卡片颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="文字颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.color" />
      </a-form-model-item>

      <header class="group">按钮</header>
      <a-form-model-item label="文字色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.color" />
      </a-form-model-item>
      <a-form-model-item label="背景色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="25"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="25"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

export default {
  props: { res: Object },

  mixins: [textMixin, uploadMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.icon = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style lang="scss"></style>
