import { mapMutations } from "vuex";

const DecoSvc = require("@/service/decoration");

export default {
  methods: {
    ...mapMutations(["setCouponList"])
  },

  async mounted() {
    // 优惠券
    try {
      const { list } = await DecoSvc.loadCouponList();
      this.setCouponList(list);
    } catch (err) {
      if (!(err.message || "").startsWith("Not exist")) {
        console.error(err);
        this.$message.error("加载优惠券出错，错误：" + err.message);
      }
    }
  }
};
