<template>
  <a-form-model>
    <a-form-model-item label="视频源" v-bind="horizonLayout">
      <a-radio-group v-model="res.type" button-style="solid">
        <a-radio-button :value="1">网络地址</a-radio-button>
        <a-radio-button :value="2">本地上传</a-radio-button>
      </a-radio-group>
    </a-form-model-item>

    <section>
      <div v-if="res.type == 2" class="image">
        <a-form-item
          label="视频"
          v-bind="horizonLayout"
          help="视频格式: mp4，文件大小: 10M以内"
        >
          <a-upload
            :data="{ index: 0 }"
            listType="picture-card"
            :action="UPLOAD_URL"
            :headers="{ t: getToken }"
            withCredentials
            accept="video/mp4"
            :showUploadList="false"
            @change="handleUpload"
            :before-upload="beforeUpload"
          >
            <video v-if="res.src" :src="res.src" style="width:80px" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </a-form-item>
      </div>
      <a-form-model-item v-else label="视频网址" v-bind="horizonLayout">
        <a-input
          v-model="res.src"
          :maxLength="1000"
          placeholder="请输入完整地址：http://"
          type="text"
        />
      </a-form-model-item>
      <a-form-model-item label="自动播放" v-bind="horizonLayout">
        <a-switch v-model="res.autoplay" />
      </a-form-model-item>
      <a-form-model-item label="循环播放" v-bind="horizonLayout">
        <a-switch v-model="res.loop" />
      </a-form-model-item>
      <a-form-model-item label="静音播放" v-bind="horizonLayout">
        <a-switch v-model="res.muted" />
      </a-form-model-item>
    </section>

    <section>
      <header class="group">样式</header>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.videoStyle.objectFit" button-style="solid">
          <a-radio-button value="contain">默认</a-radio-button>
          <a-radio-button value="cover">缩放</a-radio-button>
          <a-radio-button value="fill">拉伸</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="长宽比" v-bind="horizonLayout">
        <a-select
          v-model="res.videoStyle.rate"
          @change="
            value => (res.videoStyle.height = 375 * parseFloat(value) + 'px')
          "
          placeholder="请选择"
        >
          <a-select-option
            v-for="t in videoRatioList"
            :key="t.id"
            :value="t.value"
            >{{ t.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="视频圆角" v-bind="horizonLayout">
        <slider-input
          v-model="res.videoStyle.borderRadius"
          :min="0"
          :max="25"
        />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.videoStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.videoStyle.boxShadow = `0 1px ${value} rgba(0, 0, 0,0.1)`)
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.videoStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.videoStyle.borderColor" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

const videoRatioList = [
  { id: 1, name: "1:1", value: 1 },
  { id: 2, name: "3:2", value: 0.666 },
  { id: 3, name: "4:3", value: 0.75 },
  { id: 4, name: "3:4", value: 1.333 },
  { id: 5, name: "5:4", value: 0.8 },
  { id: 6, name: "16:9", value: 0.5625 },
  { id: 7, name: "9:16", value: 1.777 }
];

export default {
  data() {
    return {
      // 默认：10M
      LIMIT_M: 10,

      videoRatioList
    };
  },

  props: { res: Object },

  mixins: [textMixin, uploadMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        this.$set(this.res, "src", url);
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style lang="scss"></style>
