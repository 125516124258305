<template>
  <div class="vip" :style="res.style">
    <div class="wrap" :style="res.boxStyle">
      <section :style="res.titleStyle">
        <div class="title">{{ res.title }}</div>
        <div class="desc">{{ res.subTitle }}</div>
      </section>
      <section class="btn-row">
        <div class="vip-text" :style="res.priceStyle">{{ res.vipTitle }}</div>
        <div class="btn" :style="res.btnStyle">
          <span>{{ res.btnTitle }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  }
};
</script>

<style lang="scss">
.vip {
  background: #fff;
  padding: 10px;

  .wrap {
    padding: 20px;
    border-style: solid;
    border-width: 0;

    .title {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.33;
    }

    .desc {
      margin-top: 10px;
      line-height: 1.5;
      font-size: 12px;
    }
  }

  .btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    section {
      flex: 1 1 auto;
    }

    .vip-text {
      font-size: 40px;
      line-height: 1;
      font-weight: bold;
      color: #ffd76a;
      letter-spacing: 1px;
    }

    .btn {
      flex: 0 0 auto;
      width: 85px;
      height: 36px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #47474d;
      margin-left: 15px;
      background: #ffd76a;
    }
  }
}
</style>
