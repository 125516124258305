<template>
  <div class="kan-wrap" :style="res.style">
    <div class="box" :style="res.boxStyle">
      <div class="header">
        <span class="label" :style="res.titleStyle">{{ res.title }}</span>
      </div>
      <div class="list" :style="listStyle">
        <Grid v-if="res.type == 2" :res="res" :rows="rows" />
        <Col3 v-else-if="res.type == 3" :res="res" :rows="rows" />
        <Scroll v-else-if="res.type == 4" :res="res" :rows="rows" />
        <List v-else :res="res" :rows="rows" />
      </div>
    </div>
  </div>
</template>

<script>
import List from "./list.vue";
import Grid from "./grid.vue";
import Col3 from "./col3.vue";
import Scroll from "./scroll.vue";

import { mapState } from "vuex";

export default {
  props: {
    res: Object
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      kanList: state => state.kanList
    }),

    // 进行中活动
    realKanList() {
      return this.kanList.filter(x => x.status === "进行中");
    },
    rows() {
      switch (this.res.ds) {
        // 全部活动
        case 1:
          return this.realKanList;
        default:
          return this.res.rows;
      }
    },

    listStyle() {
      let {
        boxStyle: { borderRadius }
      } = this.res;

      return { borderRadius };
    }
  },

  methods: {},

  components: { List, Grid, Col3, Scroll }
};
</script>

<style lang="scss">
.kan-wrap {
  padding: 20px 10px;
  background: #fff;

  .box {
    background-color: #f30305;
    border-radius: 16px;
    padding: 5px;
  }

  .header {
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    padding: 5px 10px 10px;

    .label {
      flex: 1 1 0%;
    }
  }

  .list {
    background: #fff;
    overflow: hidden;
    border-radius: 16px;
    padding: 10px;
  }
}
</style>
