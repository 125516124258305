<template>
  <div class="add-wechat" :style="res.style">
    <div :class="`wrap type-${res.type}`" :style="res.boxStyle">
      <img class="wechat-icon" :src="attr.big" />
      <section>
        <div class="title">{{ res.title }}</div>
        <div class="desc">{{ res.subTitle }}</div>
      </section>
      <div class="btn" :style="res.btnStyle">
        <span>{{ attr.btnTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const ATTRS = [
  {
    text: "加好友",
    big: "ic-wechat@3x"
  },
  { text: "进群", big: "ic-group@3x" },
  { text: "加关注", big: "ic-mp@3x" },
  { text: "打电话", big: "ic-phone@3x" }
];

export default {
  props: {
    res: Object
  },

  computed: {
    attr() {
      const attr = ATTRS[this.res.type - 1] || ATTRS[0];
      const btnTitle = this.res.btnTitle || attr.text;
      const big = this.res.icon
        ? this.res.icon
        : require(`@/assets/contact/${attr.big}.png`);
      return {
        btnTitle,
        big
      };
    }
  }
};
</script>

<style lang="scss">
.add-wechat {
  background: #fff;
  padding: 10px;

  .wrap {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 18px 10px;
    border-radius: 6px;
    border: 1px solid #f5f5f5;

    .wechat-icon {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    section {
      flex: 1 1 auto;
    }

    .title {
      font-size: 15px;
      font-weight: 700;
    }

    .desc {
      font-size: 14px;
    }

    .btn {
      flex: 0 0 auto;
      width: 85px;
      height: 36px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      margin-left: 15px;
      background: #30992e;
    }
  }

  .wrap.type-3 {
    .btn {
      background: #4c83d7;
    }
  }
}
</style>
