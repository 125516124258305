<template>
  <div class="time-sale-wrap" :class="state" :style="res.style">
    <div class="box" :style="boxStyle">
      <div class="header">
        <span class="label" :style="res.titleStyle">{{ res.title }}</span>
        <span class="desc">
          <count-down
            :start-time="timeRange.startTime"
            :end-time="timeRange.endTime"
            v-model="state"
          />
        </span>
      </div>
      <div class="list" :style="listStyle">
        <Grid v-if="res.type == 2" :res="res" :state="state" :rows="rows" />
        <Col3
          v-else-if="res.type == 3"
          :res="res"
          :state="state"
          :rows="rows"
        />
        <Scroll
          v-else-if="res.type == 4"
          :res="res"
          :state="state"
          :rows="rows"
        />
        <List v-else :res="res" :state="state" :rows="rows" />
      </div>
    </div>
  </div>
</template>

<script>
import CountDown from "@/components/count-down.vue";
import Grid from "./grid.vue";
import List from "./list.vue";
import Col3 from "./col3.vue";
import Scroll from "./scroll.vue";

import { mapState } from "vuex";

export default {
  props: {
    res: Object
  },

  data() {
    return {
      state: ""
    };
  },

  computed: {
    ...mapState({
      discountList: state => state.discountList
    }),

    boxStyle() {
      const { res, state } = this;

      if (!res.bgColorEnable) {
        return res.boxStyle;
      }

      let boxStyle = window._.clone(res.boxStyle);
      switch (state) {
        case "start":
          boxStyle.backgroundColor = res.bgColorStart;
          break;
        case "end":
          boxStyle.backgroundColor = res.bgColorEnd;
          break;
        default:
          boxStyle.backgroundColor = res.bgColorRunning;
          break;
      }

      return boxStyle;
    },

    rows() {
      switch (this.res.ds) {
        // 挑选活动
        case 1:
          return this.res.discountRows;
        // 全部活动
        case 2:
          return this.discountList;
        // 商品
        default:
          return this.res.rows;
      }
    },

    timeRange() {
      switch (this.res.ds) {
        // 挑选活动
        case 1: {
          if (this.res.discountRows.length <= 0) {
            return {
              startTime: null,
              endTime: null
            };
          }

          const startTime = window._.minBy(this.res.discountRows, "startTime")
            .startTime;
          const endTime = window._.maxBy(this.res.discountRows, "endTime")
            .endTime;

          return {
            startTime,
            endTime
          };
        }
        // 全部活动
        case 2: {
          if (this.discountList.length <= 0) {
            return {
              startTime: null,
              endTime: null
            };
          }

          const startTime = window._.minBy(this.discountList, "startTime")
            .startTime;
          const endTime = window._.maxBy(this.discountList, "endTime").endTime;

          return {
            startTime,
            endTime
          };
        }
        // 商品
        default: {
          const { startTime, endTime } = this.res;
          return {
            startTime,
            endTime
          };
        }
      }
    },

    listStyle() {
      let {
        boxStyle: { borderRadius }
      } = this.res;

      return { borderRadius };
    }
  },

  methods: {},

  components: { Grid, List, Col3, Scroll, CountDown }
};
</script>

<style lang="scss">
.time-sale-wrap {
  padding: 20px 10px;
  background: #fff;

  .box {
    background-color: #f30305;
    border-radius: 16px;
    padding: 5px;
  }

  &.start {
    .box {
      background: #3bcb3f;
    }
  }

  &.end {
    .box {
      background: #9d9d9d;
    }
  }

  .header {
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    padding: 5px 10px 10px;

    .label {
      flex: 1 1 0%;
    }

    .desc {
      flex: 1 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .list {
    background: #fff;
    overflow: hidden;
    border-radius: 16px;
    padding: 10px;

    .empty img {
      width: 100%;
    }
  }
}

.time-sale-item {
  position: relative;
  width: calc(50% - 5px);
  margin-right: 10px;
  padding-bottom: 15px;
  overflow: hidden;

  .cover {
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .name {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 1.29;

    text-align: center;
    padding: 0 7px;
    margin-top: 10px;

    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 2.6;
  }

  .price-wrap {
    display: flex;
    justify-content: center;
    padding: 0 7px;
    margin-top: 5px;

    .price {
      font-size: 16px;
      font-weight: 600;
      color: #ec4b30;
      line-height: 1.07;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .origin-price {
      font-size: 12px;
      color: #7f7f7f;
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  .buy {
    width: 72px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #ec4b30;
    color: #fff;
    line-height: 1;
    font-size: 13px;
    font-weight: 600;
    margin: 5px auto 0;
    border: 1px solid #fff;

    &.start {
      background: #3bcb3f;
    }

    &.end {
      background: #9d9d9d;
    }
  }

  .buy-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
  }
}
</style>
