<template>
  <div class="goods-list" :class="darkClass" :style="style">
    <div class="header"><span class="label">全部商品</span></div>
    <div class="list">
      <div v-for="item in rows" :key="item.id" class="item">
        <div class="cover" :style="imageStyle(item.img)" />
        <div class="name">{{ item.title }}</div>
        <div class="pending"></div>
        <div class="price">￥{{ item.price }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { isColorDark } from "@/utils/tool.js";

export default {
  props: {
    res: Object
  },

  computed: {
    ...mapState({
      rows: state => (state.productList || []).slice(0, 4)
    }),

    style() {
      const res = this.res || {};
      return res.style || {};
    },

    darkClass() {
      const res = this.res || {};
      const style = res.style || {};
      const backgroundColor = style.backgroundColor || "#ffff";
      // console.log(backgroundColor, isColorDark(backgroundColor));
      return isColorDark(backgroundColor) ? "dark" : "";
    }
  },

  methods: {
    imageStyle(img) {
      return {
        backgroundImage: `url(${img})`
      };
    }
  }
};
</script>

<style lang="scss">
.goods {
  background: #fff;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.goods-list {
  padding: 20px 10px;
  background: #fff;

  .header {
    font-size: 17px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.75);
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.66);
      font-weight: 400;
    }
  }

  &.dark .header {
    color: #fff;
  }

  .list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      position: relative;
      width: calc(50% - 5px);
      margin-bottom: 20px;
      border-radius: 6px;
      border: 1px solid #f5f5f5;
      background: #fff;

      .cover {
        width: 100%;
        height: 172.5px;
        overflow: hidden;
        background-size: cover;
      }

      .name {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.9);
        margin-top: 15px;
        max-height: 36px;
        line-height: 1.29;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        padding: 0 7px;
      }

      .pending {
        flex: 1 1 auto;
      }

      .price {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.07;
        color: #e02f11;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        padding: 0 7px 15px;
      }

      .recommend-icn {
        position: absolute;
        width: 33px;
        height: 33px;
        top: 5px;
        right: 5px;
        background: transparent;
      }
    }

    .item.highlight {
      opacity: 0.8;
    }
  }
}
</style>
