<template>
  <div class="notice-v-scroll">
    <img v-if="showIcon" class="icn" :src="icon" />
    <div class="item">
      <transition class="inner" name="slide" mode="out-in">
        <div class="content" :key="text.id">{{ text.val }}</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0
    };
  },

  props: {
    res: Object
  },

  computed: {
    icon() {
      return this.res.icon
        ? this.res.icon
        : require("@/assets/icn-notice-3.png");
    },

    showIcon() {
      return this.res.iconType !== 9;
    },

    arr() {
      return this.res.content.split("\n").filter(x => x.length > 0);
    },
    text() {
      return {
        id: this.index,
        val: this.arr[this.index]
      };
    }
  },

  methods: {
    startMove() {
      const len = this.arr.length;
      setTimeout(() => {
        if (this.index === len - 1) {
          this.index = 0;
        } else {
          this.index += 1;
        }
        this.startMove();
      }, 3000);
    }
  },

  mounted() {
    this.startMove();
  }
};
</script>

<style lang="scss">
.notice-v-scroll {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icn {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .item {
    flex: 1;
    overflow: hidden;
    position: relative;

    .content {
      line-height: 2;
      white-space: nowrap;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s linear;
  }
  .slide-leave-to {
    transform: translateY(-20px);
  }
  .slide-enter {
    transform: translateY(20px);
  }
}
</style>
