<template>
  <div class="spacer-wrap">
    <div class="content" :style="res.style">
      <div class="line" :style="res.boxStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  }
};
</script>

<style lang="scss">
.spacer-wrap {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
