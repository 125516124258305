<template>
  <a-form-model class="hotzone-panel">
    <!-- 热点图 -->
    <section>
      <header class="group">底图</header>
      <a-upload
        class="upload-big"
        :data="{ index: 0 }"
        listType="picture-card"
        :action="UPLOAD_URL"
        :headers="{ t: getToken }"
        withCredentials
        accept="image/*"
        :showUploadList="false"
        @change="handleUpload"
        :before-upload="beforeUpload"
      >
        <img v-if="res.img" :src="res.img" />
        <div v-else>
          <a-icon type="plus" />
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>
      <a-form-model-item>
        <a-button style="width: 100%" @click="openDialog">
          <a-icon type="edit" /> 编辑热区
        </a-button>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">图片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
    <HotzoneDialog id="hotzoneDialog" ref="hotzoneDialog" v-model="res" />
  </a-form-model>
</template>

<script>
import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";

export default {
  data() {
    return {};
  },
  props: {
    res: Object
  },

  mixins: [uploadMixin, textMixin],

  computed: {},

  async mounted() {},

  methods: {
    openDialog() {
      this.$refs.hotzoneDialog.show();
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        this.res.img = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style lang="scss"></style>
