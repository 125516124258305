<template>
  <div class="coupon" :style="res.style">
    <div class="list" :class="`left-${res.rows.length % 2} ` + `type-${type}`">
      <div
        v-for="item in res.rows"
        :key="item.key"
        class="item"
        :style="res.boxStyle"
      >
        <div class="info" :class="`type-${res.type}`">
          <div class="title" :style="res.titleStyle">{{ item.name }}</div>
          <div class="desc" :style="valueStyle">
            <span class="value">{{ item.num2 }}</span>
            <span class="text">{{ getUnit(item.type) }}</span>
          </div>
          <div class="btn" :style="btnStyle">领取</div>
        </div>
        <template v-if="res.type === 2 || res.type === 3">
          <div class="circle c1" :style="circleStyle" />
          <div class="circle c2" :style="circleStyle" />
        </template>
        <template v-if="res.type === 3">
          <div class="line" :style="lineStyle" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    type() {
      const rows = this.res.rows;
      let type = 1;
      if (rows.length === 1) {
        type = 1;
      } else if (rows.length === 2 || rows.length === 4) {
        type = 2;
      } else {
        type = 3;
      }
      return type;
    },

    circleStyle() {
      let top = "50%";

      if (this.res.type === 3) {
        top =
          parseInt(this.res.boxStyle.padding) +
          parseInt(this.res.titleStyle.lineHeight) +
          18 / 2 +
          "px";
      }

      return {
        top,
        background: this.res.style.backgroundColor
      };
    },

    lineStyle() {
      let top =
        parseInt(this.res.boxStyle.padding) +
        parseInt(this.res.titleStyle.lineHeight) +
        18 / 2;

      return {
        top: top + "px",
        borderTopColor: this.res.style.backgroundColor
      };
    },

    valueStyle() {
      let val = {
        ...this.res.valueStyle
      };

      if (this.res.type === 3) {
        val.marginTop = "24px";
      }

      return val;
    },
    btnStyle() {
      let val = {
        ...this.res.btnStyle
      };

      switch (this.res.type) {
        case 2:
          val.borderRadius = "30px";
          break;
        case 3:
          val.marginTop = "24px";
      }

      return val;
    }
  },

  methods: {
    getUnit(type) {
      return [1, 101, 3, 103].includes(type) ? "折" : "元";
    },
    getTips(item) {
      const type = item.type > 100 ? item.type - 100 : item.type;
      const TIPS = {
        1: "满xx件yy折",
        2: "满xx减yy",
        3: "yy折优惠券",
        4: "yy元直减券"
      };
      return TIPS[type].replace("xx", item.num1).replace("yy", item.num2);
    }
  }
};
</script>

<style lang="scss">
.coupon {
  padding: 10px 10px 0;
  background: #fff;

  .list {
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 15px;
      border-radius: 6px;
      margin-bottom: 10px;
      flex: 0 0 100%;
      overflow: hidden;
      background-color: rgba(243, 55, 56, 0.1);
      border-style: solid;
      position: relative;

      .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: rgb(243, 55, 56);

        &.type-2 {
          margin: 0 5px;
        }

        .title {
          font-size: 13px;
          color: #1f1200;
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex: 0 0 100%;
        }

        .desc {
          margin-top: 18px;
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 600;
          flex: 1 1 auto;
          font-size: 30px;

          .text {
            font-size: 13px;
            margin-left: 4px;
            position: relative;
            top: -1px;
          }
        }

        .btn {
          flex: 0 0 auto;
          margin-top: 18px;
          width: 60px;
          height: 30px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: 600;
          color: #fff;
          background-color: rgb(243, 55, 56);
        }
        .btn.highlight {
          opacity: 0.8;
        }
      }

      .circle {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 20px;
        background-color: #e2dfdf;
        transform: translate(0, -50%);
        top: 50%;
      }
      .c1 {
        left: -5px;
      }
      .c2 {
        right: -5px;
      }

      .line {
        position: absolute;
        left: 5px;
        right: 5px;
        top: 37px;
        border-top: 1px dashed #fff;
      }
    }
  }

  .list.type-2 .item {
    flex: 0 0 auto;
    width: calc(50% - 5px);

    &:nth-child(odd) {
      margin-right: 10px;
    }
  }

  .list.type-3 .item {
    width: calc(33.3% - 6.67px);
    flex: 0 0 auto;
    margin-right: 10px;

    .info {
      justify-content: center;
    }
    .title {
      text-align: center;
    }

    .desc {
      flex: 0 0 100%;
      text-align: center;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
