<template>
  <a-form-model>
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
      ><p slot="description">
        请确认您在创建直播时，已开启「官方收录」
      </p>
    </a-alert>
    <div></div>
    <section>
      <header class="group">样式</header>
      <a-form-model-item label="尺寸" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.size" button-style="solid">
          <a-radio-button value="small">小</a-radio-button>
          <a-radio-button value="mid">中</a-radio-button>
          <a-radio-button value="big">大</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="位置" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.position" button-style="solid">
          <a-radio-button value="rightTop">
            <a-icon type="vertical-align-top" />
          </a-radio-button>
          <a-radio-button value="rightBottom">
            <a-icon type="vertical-align-bottom" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">图标</header>
      <a-form-model-item label="内置样式" v-bind="horizonLayout">
        <a-radio-group v-model="res.type" button-style="solid">
          <a-radio-button :value="3">样式一</a-radio-button>
          <a-radio-button :value="2">样式二</a-radio-button>
          <a-radio-button :value="1">样式三</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-item
        label="自定图标"
        help="建议宽度：180px"
        v-bind="horizonLayout"
      >
        <a-upload
          :data="{ index: 1 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.icon" class="imageBox">
            <img :src="res.icon" style="width:80px" />
            <img
              @click.stop="delItem"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
    </section>
  </a-form-model>
</template>

<script>
import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";

export default {
  data() {
    return {
      tipsKey: "hide-live-tips"
    };
  },

  props: { res: Object },

  mixins: [uploadMixin, textMixin, tipsMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;

        this.res.icon = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    delItem() {
      this.res.icon = "";
    }
  }
};
</script>

<style></style>
