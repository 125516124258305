<template>
  <a-form-model>
    <a-form-model-item label="联系方式">
      <a-radio-group
        @change="changeType"
        v-model="res.type"
        button-style="solid"
      >
        <a-radio-button :value="1">微信号</a-radio-button>
        <a-radio-button :value="2">微信群</a-radio-button>
        <a-radio-button :value="3">公众号</a-radio-button>
        <a-radio-button :value="4">拨打电话</a-radio-button>
      </a-radio-group>
    </a-form-model-item>

    <section>
      <!-- 微信号 -->
      <template v-if="res.type === 1">
        <a-form-model-item label="添加方式" v-bind="horizonLayout">
          <a-select v-model="res.subType">
            <a-select-option :value="1">复制微信号添加</a-select-option>
            <a-select-option :value="2">扫二维码添加</a-select-option>
          </a-select>
        </a-form-model-item>

        <div v-if="res.subType == 2" class="image">
          <a-form-item label="二维码" v-bind="horizonLayout">
            <a-upload
              :data="{ index: 0 }"
              listType="picture-card"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <img v-if="res.qrcode" :src="res.qrcode" style="width:80px" />
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </a-form-item>
        </div>
        <a-form-model-item
          v-if="res.subType != 2"
          label="微信号"
          v-bind="horizonLayout"
        >
          <a-input
            v-model="res.weixin"
            :maxLength="50"
            placeholder="请输入微信号"
            type="text"
          />
        </a-form-model-item>
      </template>

      <!-- 微信群 -->
      <template v-if="res.type === 2">
        <a-form-item label="二维码" v-bind="horizonLayout">
          <a-upload
            :data="{ index: 0 }"
            listType="picture-card"
            :action="UPLOAD_URL"
            :headers="{ t: getToken }"
            withCredentials
            accept="image/*"
            :showUploadList="false"
            @change="handleUpload"
            :before-upload="beforeUpload"
          >
            <img v-if="res.qrcode" :src="res.qrcode" style="width:80px" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </a-form-item>
      </template>

      <!-- 公众号 -->
      <template v-if="res.type === 3">
        <a-form-model-item label="添加方式" v-bind="horizonLayout">
          <a-select v-model="res.subType">
            <a-select-option :value="1">复制公众号添加</a-select-option>
            <a-select-option :value="2">扫二维码添加</a-select-option>
          </a-select>
        </a-form-model-item>

        <div v-if="res.subType == 2" class="image">
          <a-form-item label="二维码" v-bind="horizonLayout">
            <a-upload
              :data="{ index: 0 }"
              listType="picture-card"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <img v-if="res.qrcode" :src="res.qrcode" style="width:80px" />
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </a-form-item>
        </div>
        <a-form-model-item
          v-if="res.subType != 2"
          label="公众号"
          v-bind="horizonLayout"
        >
          <a-input
            v-model="res.weixin"
            :maxLength="50"
            placeholder="请输入公众号"
            type="text"
          />
        </a-form-model-item>
      </template>

      <!-- 联系客服 -->
      <template v-if="res.type === 4">
        <a-form-model-item label="电话号码" v-bind="horizonLayout">
          <a-input
            v-model="res.tel"
            :maxLength="20"
            placeholder="请输入电话号码"
            type="text"
          />
        </a-form-model-item>
      </template>

      <a-form-model-item label="标题" v-bind="horizonLayout">
        <a-input
          v-model="res.title"
          :maxLength="20"
          placeholder="请输入主引导文案"
          type="text"
        />
      </a-form-model-item>

      <a-form-model-item label="副标题" v-bind="horizonLayout">
        <a-input
          v-model="res.subTitle"
          :maxLength="20"
          placeholder="请输入次引导文案"
          type="text"
        />
      </a-form-model-item>
      <a-form-model-item label="按钮文字" v-bind="horizonLayout">
        <a-input
          v-model="res.btnTitle"
          :maxLength="10"
          placeholder="请输入按钮文案"
          type="text"
        />
      </a-form-model-item>
    </section>

    <section>
      <header class="group">卡片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.padding" :min="0" :max="15" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>

      <a-form-model-item label="字体颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.color" />
      </a-form-model-item>
      <a-form-model-item label="卡片背景" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="图标" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 1 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.icon" class="imageBox">
            <img :src="res.icon" style="width:80px" />
            <img
              @click.stop="delItem"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>

      <header class="group">按钮</header>
      <a-form-model-item label="文字颜色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.color" />
      </a-form-model-item>
      <a-form-model-item label="按钮背景" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";

import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

const DEFAULT_SETTING = {
  1: {
    title: "店主想和你成为好友",
    subTitle: "加好友进群有专属福利呦",
    btnTitle: "加好友",
    backgroundColor: "#30992e"
  },
  2: {
    title: "欢迎进群交流",
    subTitle: "更有不定时福利哟~",
    btnTitle: "进群",
    backgroundColor: "#30992e"
  },
  3: {
    title: "欢迎关注官方公众号",
    subTitle: "进入公众号与我们互动~",
    btnTitle: "加关注",
    backgroundColor: "#5791e9"
  },
  4: {
    title: "联系客服",
    subTitle: "欢迎随时咨询",
    btnTitle: "打电话",
    backgroundColor: "#30992e"
  }
};

export default {
  data() {
    return {};
  },

  props: { res: Object },

  computed: {},

  mixins: [uploadMixin, textMixin],

  methods: {
    changeType(e) {
      const val = DEFAULT_SETTING[e.target.value];
      const { title, subTitle, btnTitle, backgroundColor } = val;
      this.res.title = title;
      this.res.subTitle = subTitle;
      this.res.btnTitle = btnTitle;
      this.res.btnStyle.backgroundColor = backgroundColor;
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url, index } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        // this.$set("res.qrcode", url);
        switch (index) {
          case 1:
            this.res.icon = url;
            break;
          case 0:
          default:
            this.res.qrcode = url;
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    delItem() {
      this.res.icon = "";
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style></style>
