<template>
  <a-form-model class="category-panel">
    <!-- <a-form-model-item label="样式">
        <a-radio-group v-model="res.type">
          <a-radio :value="1">宫格入口</a-radio>
          <a-radio :value="2" disabled>更多样式</a-radio>
        </a-radio-group>
      </a-form-model-item> -->

    <section v-for="(row, index) in res.rows" :key="row.key">
      <header>
        <div class="label">添加分类</div>
        <div class="pending"></div>
        <div @click="delRow(index)" class="opt">移除</div>
      </header>

      <a-form-model-item label="分类名称">
        <a-input
          v-model="row.title"
          :maxLength="10"
          placeholder="填写分类名称"
        />
      </a-form-model-item>

      <div v-if="res.type == 1" class="image">
        <a-form-item label="封面图片">
          <a-upload
            :data="{ index: index }"
            listType="picture-card"
            :action="UPLOAD_URL"
            :headers="{ t: getToken }"
            withCredentials
            accept="image/*"
            :showUploadList="false"
            @change="handleUpload"
            :before-upload="beforeUpload"
          >
            <img v-if="row.icon" :src="row.icon" style="width:80px" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-form-item>
      </div>

      <section>
        <header>
          <div class="label light">添加商品（拖动可排序）</div>
        </header>
        <draggable
          v-model="row.items"
          @start="drag = true"
          @end="drag = false"
          v-bind="dragOptions"
        >
          <transition-group
            type="transition"
            tag="div"
            :name="!drag ? 'flip-list' : null"
          >
            <div
              v-for="(item, idx) in row.items"
              :key="item.id"
              class="config-product"
            >
              <div class="main">
                <img class="cover" :src="item.img" />
                <div class="name">{{ item.title }}</div>
              </div>
              <img
                @click="delItem(index, idx)"
                class="del-icon highlight"
                src="@/assets/icn-delete-popup@3x.png"
              />
            </div>
          </transition-group>
        </draggable>
        <a-form-model-item>
          <a-button style="width: 100%" @click="openProductDialog(index)">
            <a-icon type="plus" /> 添加商品
          </a-button>
        </a-form-model-item>
      </section>
    </section>

    <a-form-model-item>
      <a-button style="width: 100%" @click="addRow">
        <a-icon type="plus" /> 新增分类
      </a-button>
    </a-form-model-item>

    <!-- 商品选择器 -->
    <ProductDialog
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="true"
    />
  </a-form-model>
</template>

<script>
const DEFAULT_CATEGORY = {
  title: "分类",
  icon: "",
  items: []
};

import draggable from "vuedraggable";

import uploadMixin from "@/mixins/upload";

import { randomKey } from "@/utils/tool";

export default {
  data() {
    return {
      rowIndex: 0,

      drag: false
    };
  },
  props: {
    res: Object
  },
  computed: {
    dragOptions() {
      return {
        animation: 200
      };
    }
  },

  mixins: [uploadMixin],

  methods: {
    openProductDialog(index) {
      this.rowIndex = index;
      this.$refs.productDialog.show();
    },

    addRow() {
      this.res.rows.push({ ...DEFAULT_CATEGORY, key: randomKey() });
    },
    delRow(index) {
      this.res.rows.splice(index, 1);

      // 默认一条记录
      if (this.res.rows.length <= 0) {
        this.addRow();
      }
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (
          this.res.rows[this.rowIndex].items.findIndex(x => x.id === val.id) >
          -1
        ) {
          continue;
        }

        this.res.rows[this.rowIndex].items.push({ ...val, key: randomKey() });
      }
    },
    delItem(index, idx) {
      this.res.rows[index].items.splice(idx, 1);
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;
        switch (parseInt(this.res.type)) {
          case 1:
            {
              const obj = this.res.rows[index];
              const newValue = { ...obj, icon: url };
              this.$set(this.res.rows, index, newValue);
            }
            break;
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable
  }
};
</script>

<style lang="scss">
.category-panel {
}
</style>
