<template>
  <div class="notice-wrap" :style="res.style">
    <div class="notice-box" :style="res.boxStyle">
      <Style2 v-if="res.type == 2" :res="res" />
      <Scroll v-else-if="res.type == 3" :res="res" />
      <VScroll v-else-if="res.type == 4" :res="res" />
      <Style1 v-else :res="res" />
    </div>
  </div>
</template>

<script>
import Style1 from "./style1.vue";
import Style2 from "./style2.vue";
import Scroll from "./scroll.vue";
import VScroll from "./v-scroll.vue";

export default {
  props: {
    res: Object
  },
  components: { Style1, Style2, Scroll, VScroll }
};
</script>

<style lang="scss">
.notice-wrap {
  background: #fff;
  color: #a18e74;
  font-size: 16px;

  .notice-box {
    overflow: hidden;
    border-style: solid;
  }
}
</style>
