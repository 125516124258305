<template>
  <div class="prize1">
    <div class="title" :style="res.titleStyle">{{ res.title }}</div>
    <div class="img" :style="imgStyle">
      <img :src="res.img" />
    </div>
    <section>
      <div class="desc" :style="res.descStyle">{{ res.prizeText }}</div>
      <div class="btn" :style="res.btnStyle">
        <span>{{ res.btnTitle }}</span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    imgStyle() {
      const {
        style: { paddingLeft },
        boxStyle: { padding, borderWidth }
      } = this.res;

      let width =
        375 -
        parseInt(paddingLeft) * 2 -
        parseInt(padding) * 2 -
        parseInt(borderWidth) * 2;
      let height = (width * 4) / 5;
      return {
        height: height + "px"
      };
    }
  },

  methods: {}
};
</script>

<style lang="scss">
.prize1 {
  .img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    margin-bottom: 10px;
  }
}
</style>
