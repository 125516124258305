<template>
  <a-form-model>
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
    >
      <p slot="description">
        1、请先确认已经发布首页、并且在小商店后台创建并发布了分类，否则分类页Tab页不会显示；
        2、店铺分类自动同步小商店后台，请注意按排列顺序创建分类。
      </p>
    </a-alert>

    <section>
      <header class="group">数据</header>
      <a-form-model-item label="商品排序" v-bind="horizonLayout">
        <a-select v-model="res.sort" placeholder="请选择">
          <a-select-option :value="0">默认排序</a-select-option>
          <a-select-option :value="1">最新上架</a-select-option>
          <a-select-option :value="2">价格从低到高</a-select-option>
          <a-select-option :value="3">价格从高到低</a-select-option>
        </a-select>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">分类</header>
      <a-form-model-item label="样式" v-bind="horizonLayout">
        <a-select v-model="(res.iconStyle || {}).type" placeholder="请选择">
          <a-select-option :value="1">
            样式一
          </a-select-option>
          <a-select-option :value="2">
            样式二
          </a-select-option>
          <a-select-option :value="3">
            样式三
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="(res.iconStyle || {}).color" />
      </a-form-model-item>

      <header class="group">商品卡片</header>

      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.marginBottom" :min="0" :max="15" />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>
      <a-form-model-item label="卡片颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>

      <header class="group">标题</header>
      <template>
        <a-form-model-item label="字号" v-bind="horizonLayout">
          <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
            <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="粗细" v-bind="horizonLayout">
          <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
            <a-select-option
              v-for="f in fontWeightList"
              :key="f.id"
              :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.titleStyle.color" />
        </a-form-model-item>
        <a-form-model-item label="行数" v-bind="horizonLayout">
          <a-select
            v-model="res.titleStyle['-webkitLineClamp']"
            placeholder="请选择"
          >
            <a-select-option value="1">1行</a-select-option>
            <a-select-option value="2">2行</a-select-option>
          </a-select>
        </a-form-model-item>
      </template>

      <header class="group">价格</header>
      <template>
        <a-form-model-item label="字号" v-bind="horizonLayout">
          <a-select v-model="res.priceStyle.fontSize" placeholder="请选择">
            <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="粗细" v-bind="horizonLayout">
          <a-select v-model="res.priceStyle.fontWeight" placeholder="请选择">
            <a-select-option
              v-for="f in fontWeightList"
              :key="f.id"
              :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.priceStyle.color" />
        </a-form-model-item>
      </template>

      <header class="group">
        <div class="label">
          购买按钮
        </div>
        <div class="pending"></div>
        <div class="opt">
          <a-switch v-model="res.showBuyButton" />
        </div>
      </header>
      <template v-if="res.showBuyButton">
        <a-form-model-item label="大小" v-bind="horizonLayout">
          <a-radio-group
            v-model="res.btnStyle.size"
            @change="changeBuyButtonSize"
          >
            <a-radio :value="1">小</a-radio>
            <a-radio :value="0">中</a-radio>
            <a-radio :value="2">大</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item class="btnStyle">
          <a-radio-group v-model="res.btnStyle.index">
            <a-radio v-for="i in 22" :key="i" :value="i">
              <div class="ic-buy" :class="'ic-buy-' + i" :style="buyIconStyle"
            /></a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.btnStyle.backgroundColor" />
        </a-form-model-item>
      </template>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input v-model="res.style.paddingTop" :min="0" :max="100" />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";
import uploadMixin from "@/mixins/upload";

const BUTTON_ICON_SIZE = [
  // 中
  {
    width: "20px",
    height: "20px"
  },
  // 小
  {
    width: "16px",
    height: "16px"
  },
  // 大
  {
    width: "24px",
    height: "24px"
  }
];

export default {
  data() {
    return {
      tipsKey: "hide-cat-tips"
    };
  },
  props: {
    res: Object
  },
  computed: {
    buyIconStyle() {
      return {
        backgroundColor: this.res.btnStyle.backgroundColor
      };
    }
  },

  mixins: [textMixin, tipsMixin, uploadMixin],

  methods: {
    changeBuyButtonSize(evt) {
      const size = evt.target.value;
      this.resetBtnStyle(size);
    },

    resetBtnStyle(size) {
      const val = BUTTON_ICON_SIZE[size];
      this.res.btnStyle = { ...this.res.btnStyle, ...val };
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    SliderInput,
    ColorPicker
  }
};
</script>

<style lang="scss">
.btnStyle {
  .ant-radio-wrapper {
    width: calc(25% - 8px);
    margin-top: 8px;

    .plainBuyBtn {
      font-size: 10px;
      background: #ec4b30;
      color: #fff;
      border-radius: 4px;
      padding: 4px;
    }

    .ic-buy {
      background: #ec4b30;
    }
  }
}
</style>
