<template>
  <div class="preview-container">
    <div class="module-preview">
      <header>
        <div class="shop">
          <img alt="" :src="logo" /><span>{{ name }}</span>
        </div>
        <div class="pending"></div>
        <div class="tip">拖拽模块可排序</div>
      </header>

      <Modules />

      <div style="height: 100px" />
    </div>
    <Live v-if="liveEnabled" :res="fix['live'].res" />
  </div>
</template>

<script>
import Modules from "./modules/index.vue";

// 固定位置组件
import Live from "./modules/live.vue";

import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  props: {},

  computed: {
    ...mapState({
      logo: state => state.store.logo,
      name: state => state.store.name,
      fix: state => state.design.fix
    }),

    liveEnabled() {
      return "live" in this.fix;
    }
  },

  components: {
    Modules,
    Live
  }
};
</script>

<style lang="scss">
.preview-container {
  position: relative;
}

.module-preview {
  flex: 0 0 auto;
  user-select: none;
  height: 100%;
  overflow-y: scroll;
  width: 375px;
  border-radius: 12px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
  background-color: #f5f6f7;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  > header {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    top: 0;
    background: #fff;
    z-index: 1;
    position: sticky;
    left: 0;
    right: 0;

    .shop {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .pending {
      flex: 1 1 auto;
    }

    .tip {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
