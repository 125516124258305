<template>
  <div class="notice">
    <div class="item">
      <div class="content">
        <img v-if="showIcon" class="icn" :src="icon" />
        <div class="desc">{{ res.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    icon() {
      return this.res.icon
        ? this.res.icon
        : require("@/assets/icn-notice@3x.png");
    },

    showIcon() {
      return this.res.iconType !== 9;
    }
  }
};
</script>

<style lang="scss">
.notice {
  .item {
    .content {
      // border: 1px solid rgba(187, 172, 142, 0.35);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;

      .icn {
        width: 264px;
        // height: 55px;
        margin-bottom: 18px;
      }

      .desc {
        text-align: center;
        white-space: pre-wrap;
        // line-height: 2;
      }
    }
  }
}
</style>
