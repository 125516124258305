<template>
  <a-form-model>
    <!-- <a-form-model-item label="样式">
      <a-radio-group v-model="res.type">
        <a-radio :value="1">拼接展示</a-radio>
        <a-radio :value="2" disabled>更多样式</a-radio>
      </a-radio-group>
    </a-form-model-item> -->

    <!-- 拼接展示 -->
    <template v-if="res.type == 1">
      <draggable
        v-model="res.cards"
        handle="header"
        @start="drag = true"
        @end="drag = false"
        v-bind="{
          animation: 200
        }"
      >
        <transition-group
          type="transition"
          tag="div"
          :name="!drag ? 'flip-list' : null"
        >
          <section
            v-for="(row, index) in res.cards"
            :key="row.key"
            class="compact"
          >
            <header>
              <div class="label">
                <a-icon type="menu" class="drag-handler" /> Banner
              </div>
              <div class="pending"></div>
              <div @click="delHalfRow(index)" class="opt">
                <a-icon type="close" />
              </div>
            </header>

            <!-- 整行 -->
            <template v-if="row.rows.length == 1">
              <div v-for="(item, idx) in row.rows" :key="item.key">
                <a-row>
                  <a-col>
                    <a-upload
                      class="upload-big"
                      :data="{ index: index, idx: idx }"
                      listType="picture-card"
                      :action="UPLOAD_URL"
                      :headers="{ t: getToken }"
                      withCredentials
                      accept="image/*"
                      :showUploadList="false"
                      @change="handleUpload"
                      :before-upload="beforeUpload"
                    >
                      <img v-if="item.img" :src="item.img" />
                      <div v-else>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                          上传图片
                        </div>
                      </div>
                    </a-upload>
                  </a-col>
                </a-row>

                <a-form-model-item label="跳转至">
                  <a-row :gutter="10">
                    <a-col :span="8">
                      <a-select v-model="item.target" placeholder="请选择">
                        <a-select-option
                          v-for="t in targetList"
                          :key="t.id"
                          :value="t.id"
                          >{{ t.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="16">
                      <!-- 商品 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 1"
                        v-model="item.productId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="p in productList"
                          :key="p.id"
                          :value="p.id"
                          >{{ p.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 带货商品 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 6"
                        v-model="item.productId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="p in productList2"
                          :key="p.id"
                          :value="p.id"
                          >{{ p.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 优惠券 -->
                      <a-select
                        v-if="item.target == 2"
                        v-model="item.couponId"
                        placeholder="请选择"
                        notFoundContent="请在小商店后台创建优惠券"
                      >
                        <a-select-option
                          v-for="c in couponList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.name }}
                        </a-select-option>
                      </a-select>

                      <!-- 分类 -->
                      <a-tree-select
                        v-if="item.target == 3"
                        v-model="item.categoryId"
                        style="width: 100%"
                        :dropdown-style="{
                          maxHeight: '400px',
                          overflow: 'auto'
                        }"
                        :tree-data="categoryTree"
                        placeholder="请选择"
                        notFoundContent="请在小商店后台创建店铺分类"
                        tree-default-expand-all
                      >
                      </a-tree-select>

                      <!-- 页面 -->
                      <a-select
                        v-if="item.target == 4"
                        v-model="item.pageId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in pageList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.name }}
                        </a-select-option>
                      </a-select>

                      <!-- 拼团 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 7"
                        v-model="item.pinId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in pinList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 砍价 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 8"
                        v-model="item.kanId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in kanList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 分享有礼 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 9"
                        v-model="item.shareId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in shareList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 抽奖 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 14"
                        v-model="item.prizeId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in prizeList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 招募推荐官 -->
                      <a-input
                        v-if="item.target == 11"
                        v-model="item.planId"
                        :max-length="10"
                        type="number"
                        placeholder="请复制招募计划 planId"
                      />

                      <!-- 小程序 -->
                      <a-input
                        v-if="item.target == 15"
                        v-model="item.minapp"
                        :max-length="255"
                        placeholder="appid#query 或 #小程序链接"
                      />

                      <!-- 网页 -->
                      <a-input
                        v-if="item.target == 12"
                        v-model="item.url"
                        :max-length="200"
                        placeholder="请输入网页地址"
                      />

                      <!-- 拨打电话 -->
                      <a-input
                        v-if="item.target == 20"
                        v-model="item.tel"
                        :max-length="100"
                        placeholder="请输入电话号码"
                      />
                      <!-- 复制文本 -->
                      <a-input
                        v-if="item.target == 21"
                        v-model="item.text"
                        :max-length="100"
                        placeholder="请输入文本"
                      />
                      <a-upload
                        v-if="item.target == 22"
                        :data="{ index, idx: 100 + idx }"
                        :action="UPLOAD_URL"
                        :headers="{ t: getToken }"
                        withCredentials
                        accept="image/*"
                        :showUploadList="false"
                        @change="handleUpload"
                        :before-upload="beforeUpload"
                      >
                        <img
                          v-if="item.qrcode"
                          class="upload-image-small"
                          :src="item.qrcode"
                        />
                        <a-button-group>
                          <a-button>
                            <a-icon type="upload" />
                            {{ item.qrcode ? "更换" : "上传" }}图片
                          </a-button>
                        </a-button-group>
                      </a-upload>
                    </a-col>
                  </a-row>
                </a-form-model-item>
              </div>
            </template>

            <!-- 分栏 -->
            <template v-else>
              <!-- 图片 -->
              <a-row :gutter="10">
                <a-col
                  v-for="(item, idx) in row.rows"
                  :key="item.key"
                  :span="12"
                >
                  <a-upload
                    class="upload-big"
                    :data="{ index: index, idx: idx }"
                    listType="picture-card"
                    :action="UPLOAD_URL"
                    :headers="{ t: getToken }"
                    withCredentials
                    accept="image/*"
                    :showUploadList="false"
                    @change="handleUpload"
                    :before-upload="beforeUpload"
                  >
                    <img v-if="item.img" :src="item.img" style="width:100%" />
                    <div v-else>
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传图片
                      </div>
                    </div>
                  </a-upload>
                </a-col>
              </a-row>
              <!-- 跳转 -->
              <a-form-model-item label="跳转至">
                <div v-for="(item, idx) in row.rows" :key="item.key">
                  <a-row :gutter="10">
                    <a-col :span="8">
                      <a-select v-model="item.target" placeholder="请选择">
                        <a-select-option
                          v-for="t in targetList"
                          :key="t.id"
                          :value="t.id"
                          >{{ t.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="16">
                      <!-- 商品 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 1"
                        v-model="item.productId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="p in productList"
                          :key="p.id"
                          :value="p.id"
                          >{{ p.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 带货商品 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 6"
                        v-model="item.productId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="p in productList2"
                          :key="p.id"
                          :value="p.id"
                          >{{ p.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 优惠券 -->
                      <a-select
                        v-if="item.target == 2"
                        v-model="item.couponId"
                        placeholder="请选择"
                        notFoundContent="请在小商店后台创建优惠券"
                      >
                        <a-select-option
                          v-for="c in couponList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.name }}
                        </a-select-option>
                      </a-select>

                      <!-- 分类 -->
                      <a-tree-select
                        v-if="item.target == 3"
                        v-model="item.categoryId"
                        style="width: 100%"
                        :dropdown-style="{
                          maxHeight: '400px',
                          overflow: 'auto'
                        }"
                        :tree-data="categoryTree"
                        placeholder="请选择"
                        notFoundContent="请在小商店后台创建店铺分类"
                        tree-default-expand-all
                      >
                      </a-tree-select>

                      <!-- 页面 -->
                      <a-select
                        v-if="item.target == 4"
                        v-model="item.pageId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in pageList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.name }}
                        </a-select-option>
                      </a-select>

                      <!-- 拼团 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 7"
                        v-model="item.pinId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in pinList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 砍价 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 8"
                        v-model="item.kanId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in kanList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 分享有礼 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 9"
                        v-model="item.shareId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in shareList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 抽奖 -->
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-if="item.target == 14"
                        v-model="item.prizeId"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="c in prizeList"
                          :key="c.id"
                          :value="c.id"
                          >{{ c.title }}
                        </a-select-option>
                      </a-select>

                      <!-- 招募推荐官 -->
                      <a-input
                        v-if="item.target == 11"
                        v-model="item.planId"
                        :max-length="10"
                        type="number"
                        placeholder="请复制招募计划 planId"
                      />

                      <!-- 小程序 -->
                      <a-input
                        v-if="item.target == 15"
                        v-model="item.minapp"
                        :max-length="255"
                        placeholder="appid#query 或 #小程序链接"
                      />

                      <!-- 网页 -->
                      <a-input
                        v-if="item.target == 12"
                        v-model="item.url"
                        :max-length="200"
                        placeholder="请输入网页地址"
                      />

                      <!-- 拨打电话 -->
                      <a-input
                        v-if="item.target == 20"
                        v-model="item.tel"
                        :max-length="100"
                        placeholder="请输入电话号码"
                      />
                      <!-- 复制文本 -->
                      <a-input
                        v-if="item.target == 21"
                        v-model="item.text"
                        :max-length="100"
                        placeholder="请输入文本"
                      />
                      <a-upload
                        v-if="item.target == 22"
                        :data="{ index, idx: 100 + idx }"
                        :action="UPLOAD_URL"
                        :headers="{ t: getToken }"
                        withCredentials
                        accept="image/*"
                        :showUploadList="false"
                        @change="handleUpload"
                        :before-upload="beforeUpload"
                      >
                        <img
                          v-if="item.qrcode"
                          class="upload-image-small"
                          :src="item.qrcode"
                        />
                        <a-button-group>
                          <a-button>
                            <a-icon type="upload" />
                            {{ item.qrcode ? "更换" : "上传" }}图片
                          </a-button>
                        </a-button-group>
                      </a-upload>
                    </a-col>
                  </a-row>
                </div>
              </a-form-model-item>
            </template>
          </section>
        </transition-group>
      </draggable>

      <a-row :gutter="10">
        <a-col :span="12">
          <a-form-model-item>
            <a-button style="width: 100%" @click="addHalfRow(1)">
              <a-icon type="plus" /> 新增整栏
            </a-button>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item>
            <a-button style="width: 100%" @click="addHalfRow(2)">
              <a-icon type="plus" /> 新增分栏
            </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>

      <section>
        <header class="group">图片</header>
        <a-form-model-item label="圆角" v-bind="horizonLayout">
          <slider-input
            v-model="res.imageStyle.borderRadius"
            :min="0"
            :max="25"
          />
        </a-form-model-item>
        <a-form-model-item label="间距" v-bind="horizonLayout">
          <slider-input v-model="res.imageStyle.margin" :min="0" :max="15" />
        </a-form-model-item>
        <a-form-model-item label="阴影强度" v-bind="horizonLayout">
          <slider-input
            v-model="res.imageStyle.boxShadowValue"
            :min="0"
            :max="25"
            @change="
              value =>
                (res.imageStyle.boxShadow =
                  value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
            "
          />
        </a-form-model-item>
        <a-form-model-item label="边框宽度" v-bind="horizonLayout">
          <slider-input
            v-model="res.imageStyle.borderWidth"
            :min="0"
            :max="25"
          />
        </a-form-model-item>
        <a-form-model-item label="边框颜色" v-bind="horizonLayout">
          <color-picker v-model="res.imageStyle.borderColor" />
        </a-form-model-item>

        <header class="group">背景</header>
        <a-form-model-item label="背景颜色" v-bind="horizonLayout">
          <color-picker v-model="res.style.backgroundColor" />
        </a-form-model-item>
        <a-form-item label="背景图片" v-bind="horizonLayout">
          <a-upload
            :data="{ index: 9999 }"
            listType="picture-card"
            :action="UPLOAD_URL"
            :headers="{ t: getToken }"
            withCredentials
            accept="image/*"
            :showUploadList="false"
            @change="handleUpload"
            :before-upload="beforeUpload"
          >
            <div v-if="res.backgroundImage" class="imageBox">
              <img :src="res.backgroundImage" style="width:80px" />
              <img
                @click.stop="removeBackImage"
                class="del-icon highlight"
                src="@/assets/icn-delete-popup@3x.png"
              />
            </div>
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-model-item label="填充模式" v-bind="horizonLayout">
          <a-radio-group
            v-model="res.style.backgroundSize"
            button-style="solid"
          >
            <a-radio-button value="cover">封面</a-radio-button>
            <a-radio-button value="contain">包含</a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <header class="group">间距</header>
        <a-form-model-item label="左右间距" v-bind="horizonLayout">
          <slider-input
            v-model="res.style.paddingLeft"
            @change="value => (res.style.paddingRight = value)"
            :min="0"
            :max="100"
          />
        </a-form-model-item>
        <a-form-model-item label="上下间距" v-bind="horizonLayout">
          <slider-input
            v-model="res.style.paddingTop"
            @change="value => (res.style.paddingBottom = value)"
            :min="0"
            :max="100"
          />
        </a-form-model-item>
      </section>
    </template>
  </a-form-model>
</template>

<script>
import draggable from "vuedraggable";

import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import textMixin from "@/mixins/text";
import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import uploadMixin from "@/mixins/upload";
import targetMixin from "@/mixins/target";

import { randomKey } from "@/utils/tool";

const DEFAULT_HALF_BANNER = {
  rows: [
    {
      img: "",
      target: 1,
      productId: null,
      couponId: null,
      categoryId: null,
      pageId: null
    }
  ]
};
const DEFAULT_HALF_BANNER_2 = {
  rows: [
    {
      img: "",
      target: 1,
      productId: null,
      couponId: null,
      categoryId: null,
      pageId: null
    },
    {
      img: "",
      target: 1,
      productId: null,
      couponId: null,
      categoryId: null,
      pageId: null
    }
  ]
};

export default {
  data() {
    return { drag: false };
  },
  props: {
    res: Object
  },

  mixins: [
    textMixin,

    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin,
    uploadMixin,
    targetMixin
  ],

  computed: {},

  methods: {
    addHalfRow(col = 1) {
      const key = randomKey();
      let row = col === 1 ? DEFAULT_HALF_BANNER : DEFAULT_HALF_BANNER_2;
      row = JSON.parse(JSON.stringify(row));
      row.key = key;
      row.rows.forEach((x, i) => {
        x.key = key + i;
      });
      this.res.cards.push({ ...row });
    },
    delHalfRow(index) {
      this.res.cards.splice(index, 1);

      // 默认一条记录
      if (this.res.cards.length <= 0) {
        this.addHalfRow();
      }
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, idx, url } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        if (idx >= 100) {
          const idx2 = idx - 100;
          const obj = this.res.cards[index].rows[idx2];
          const newValue = { ...obj, qrcode: url };
          this.$set(this.res.cards[index].rows, idx2, newValue);
        } else {
          const obj = this.res.cards[index].rows[idx];
          const newValue = { ...obj, img: url };
          this.$set(this.res.cards[index].rows, idx, newValue);
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable,

    ColorPicker,
    SliderInput
  }
};
</script>

<style></style>
