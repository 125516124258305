<template>
  <div class="pin-scroll">
    <div v-for="(item, index) in rows" :key="item.id" class="item">
      <div class="cover" :style="imageStyle(index)" />
      <div class="name" :style="res.productTitleStyle">
        {{ item.title }}
      </div>
      <div class="num-wrap">
        <div class="num">{{ item.num }}人团</div>
      </div>
      <div class="price-wrap">
        <div class="price" :style="res.priceStyle">￥{{ item.pinPrice }}</div>
        <span class="origin-price">￥{{ item.price }}</span>
      </div>
      <div class="buy" :style="res.btnStyle">
        {{ res.btnText }}
      </div>
    </div>
    <div v-if="rows.length <= 0" class="empty">
      <img src="@/assets/icn-grid-default-2@3x.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object,
    rows: Array
  },

  methods: {
    imageStyle(index) {
      let width = "100%";
      const row = this.rows[index];

      const {
        style: { paddingLeft },
        imageStyle: { borderRadius }
      } = this.res;

      const gap = 4;
      width = (370 - 30 - parseInt(paddingLeft) * 2) * 0.4 - gap;
      const height = width;

      return {
        width: width + "px",
        height: height + "px",
        borderRadius: borderRadius,
        backgroundImage: `url(${row.img})`
      };
    }
  }
};
</script>

<style lang="scss">
.pin-scroll {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: scroll;

  .item {
    position: relative;
    flex-shrink: 0;
    width: calc(40% - 5px);
    margin-right: 6px;
    margin-bottom: 6px;
    border-radius: 6px;
    padding-bottom: 15px;
    overflow: hidden;

    .cover {
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .name {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 1.29;

      text-align: center;
      padding: 0 7px;
      margin-top: 10px;

      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-height: 2.6;
    }

    .num-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .num {
      display: inline;
      margin: 2px auto;
      background: #f00;
      font-size: 11px;
      padding: 0 5px;
      border-radius: 4px;
      overflow: hidden;
      color: #fff;
    }

    .price-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 7px;
      margin-top: 5px;

      .price {
        font-size: 16px;
        font-weight: 600;
        color: #ec4b30;
        line-height: 1.07;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .origin-price {
        font-size: 12px;
        color: #7f7f7f;
        opacity: 0.5;
        text-decoration: line-through;
      }
    }

    .buy {
      width: 72px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: #ec4b30;
      color: #fff;
      line-height: 1;
      font-size: 13px;
      font-weight: 600;
      margin: 5px auto 0;
      border: 1px solid #fff;

      &.start {
        background: #3bcb3f;
      }

      &.end {
        background: #9d9d9d;
      }
    }

    .item:last-child {
      margin-right: 0;
    }
  }

  .empty img {
    width: 100%;
  }
}
</style>
