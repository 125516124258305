<template>
  <div class="title-wrap">
    <div class="content" :style="res.style">
      <span>
        {{ res.content }}
        <template v-if="res.showFlag">
          <div
            v-if="res.style.textAlign != 'left'"
            class="tf tf1"
            :class="flagClass"
            :style="flagStyle"
          />
          <div
            v-if="res.style.textAlign != 'right'"
            class="tf tf2"
            :class="flagClass"
            :style="flagStyle"
          />
        </template>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    flagClass() {
      if (!this.res.showFlag) return "";

      return `flag-${this.res.flag}`;
    },

    flagStyle() {
      if (!this.res.showFlag) return {};

      const top = (parseInt(this.res.style.fontSize) - 12) / 2 + "px";
      return {
        top
      };
    }
  }
};
</script>

<style lang="scss">
.title-wrap {
  overflow: hidden;

  span {
    position: relative;
    display: inline-block;
    line-height: 1em;
  }

  .tf {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;

    &.tf1 {
      left: -30px;
    }

    &.tf2 {
      transform: rotate(180deg);
      right: -30px;
    }
  }
}

/* 标题文本装饰*/

.tf.flag-1 {
  background-image: url(https://store.xinsailei.com/tf/1.png);
}
.tf.flag-2 {
  background-image: url(https://store.xinsailei.com/tf/2.png);
}
.tf.flag-3 {
  background-image: url(https://store.xinsailei.com/tf/3.png);
}
.tf.flag-4 {
  background-image: url(https://store.xinsailei.com/tf/4.png);
}
.tf.flag-5 {
  background-image: url(https://store.xinsailei.com/tf/5.png);
}
.tf.flag-6 {
  background-image: url(https://store.xinsailei.com/tf/6.png);
}
.tf.flag-7 {
  background-image: url(https://store.xinsailei.com/tf/7.png);
}
.tf.flag-8 {
  background-image: url(https://store.xinsailei.com/tf/8.png);
}
.tf.flag-9 {
  background-image: url(https://store.xinsailei.com/tf/9.png);
}
.tf.flag-10 {
  background-image: url(https://store.xinsailei.com/tf/10.png);
}
.tf.flag-11 {
  background-image: url(https://store.xinsailei.com/tf/11.png);
}
.tf.flag-12 {
  background-image: url(https://store.xinsailei.com/tf/12.png);
}
.tf.flag-13 {
  background-image: url(https://store.xinsailei.com/tf/13.png);
}
.tf.flag-14 {
  background-image: url(https://store.xinsailei.com/tf/14.png);
}
.tf.flag-15 {
  background-image: url(https://store.xinsailei.com/tf/15.png);
}
.tf.flag-16 {
  background-image: url(https://store.xinsailei.com/tf/16.png);
}
.tf.flag-17 {
  background-image: url(https://store.xinsailei.com/tf/17.png);
}
.tf.flag-18 {
  background-image: url(https://store.xinsailei.com/tf/18.png);
}
.tf.flag-19 {
  background-image: url(https://store.xinsailei.com/tf/19.png);
}
.tf.flag-20 {
  background-image: url(https://store.xinsailei.com/tf/20.png);
}
.tf.flag-21 {
  background-image: url(https://store.xinsailei.com/tf/21.png);
}
.tf.flag-22 {
  background-image: url(https://store.xinsailei.com/tf/22.png);
}
.tf.flag-23 {
  background-image: url(https://store.xinsailei.com/tf/23.png);
}
.tf.flag-24 {
  background-image: url(https://store.xinsailei.com/tf/24.png);
}
.tf.flag-41 {
  width: 30px !important;
  background-image: url(https://store.xinsailei.com/tf/41.png);
}
.tf.flag-42 {
  width: 30px !important;
  background-image: url(https://store.xinsailei.com/tf/42.png);
}
.tf.flag-43 {
  width: 30px !important;
  background-image: url(https://store.xinsailei.com/tf/43.png);
}
.tf.flag-44 {
  width: 30px !important;
  background-image: url(https://store.xinsailei.com/tf/44.png);
}

.tf1.flag-41,
.tf1.flag-42,
.tf1.flag-43,
.tf1.flag-44 {
  left: -40px !important;
}

.tf2.flag-41,
.tf2.flag-42,
.tf2.flag-43,
.tf2.flag-44 {
  right: -40px !important;
}
</style>
