import { mapMutations } from "vuex";

const LimitedDiscountSvc = require("@/service/limited-discount");

export default {
  methods: {
    ...mapMutations(["setDiscountList"])
  },

  async mounted() {
    // 秒杀
    try {
      const list = await LimitedDiscountSvc.list();
      this.setDiscountList(list);
    } catch (err) {
      console.error(err);
      // this.$message.error("加载活动出错，错误：" + err.message);
    }
  }
};
