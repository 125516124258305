<template>
  <a-form-model class="cube-panel">
    <!-- 魔方 -->
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
    >
      <p slot="description">
        如何使用魔方组件？「<a
          target="_blank"
          href="https://mp.weixin.qq.com/s?__biz=Mzk0MzIxMTU3Ng==&mid=2247483914&idx=1&sn=a8f5fe9570515863e370561a91fc78e5&chksm=c336175df4419e4b6c62ec5cf925a34cce64f6887b46921354c4e3c1cba5daff6125c1a6fce4&token=312490012&lang=zh_CN#rd"
          >立即前往了解</a
        >」
      </p>
    </a-alert>

    <section>
      <a-form-model-item label="选择模板">
        <a-radio-group @change="changeTpl" v-model="res.tpl">
          <a-radio-button v-for="i in 8" :key="i" :value="i">
            <img class="tpl-icon" :src="icon(i)" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="res.tpl === 8" label="魔方密度">
        <a-select
          v-model="res.density"
          @change="changeDesity"
          placeholder="请选择"
        >
          <a-select-option :value="4">4x4</a-select-option>
          <a-select-option :value="5">5x5</a-select-option>
          <a-select-option :value="6">6x6</a-select-option>
          <a-select-option :value="7">7x7</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="魔方布局">
        <div class="decorate-cube">
          <ul class="cube-row" v-for="n in res.density" :key="n">
            <li
              :class="[
                'cube-item',
                {
                  'item-selecting': isSelecting(i, n),
                  'item-selected': isSelected(i, n)
                }
              ]"
              v-for="i in res.density"
              :key="i"
              :style="{
                width: cubeItemWidth + 'px',
                height: cubeItemHeight + 'px'
              }"
              :data-x="i"
              :data-y="n"
              @click="cubeItemClick($event)"
            >
              <a-icon
                type="plus"
                color="#bbbbb"
                :style="{ 'line-height': cubeItemHeight + 'px' }"
              />
            </li>
          </ul>

          <div
            @click="changeSelectIndex(index)"
            class="cube-selected"
            :class="index === selectIndex ? 'active' : null"
            v-for="(item, index) in res.cubeList"
            :key="`cube-${index}`"
            :style="{
              width: getCubeSelectedWidth(item) + 'px',
              height: getCubeSelectedHeight(item) + 'px',
              top: getCubeSelectedTop(item) + 'px',
              left: getCubeSelectedLeft(item) + 'px'
            }"
          >
            <div class="cube-selected-text"></div>
          </div>
        </div>
      </a-form-model-item>

      <div class="target-card" v-if="selectIndex <= -1">
        <a-icon type="info-circle" /> 请点选区块
      </div>
      <template v-else>
        <a-row class="target-card" :gutter="4">
          <a-col :span="6">
            <a-upload
              class="compact"
              :data="{ index: selectIndex }"
              listType="picture-card"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <div v-if="res.cubeList[selectIndex].img" class="imageBox">
                <img :src="res.cubeList[selectIndex].img" style="width:60px" />
              </div>
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </a-col>

          <a-col :span="18">
            <a-form-model-item label="跳转" v-bind="horizonLayout">
              <a-select
                v-model="res.cubeList[selectIndex].target"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="t in targetList"
                  :key="t.id"
                  :value="t.id"
                  >{{ t.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="地址" v-bind="horizonLayout">
              <!-- 商品 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.cubeList[selectIndex].target == 1"
                v-model="res.cubeList[selectIndex].productId"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="p in productList"
                  :key="p.id"
                  :value="p.id"
                  >{{ p.title }}
                </a-select-option>
              </a-select>

              <!-- 带货商品 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.cubeList[selectIndex].target == 6"
                v-model="res.cubeList[selectIndex].productId"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="p in productList2"
                  :key="p.id"
                  :value="p.id"
                  >{{ p.title }}
                </a-select-option>
              </a-select>

              <!-- 优惠券 -->
              <a-select
                v-if="res.cubeList[selectIndex].target == 2"
                v-model="res.cubeList[selectIndex].couponId"
                placeholder="请选择"
                notFoundContent="请在小商店后台创建优惠券"
              >
                <a-select-option
                  v-for="c in couponList"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.name }}
                </a-select-option>
              </a-select>

              <!-- 分类 -->
              <a-tree-select
                v-if="res.cubeList[selectIndex].target == 3"
                v-model="res.cubeList[selectIndex].categoryId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="categoryTree"
                placeholder="请选择"
                notFoundContent="请在小商店后台创建店铺分类"
                tree-default-expand-all
              >
              </a-tree-select>

              <!-- 页面 -->
              <a-select
                v-if="res.cubeList[selectIndex].target == 4"
                v-model="res.cubeList[selectIndex].pageId"
                placeholder="请选择"
              >
                <a-select-option v-for="c in pageList" :key="c.id" :value="c.id"
                  >{{ c.name }}
                </a-select-option>
              </a-select>

              <!-- 拼团 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.cubeList[selectIndex].target == 7"
                v-model="res.cubeList[selectIndex].pinId"
                placeholder="请选择"
              >
                <a-select-option v-for="c in pinList" :key="c.id" :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 砍价 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.cubeList[selectIndex].target == 8"
                v-model="res.cubeList[selectIndex].kanId"
                placeholder="请选择"
              >
                <a-select-option v-for="c in kanList" :key="c.id" :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 分享有礼 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.cubeList[selectIndex].target == 9"
                v-model="res.cubeList[selectIndex].shareId"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="c in shareList"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 抽奖 -->
              <a-select
                show-search
                :filter-option="filterOption"
                v-if="res.cubeList[selectIndex].target == 14"
                v-model="res.cubeList[selectIndex].prizeId"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="c in prizeList"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.title }}
                </a-select-option>
              </a-select>

              <!-- 招募推荐官 -->
              <a-input
                v-if="res.cubeList[selectIndex].target == 11"
                v-model="res.cubeList[selectIndex].planId"
                :max-length="10"
                type="number"
                placeholder="请复制招募计划 planId"
              />

              <!-- 小程序 -->
              <a-input
                v-if="res.cubeList[selectIndex].target == 15"
                v-model="res.cubeList[selectIndex].minapp"
                :max-length="255"
                placeholder="appid#query 或 #小程序链接"
              />

              <!-- 网页 -->
              <a-input
                v-if="res.cubeList[selectIndex].target == 12"
                v-model="res.cubeList[selectIndex].url"
                :max-length="200"
                placeholder="请输入网页地址"
              />

              <!-- 拨打电话 -->
              <a-input
                v-if="res.cubeList[selectIndex].target == 20"
                v-model="res.cubeList[selectIndex].tel"
                :max-length="100"
                placeholder="请输入电话号码"
              />
              <!-- 复制文本 -->
              <a-input
                v-if="res.cubeList[selectIndex].target == 21"
                v-model="res.cubeList[selectIndex].text"
                :max-length="100"
                placeholder="请输入文本"
              />
              <a-upload
                v-if="res.cubeList[selectIndex].target == 22"
                :data="{ index: 100 + selectIndex }"
                :action="UPLOAD_URL"
                :headers="{ t: getToken }"
                withCredentials
                accept="image/*"
                :showUploadList="false"
                @change="handleUpload"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="res.cubeList[selectIndex].qrcode"
                  class="upload-image-small"
                  :src="res.cubeList[selectIndex].qrcode"
                />
                <a-button-group>
                  <a-button>
                    <a-icon type="upload" />
                    {{ res.cubeList[selectIndex].qrcode ? "更换" : "上传" }}图片
                  </a-button>
                </a-button-group>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>

      <a-form-model-item label="图片间距">
        <slider-input v-model="res.gap" :min="0" :max="100" />
      </a-form-model-item>
    </section>

    <section>
      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import targetMixin from "@/mixins/target";

import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";

const DEFAULT_CUBE_TPL = {
  // 2列
  1: {
    num: 2,
    list: [
      [
        [1, 1],
        [2, 1]
      ],
      [
        [1, 2],
        [2, 2]
      ]
    ]
  },
  // 3列
  2: {
    num: 3,
    list: [
      [
        [1, 1],
        [3, 1]
      ],
      [
        [1, 2],
        [3, 2]
      ],
      [
        [1, 3],
        [3, 3]
      ]
    ]
  },
  // 4列
  3: {
    num: 4,
    list: [
      [
        [1, 1],
        [4, 1]
      ],
      [
        [1, 2],
        [4, 2]
      ],
      [
        [1, 3],
        [4, 3]
      ],
      [
        [1, 4],
        [4, 4]
      ]
    ]
  },
  // 4格
  4: {
    num: 2,
    list: [
      [
        [1, 1],
        [1, 1]
      ],
      [
        [1, 2],
        [1, 2]
      ],
      [
        [2, 1],
        [2, 1]
      ],
      [
        [2, 2],
        [2, 2]
      ]
    ]
  },
  // 左1+右2
  5: {
    num: 2,
    list: [
      [
        [1, 1],
        [2, 1]
      ],
      [
        [1, 2],
        [1, 2]
      ],
      [
        [2, 2],
        [2, 2]
      ]
    ]
  },
  // 上1+下2
  6: {
    num: 2,
    list: [
      [
        [1, 1],
        [1, 2]
      ],
      [
        [2, 1],
        [2, 1]
      ],
      [
        [2, 2],
        [2, 2]
      ]
    ]
  },
  // 左1+右2
  7: {
    num: 4,
    list: [
      [
        [1, 1],
        [4, 2]
      ],
      [
        [1, 3],
        [2, 4]
      ],
      [
        [3, 3],
        [4, 3]
      ],
      [
        [3, 4],
        [4, 4]
      ]
    ]
  }
};

export default {
  data() {
    return {
      tipsKey: "hide-cube-tips",

      cubeWidth: 340, // 魔方宽度
      cubeHeight: 340, // 魔方高度
      cudeSelecting: {
        start: null,
        end: null,
        data: []
      },
      selectIndex: -1 // 选中的块
      // cubeList: [] // 已经生成的单元
    };
  },
  props: {
    res: Object
  },

  mixins: [
    uploadMixin,
    textMixin,
    tipsMixin,

    targetMixin,
    productMixin,
    categoryMixin,
    pageMixin,
    couponMixin
  ],

  computed: {
    // 单元魔方高度
    cubeItemHeight() {
      return this.cubeHeight / this.res.density;
    },

    // 单元魔方宽度
    cubeItemWidth() {
      return this.cubeWidth / this.res.density;
    }
  },

  async mounted() {},

  methods: {
    icon(i) {
      return require(`@/assets/cube/ic-cube-${i}.png`);
    },

    changeTpl(e) {
      const index = e.target.value;

      if (index >= 8) {
        this.res.density = 4;
        this.changeDesity();
        return;
      }

      const tpl = DEFAULT_CUBE_TPL[index];
      const { num, list } = tpl;
      this.res.density = num;

      this.changeDesity();

      let cubeList = [];
      for (const row of list) {
        const start = row[0];
        const end = row[1];

        let cube = {
          start: { x: start[0], y: start[1] },
          end: { x: end[0], y: end[1] },
          data: []
        };
        for (let i = start[0]; i <= end[0]; i++) {
          for (var j = start[1]; j <= end[1]; j++) {
            cube.data.push({ x: i, y: j });
          }
        }

        cubeList.push(cube);
      }

      this.res.cubeList = cubeList;
    },

    changeDesity() {
      this.res.cubeList = [];

      this.selectIndex = -1;

      // 清除正在选择的
      this.cudeSelecting.start = null;
      this.cudeSelecting.end = null;
      this.cudeSelecting.data.splice(0);
    },

    changeSelectIndex(index) {
      this.selectIndex = index;
    },

    // 魔方点击事件
    cubeItemClick(event) {
      var el = event.currentTarget;
      var x = el.getAttribute("data-x");
      var y = el.getAttribute("data-y");
      var domclass = el.getAttribute("class");
      // console.log("[" + x + "," + y + "," + domclass + "]执行了点击");

      var cudeSelectingStart = this.cudeSelecting.start;
      var coord = { x: x, y: y };
      if (-1 !== domclass.indexOf("item-selected")) {
        alert("已经被占用");

        return;
      }

      if (null == cudeSelectingStart) {
        this.cudeSelecting.start = coord;
        this.cudeSelecting.data.push(coord);

        this.selectIndex = -1;
      } else {
        this.cudeSelecting.end = coord;
        this.cudeSelecting.data.push(coord);

        // 获取开始和结束之间所有魔方单元
        var start = cudeSelectingStart;
        var end = coord;

        var x_start = Math.min(start.x, end.x);
        var x_end = Math.max(start.x, end.x);

        var y_start = Math.min(start.y, end.y);
        var y_end = Math.max(start.y, end.y);

        var real_start = {
          x: Math.min(start.x, end.x),
          y: Math.min(start.y, end.y)
        };

        var real_end = {
          x: Math.max(start.x, end.x),
          y: Math.max(start.y, end.y)
        };

        // 清空正在选择的
        this.cudeSelecting.data.splice(0);

        for (let i = x_start; i <= x_end; i++) {
          for (var j = y_start; j <= y_end; j++) {
            this.cudeSelecting.data.push({ x: i, y: j });
          }
        }

        var cudeSelectingData = this.cudeSelecting.data;

        var cubeListArr = [];
        for (let i = 0; i < cudeSelectingData.length; i++) {
          cubeListArr.push(cudeSelectingData[i]);
        }

        // console.log(cubeListArr);
        // console.log(real_start);
        // console.log(real_end);

        // 加入选中的
        var cubeList = {
          start: real_start,
          end: real_end,
          data: cubeListArr
        };

        this.res.cubeList.push(cubeList);

        // 自动选中
        this.changeSelectIndex(this.res.cubeList.length - 1);

        // 清除正在选择的
        this.cudeSelecting.start = null;
        this.cudeSelecting.end = null;
        this.cudeSelecting.data.splice(0);
      }
    },
    // 判断是否正在选择
    isSelecting: function(x, y) {
      var cudeSelectingData = this.cudeSelecting.data;
      for (var i = 0, len = cudeSelectingData.length; i < len; i++) {
        var coord = cudeSelectingData[i];

        if (
          parseInt(coord.x) === parseInt(x) &&
          parseInt(coord.y) === parseInt(y)
        ) {
          return true;
        }
      }
      return false;
    },

    // 判断是否已经选择
    isSelected(x, y) {
      var list = this.res.cubeList;
      for (var i = 0; i < list.length; i++) {
        var data = list[i].data;

        for (var j = 0; j < data.length; j++) {
          var coord = data[j];

          if (
            parseInt(coord.x) === parseInt(x) &&
            parseInt(coord.y) === parseInt(y)
          ) {
            return true;
          }
        }
      }
      return false;
    },

    // 计算选中层的宽度
    getCubeSelectedWidth(item) {
      return (
        (parseInt(item.end.y) - parseInt(item.start.y) + 1) * this.cubeItemWidth
      );
    },

    // 计算选中层的高度
    getCubeSelectedHeight(item) {
      return (
        (parseInt(item.end.x) - parseInt(item.start.x) + 1) *
        this.cubeItemHeight
      );
    },

    // 计算选中层的上边距离
    getCubeSelectedTop(item) {
      return (item.start.x - 1) * this.cubeItemHeight;
    },

    // 计算选中层的左边距离
    getCubeSelectedLeft(item) {
      return (item.start.y - 1) * this.cubeItemWidth;
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        if (index >= 100) {
          const idx = index - 100;
          const val = { ...this.res.cubeList[idx], qrcode: url };
          this.$set(this.res.cubeList, idx, val);
        } else {
          const val = { ...this.res.cubeList[index], img: url };
          this.$set(this.res.cubeList, index, val);
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style lang="scss">
.cube-panel {
  .compact {
    .ant-upload.ant-upload-select-picture-card {
      width: 80px;
      height: 80px;
      margin-bottom: 0;
    }
  }

  .tpl-icon {
    width: 20px;
    height: 20px;
  }

  .target-card {
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
  }

  .decorate-cube {
    position: relative;

    .cube-row {
      float: left;
      list-style: none;
      padding: 0;
      margin: 0;

      &:last-of-type .cube-item {
        border-right: 1px solid #e5e5e5;
      }
    }

    .cube-item {
      background: #f8f8f8;
      border-left: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;

      &:first-child {
        border-top: 1px solid #e5e5e5;
      }

      &.item-selecting {
        background: #e0edff;
        visibility: hidden;

        .ant-icon {
          display: none;
        }
      }

      .item-selected {
        .ant-icon {
          display: none;
        }
      }
    }

    .cube-selected {
      position: absolute;
      background-color: #fff;
      border: 1px solid #ebedf0;
      text-align: center;
      color: #7d7e80;
      cursor: pointer;
      box-sizing: border-box;

      &.active {
        background: #e0edff;
        border: 1px solid #155bd4;
        color: #155bd4;
        z-index: 2;
        cursor: auto;
      }

      .cube-selected-text {
        font-size: 12px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}
</style>
