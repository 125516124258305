import request from "./request";

/**
 * 模板
 */

/**
 * 查询
 */
export const list = () => request.get("/t/");

/**
 * 加载数据
 */
export const getData = id => request.get(`/t/get?id=${id}`);
