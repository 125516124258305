<template>
  <div class="live" :class="wrapClass" @click="selectFixElement('live')">
    <img class="img" :class="res.style.size" :src="icon" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

const DEFAULT_ICONS = {
  1: "icn-live@3x.png",
  2: "icn-live2.png",
  3: "icn-live3.gif"
};

export default {
  props: {
    res: Object
  },

  computed: {
    ...mapState({
      current: state => state.design.current
    }),

    icon() {
      if (this.res.icon) {
        return this.res.icon;
      }

      return require("@/assets/" + DEFAULT_ICONS[this.res.type || 1]);
    },

    wrapClass() {
      return (
        this.res.style.position + (this.current === "live" ? " active" : "")
      );
    }
  },

  methods: {
    ...mapMutations(["selectFixElement"])
  }
};
</script>

<style lang="scss">
.live {
  position: absolute;
  right: 10px;

  bottom: 10px;

  z-index: 20;
  transition: all 0.2s ease;
  cursor: pointer;

  &.rightTop {
    top: 80px !important;
    bottom: auto !important;
  }

  &.active {
    padding: 2px;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%),
      linear-gradient(90deg, silver 50%, transparent 50%),
      linear-gradient(0deg, silver 50%, transparent 50%),
      linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
    background-position: left top, right bottom, left bottom, right top;
    animation: border-dance 1s infinite linear;
  }

  img {
    width: 80px;
    // height: 112px;
    background: transparent;
    display: inline-block;

    &.mid {
      width: 60px;
    }
    &.small {
      width: 50px;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
