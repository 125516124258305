<template>
  <div class="richtext-wrap " :style="res.style">
    <div class="ql-snow">
      <div class="ql-editor">
        <div class="content" v-html="res.html"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";

export default {
  props: {
    res: Object
  },

  components: {
    // VueEditor
  }
};
</script>

<style lang="scss">
.richtext-wrap {
  .ql-editor {
    min-height: auto;
    height: auto;
    padding: 0;
  }
}
</style>
