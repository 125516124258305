<template>
  <div>
    <div class="module-tool">
      <header>基础组件</header>
      <div class="list">
        <draggable
          v-model="DEFAULT_TOOL"
          @start="startDrag"
          @end="endDrag"
          :move="checkMove"
          :clone="clone"
          :sort="false"
          chosen-class="drag-placeholder"
          :group="{
            name: 'toolbox',
            pull: 'clone',
            put: false
          }"
        >
          <div
            v-for="tool in DEFAULT_TOOL"
            :key="tool.name"
            @click="push(tool)"
            class="item normal"
          >
            <img :src="getImageUrl(tool.cls)" />
            <div>{{ tool.name }}</div>
          </div>
        </draggable>
      </div>
    </div>
    <div class="module-tool">
      <header>营销组件</header>
      <div class="list">
        <draggable
          v-model="AD_TOOL"
          @start="startDrag"
          @end="endDrag"
          :move="checkMove"
          :clone="clone"
          :sort="false"
          chosen-class="drag-placeholder"
          :group="{
            name: 'toolbox',
            pull: 'clone',
            put: false
          }"
        >
          <div
            v-for="tool in AD_TOOL"
            :key="tool.name"
            @click="push(tool)"
            class="item adv"
          >
            <img :src="getImageUrl(tool.cls)" />
            <div>{{ tool.name }}</div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const VueScrollTo = require("vue-scrollto");

import { getImageUrl, generateKey } from "@/utils/tool.js";
import { DEFAULT_TOOL, AD_TOOL } from "@/utils/toolbar.js";

export default {
  data() {
    return {
      AD_TOOL,

      isCategoryPage: false
    };
  },

  computed: {
    ...mapState({
      current: state => state.design.current
    }),

    DEFAULT_TOOL() {
      return DEFAULT_TOOL.filter(x => {
        return this.isCategoryPage
          ? [0, 2].includes(x.c || 0)
          : [0, 1].includes(x.c || 0);
      });
    }
  },

  methods: {
    ...mapMutations({ add: "addElement", selectElement: "selectElement" }),
    getImageUrl,

    push(tool) {
      this.add(JSON.parse(JSON.stringify(generateKey(tool))));

      if (tool.fix) {
        return;
      }

      // 自动滚动到顶部
      // 延迟滚动
      this.$nextTick(() => {
        if (!this.current) return;

        var options = {
          container: ".module-preview"
        };
        const element = `.module:nth-child(${this.current})`;
        VueScrollTo.scrollTo(element, 500, options);
      });
    },

    startDrag(evt) {
      this.drag = true;

      // 提示仅支持点击添加组件
      const {
        oldDraggableIndex,
        item: { classList }
      } = evt;

      let tool = null;
      if (classList.contains("normal")) {
        tool = DEFAULT_TOOL[oldDraggableIndex];
      }
      if (classList.contains("ad")) {
        tool = AD_TOOL[oldDraggableIndex];
      }

      if (tool) {
        const CLS = ["product", "cat"];
        if (tool.fix || CLS.includes(tool.cls)) {
          this.$message.info("请点击添加组件");
        }
      }
    },

    checkMove: function(evt) {
      const srcEle = evt.draggedContext.element;

      // 固定元素不允许拖动放置
      if (srcEle.fix) {
        return false;
      }

      // 仅限一个的组件：全部商品
      const srcCls = srcEle.cls;

      const CLS = ["product", "cat"];
      if (CLS.includes(srcCls)) {
        return false;
      }

      return true;
    },

    clone(tool) {
      return JSON.parse(JSON.stringify(generateKey(tool)));
    },

    endDrag(evt) {
      this.drag = false;
      this.selectElement(evt.newDraggableIndex);
    }
  },

  mounted() {
    this.isCategoryPage = this.$route.name == "DecorationCategory";
  }
};
</script>

<style lang="scss">
.module-tool {
  background-color: #fff;

  header {
    height: 42px;
    font-size: 12px;
    justify-content: center;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .list {
    & > div {
      display: flex;
      flex-wrap: wrap;
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(50% - 10px);
      padding: 10px 0;
      margin: 5px;
      border-radius: 10px;

      font-size: 13px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: rgba(0, 0, 0, 0.66);

      img {
        width: 32px;
        height: 32px;
        margin-bottom: 6px;
      }

      &:hover {
        background-color: #ddd;
      }
    }
  }
}
</style>
