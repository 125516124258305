// 默认工具栏
export const DEFAULT_TOOL = [
  {
    name: "轮播图",
    cls: "banner",
    v: 6,
    res: {
      type: 1,

      direction: 0,
      dots: true,
      speed: 5,
      interval: 5000,

      backgroundImage: "",

      rows: [
        {
          img: "",

          target: 1,
          productId: null,
          couponId: null,
          categoryId: null,
          pageId: null
        }
      ],

      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      imageStyle: {
        borderRadius: "0px",
        borderWidth: "0px",
        borderColor: "#fff",
        boxShadow: "",
        boxShadowValue: "0px"
      }
    }
  },

  {
    name: "拼贴图",
    cls: "card",
    v: 4,
    res: {
      type: 1,
      cards: [
        {
          rows: [
            {
              img: "",

              target: 1,
              productId: null,
              couponId: null,
              categoryId: null,
              pageId: null
            }
          ]
        }
      ],

      backgroundImage: "",

      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      imageStyle: {
        margin: "0px",
        borderRadius: "0px",
        borderWidth: "0px",
        borderColor: "#fff",
        boxShadow: "",
        boxShadowValue: "0px"
      }
    }
  },

  {
    name: "热区",
    cls: "hot-zone",
    v: 2,
    res: {
      img: "",
      rows: [],

      backgroundImage: "",

      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        margin: "0px",
        borderRadius: "0px",
        borderWidth: "0px",
        borderColor: "#fff",
        boxShadow: "",
        boxShadowValue: "0px"
      }
    }
  },

  {
    name: "魔方",
    cls: "cube",
    v: 0,
    res: {
      tpl: 8, // 模板
      density: 4, // 密度
      gap: "0px", // 图片间隔
      cubeList: [],

      backgroundImage: "",

      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        margin: "0px",
        borderRadius: "0px",
        borderWidth: "0px",
        borderColor: "#fff",
        boxShadow: "",
        boxShadowValue: "0px"
      }
    }
  },

  {
    name: "导航",
    cls: "navi",
    v: 4,
    res: {
      type: 1,
      rows: [
        {
          title: "分类",
          icon: "",

          target: 3,
          productId: null,
          couponId: null,
          categoryId: null,
          pageId: null
        },
        {
          title: "分类",
          icon: "",

          target: 3,
          productId: null,
          couponId: null,
          categoryId: null,
          pageId: null
        },
        {
          title: "分类",
          icon: "",

          target: 3,
          productId: null,
          couponId: null,
          categoryId: null,
          pageId: null
        },
        {
          title: "分类",
          icon: "",

          target: 3,
          productId: null,
          couponId: null,
          categoryId: null,
          pageId: null
        }
      ],

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "15px",
        paddingBottom: "15px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      iconStyle: {
        rate: "1",
        width: "54px",
        height: "54px",
        borderRadius: "16px",
        borderWidth: "0px",
        borderColor: "#eee",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      titleStyle: {
        fontSize: "12px",
        color: "#000",
        fontWeight: "normal",
        marginTop: "8px",
        marginBottom: "8px"
      }
    }
  },
  {
    name: "商品组",
    cls: "group",
    v: 7,
    res: {
      type: 2,
      title: "店长推荐",
      subTitle: "",
      ds: 0, // 数据源
      sort: 0, // 排序
      rows: [],

      // 显示控制
      showTitle: true,
      showSubTitle: false,
      showPrice: true,
      showFlag: false,
      showBuyButton: true,
      priceAndBtnAlign: "column", // 价格按钮排列
      lines: 0, // 显示行数

      backgroundImage: "",

      // 样式
      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "9px",
        paddingRight: "9px",
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      boxStyle: {
        backgroundColor: "#fff",
        borderRadius: "6px",
        borderWidth: "1px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px",
        marginBottom: "5px"
      },
      imageStyle: {
        rate: 1
      },
      titleStyle: {
        fontSize: "14px", // 字号
        fontWeight: "normal", // 粗细
        "-webkitLineClamp": "1", // 显示行数
        color: "rgba(0, 0, 0, 0.9)"
      },
      priceStyle: {
        fontSize: "15px", // 字号
        fontWeight: "bold", // 粗细
        color: "#ec4b30"
      },
      btnStyle: {
        index: 0, // 预设, 0:文字，其他图标
        size: 0, // 大小

        // 按大小自动切换
        fontSize: "13px", // 字号
        width: "72px",
        height: "30px",
        fontWeight: "bold",

        // 自定义
        color: "#fff",
        backgroundColor: "#ec4b30",
        borderColor: "#fff",
        borderRadius: "6px" // 圆角
      },
      flagStyle: {
        type: 1, // 1-3:文字，0：图片
        index: 0, // 预设, -1:自定义

        text: "HOT",
        icon: "",

        // 自定义
        backgroundColor: "#ec4b30",
        color: "#fff",
        left: "0" // 位置
      }
    }
  },

  {
    name: "标题文本",
    cls: "title-text",
    v: 4,
    res: {
      content: "标题",

      target: 0,
      productId: null,
      couponId: null,
      categoryId: null,
      pageId: null,
      qrcode: "",

      backgroundImage: "",

      // 装饰条
      showFlag: false,
      flag: 1,
      // 悬浮置顶
      fixed: false,

      style: {
        fontSize: "16px",
        textAlign: "center",
        lineHeight: "50px",
        letterSpacing: "0px",
        color: "#000",
        fontWeight: "normal",
        textDecoration: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",

        backgroundColor: "#fff",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: ""
      }
    }
  },

  {
    name: "搜索",
    cls: "search",
    v: 5,
    res: {
      content: "搜索商品",
      fixed: false,

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#f4f4f4",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        lineHeight: "32px",
        borderRadius: "20px",
        backgroundColor: "#fff"
      },
      textStyle: {
        justifyContent: "flex-start",
        color: "#ccc"
      }
    }
  },

  {
    name: "联系",
    cls: "friend",
    v: 5,
    res: {
      type: 1,
      subType: 1,
      weixin: "",
      tel: "",
      qrcode: "",
      title: "店主想和你成为好友",
      subTitle: "加好友进群有专属福利呦~",
      btnTitle: "加好友",
      icon: "", // 自定义图标

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        color: "#000"
      },
      boxStyle: {
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: "6px",
        borderWidth: "1px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      btnStyle: {
        color: "#fff",
        backgroundColor: "#30992e"
      }
    }
  },

  {
    name: "公告",
    cls: "notice",
    v: 6,
    res: {
      type: 3,
      iconType: 0,
      content: "欢迎光临",

      target: 0,
      productId: null,
      couponId: null,
      categoryId: null,
      pageId: null,
      qrcode: "",

      icon: "", // 自定义图标
      backgroundImage: "",

      style: {
        fontSize: "16px",
        color: "#FF6146",
        fontWeight: "bold",
        lineHeight: "22px",
        letterSpacing: "0px",
        textDecoration: "auto",

        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        backgroundColor: "transparent",
        padding: "0px",
        borderRadius: "6px",
        borderWidth: "0px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px"
      }
    }
  },

  {
    name: "富文本",
    cls: "richtext",
    v: 2,
    res: {
      html: "",

      backgroundImage: "",

      style: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "15px",
        paddingBottom: "15px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      }
    }
  },

  {
    name: "视频",
    cls: "movie",
    v: 6,
    res: {
      type: 1,
      src: "",
      autoplay: false,
      loop: false,
      muted: false,

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      videoStyle: {
        rate: 0.75,
        height: "281px",
        borderRadius: "0px",
        borderWidth: "0px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px",

        objectFit: "contain"
      }
    }
  },

  {
    name: "倒计时",
    cls: "counter",
    v: 2,
    res: {
      title: "距离结束还有",
      endTime: "",
      type: 1,
      align: "auto",

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        padding: "0px",
        backgroundColor: "transparent",
        borderRadius: "0px",
        boxShadow: "",
        boxShadowValue: "0px",
        borderWidth: "0px",
        borderColor: "#ccc"
      },
      titleStyle: {
        fontSize: "16px",
        fontWeight: "normal",
        fontStyle: "normal",
        color: "#000"
      },
      numStyle: {
        fontSize: "12px",
        fontWeight: "bold",
        color: "#fff",
        backgroundColor: "#f30305"
      },
      num2Style: {
        fontSize: "12px",
        fontWeight: "normal",
        color: "#f30305"
      }
    }
  },

  {
    name: "分割线",
    cls: "spacer",
    v: 3,
    res: {
      backgroundImage: "",

      style: {
        height: "1px",
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px"
      },
      boxStyle: {
        borderTopStyle: "solid",
        borderTopWidth: "0px",
        borderTopColor: "#cccccc"
      }
    }
  },

  {
    name: "全部商品",
    cls: "product",
    c: 1, // 0/null 所有页面都出现，1:只出现在首页/自定义页，2：只出现在分类页
    v: 3,
    res: {
      // 样式
      backgroundImage: "",

      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      }
    }
  },

  {
    name: "分类导航",
    cls: "cat",
    c: 2, // 2：只出现在分类页
    v: 5,
    res: {
      type: 1,
      sort: 0, // 排序

      showBuyButton: true,
      priceAndBtnAlign: "row between", // 价格按钮排列
      backgroundImage: "",

      // 样式
      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "9px",
        paddingRight: "9px",
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      // 分类样式
      iconStyle: {
        type: 1,
        color: "#ec4b30"
      },
      boxStyle: {
        backgroundColor: "#fff",
        borderRadius: "6px",
        borderWidth: "1px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px",
        marginBottom: "5px"
      },
      imageStyle: {
        rate: 1
      },
      titleStyle: {
        fontSize: "14px", // 字号
        fontWeight: "normal", // 粗细
        "-webkitLineClamp": "1", // 显示行数
        color: "rgba(0, 0, 0, 0.9)"
      },
      priceStyle: {
        fontSize: "15px", // 字号
        fontWeight: "bold", // 粗细
        color: "#ec4b30"
      },
      btnStyle: {
        index: 1, // 预设, 0:文字，其他图标
        size: 1, // 大小

        backgroundColor: "#ec4b30"
      }
    }
  }
];

// 营销工具
export const AD_TOOL = [
  {
    name: "限时秒杀",
    cls: "time-sale",
    v: 5,
    res: {
      type: 1,
      title: "限时秒杀",
      startTime: null,
      endTime: null,
      ds: 0, // 数据源
      rows: [], // 商品列表
      discountRows: [], // 秒杀列表

      // 状态颜色
      bgColorEnable: false,
      bgColorRunning: "#f30305",
      bgColorStart: "#3bcb3f",
      bgColorEnd: "#9d9d9d",

      backgroundImage: "",

      // 样式
      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px"
      },
      boxStyle: {
        borderRadius: "16px",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      imageStyle: {
        borderRadius: "8px"
      },
      titleStyle: {
        fontSize: "17px", // 字号
        fontWeight: "bold", // 粗细
        fontStyle: "normal" // 样式
      }
    }
  },

  {
    name: "拼团",
    cls: "pin",
    v: 4,
    res: {
      type: 1,
      title: "超值拼团",
      btnText: "立即拼",
      ds: 0, // 数据源
      rows: [],

      backgroundImage: "",

      // 样式
      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px"
      },
      boxStyle: {
        backgroundColor: "#f30305",
        borderRadius: "16px",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      titleStyle: {
        fontSize: "17px", // 字号
        fontWeight: "bold", // 粗细
        fontStyle: "normal", // 样式
        color: "#fff",
        textAlign: "left",
        lineHeight: "17px",
        letterSpacing: "0px"
      },
      imageStyle: {
        borderRadius: "8px"
      },
      productTitleStyle: {
        fontSize: "14px", // 字号
        fontWeight: "normal", // 粗细
        fontStyle: "normal", // 样式
        "-webkitLineClamp": "1", // 显示行数
        color: "rgba(0, 0, 0, 0.9)"
      },
      priceStyle: {
        fontSize: "15px", // 字号
        fontWeight: "bold", // 粗细
        color: "#ec4b30"
      },
      btnStyle: {
        fontSize: "13px", // 字号
        fontWeight: "normal",

        // 自定义
        color: "#fff",
        backgroundColor: "#ec4b30",
        borderColor: "#fff",
        borderRadius: "6px" // 圆角
      }
    }
  },

  {
    name: "砍价",
    cls: "kan",
    v: 3,
    res: {
      type: 1,
      title: "超值砍价",
      btnText: "立即砍价",
      ds: 0, // 数据源
      rows: [],

      backgroundImage: "",

      // 样式
      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px"
      },
      boxStyle: {
        backgroundColor: "#f30305",
        borderRadius: "16px",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      titleStyle: {
        fontSize: "17px", // 字号
        fontWeight: "bold", // 粗细
        fontStyle: "normal", // 样式
        color: "#fff",
        textAlign: "left",
        lineHeight: "17px",
        letterSpacing: "0px"
      },
      imageStyle: {
        borderRadius: "8px"
      },
      productTitleStyle: {
        fontSize: "14px", // 字号
        fontWeight: "normal", // 粗细
        fontStyle: "normal", // 样式
        "-webkitLineClamp": "1", // 显示行数
        color: "rgba(0, 0, 0, 0.9)"
      },
      priceStyle: {
        fontSize: "15px", // 字号
        fontWeight: "bold", // 粗细
        color: "#ec4b30"
      },
      btnStyle: {
        fontSize: "13px", // 字号
        fontWeight: "normal",

        // 自定义
        color: "#fff",
        backgroundColor: "#ec4b30",
        borderColor: "#fff",
        borderRadius: "6px" // 圆角
      }
    }
  },

  {
    name: "优惠券",
    cls: "coupon",
    v: 5,
    res: {
      type: 1,
      rows: [
        {
          id: "",
          name: "",
          type: 1,
          num1: "",
          num2: ""
        }
      ],

      backgroundImage: "",

      style: {
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",

        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "0px",
        marginBottom: "0px"
      },
      // 券面
      boxStyle: {
        backgroundColor: "rgba(243, 55, 56, 0.1)",
        padding: "15px",
        borderRadius: "6px",
        borderWidth: "0px",
        borderColor: "#ffffff",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      // 券名称
      titleStyle: {
        color: "#1f1200",
        fontSize: "13px",
        lineHeight: "13px",
        letterSpacing: "0px",
        fontWeight: "normal"
      },
      // 折扣
      valueStyle: {
        fontSize: "30px",
        color: "#f33738",
        lineHeight: "30px",
        letterSpacing: "0px",
        fontWeight: "bold"
      },
      // 详情
      descStyle: {
        color: "rgba(243, 55, 56, 0.5)"
      },
      // 按钮
      btnStyle: {
        color: "#fff",
        backgroundColor: "#f33738"
      }
    }
  },

  {
    name: "幸运抽奖",
    cls: "prize",
    v: 0,
    res: {
      type: 1,

      prizeId: null,

      img: "",
      title: "抽奖",
      prizeText: "奖品：",
      btnTitle: "去抽奖",

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        backgroundColor: "#EF5F43",
        padding: "10px",
        borderRadius: "6px",
        borderWidth: "0px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      titleStyle: {
        color: "#FBE1B3"
      },
      descStyle: {
        color: "#fff"
      },
      priceStyle: {
        color: "#FBE2B6"
      },
      btnStyle: {
        color: "#EF5F43",
        backgroundColor: "#fff"
      }
    }
  },

  {
    name: "分享有礼",
    cls: "share",
    v: 3,
    res: {
      shareId: null,
      num: 3,
      price: 5,
      unit: "折",

      title: "分享抢优惠券",
      subTitle: "分享3名好友可得",
      btnTitle: "立即分享",
      hideLeft: false, // 隐藏劵金额

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      boxStyle: {
        backgroundColor: "#EF5F43",
        padding: "10px",
        borderRadius: "6px",
        borderWidth: "0px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      titleStyle: {
        color: "#fff"
      },
      descStyle: {
        color: "#ffb0a0"
      },
      priceStyle: {
        color: "#FBE2B6"
      },
      btnStyle: {
        color: "#EF5F43",
        backgroundColor: "#FBE2B6"
      }
    }
  },

  {
    name: "会员",
    cls: "vip",
    v: 2,
    res: {
      type: 1,
      title: "店主邀你成为会员，享以下权益：",
      subTitle: "VIP专属优惠券，生日福利等",
      vipTitle: "VIP",
      btnTitle: "成为会员",

      backgroundImage: "",

      style: {
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",

        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        color: "#fff"
      },
      boxStyle: {
        backgroundColor: "#47474d",
        padding: "20px",
        borderRadius: "6px",
        borderWidth: "0px",
        borderColor: "#f5f5f5",
        boxShadow: "",
        boxShadowValue: "0px"
      },
      titleStyle: {
        textAlign: "left",
        marginBottom: "40px"
      },
      priceStyle: {
        fontWeight: "bold",
        color: "#ffd76a",
        fontSize: "40px"
      },
      btnStyle: {
        color: "#47474d",
        backgroundColor: "#ffd76a"
      }
    }
  },

  {
    name: "群裂变",
    cls: "qun",
    v: 0,
    res: {
      img: "",

      backgroundImage: "",

      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fff",
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat"
      },
      imageStyle: {
        margin: "0px",
        borderRadius: "0px",
        borderWidth: "0px",
        borderColor: "#fff",
        boxShadow: "",
        boxShadowValue: "0px"
      }
    }
  },

  /*
  {
    name: "店铺直播",
    cls: "live-bar",
    v: 0,
    res: {
      icon: "",
      content: "我的直播间",

      style: {
        backgroundColor: "#fff",

        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px"
      },
      boxStyle: {
        backgroundColor: "#e1ee14",
        color: "#f33738",
        borderRadius: "10px"
      },
      // 按钮
      btnStyle: {
        color: "#fff",
        backgroundColor: "#f33738"
      }
    }
  },
  */

  {
    name: "店铺直播",
    fix: true,
    cls: "live",
    v: 2,
    res: {
      type: 3,
      icon: "",

      style: {
        position: "rightTop",
        size: "small"
      }
    }
  }
];

/**
 * 升级数据
 */
export const upgrade = res => {
  let list = res.list || [],
    fix = res.fix || {};

  for (let row of list) {
    _upgrade(row);
  }

  for (let row of Object.keys(fix)) {
    _upgrade(fix[row]);
  }
};

const STYLE_LIST = [
  "style",
  "boxStyle",
  "imageStyle",
  "iconStyle",
  "textStyle",
  "titleStyle",
  "priceStyle",
  "btnStyle",
  "flagStyle",
  "videoStyle",
  "numStyle",
  "num2Style",
  "valueStyle",
  "descStyle"
];

function _upgrade(row) {
  const cls = row.cls;
  const version = row.v || 0;

  let tool = DEFAULT_TOOL.filter(x => x.cls === cls);
  if (tool.length <= 0) {
    tool = AD_TOOL.filter(x => x.cls === cls);
    if (tool.length <= 0) {
      tool = DEFAULT_CAT_NAV.filter(x => x.cls === cls);
    }
  }
  // console.log(tool);

  if (tool.length > 0) {
    const { v, res } = tool[0];
    if (v > version) {
      if (res) {
        row.res = { ...res, ...row.res };

        for (const style of STYLE_LIST) {
          if (res[style]) {
            row.res[style] = { ...res[style], ...row.res[style] };
          }
        }
      }

      row.v = v;
    }
  }
}

/**
 * 默认分类导航
 */
export const DEFAULT_CAT_NAV = {
  list: [
    {
      name: "分类导航",
      cls: "cat",
      c: 2,
      v: 2,
      res: {
        type: 1,
        showBuyButton: true,
        priceAndBtnAlign: "row between",

        backgroundImage: "",

        style: {
          backgroundColor: "#fff",
          backgroundImage: "",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",

          paddingLeft: "9px",
          paddingRight: "9px",
          paddingTop: "12px",
          paddingBottom: "12px"
        },
        boxStyle: {
          backgroundColor: "#fff",
          borderRadius: "6px",
          borderWidth: "1px",
          borderColor: "#f5f5f5",
          boxShadow: "",
          boxShadowValue: "0px",
          marginBottom: "5px"
        },
        imageStyle: { rate: 1 },
        titleStyle: {
          fontSize: "14px",
          fontWeight: "normal",
          "-webkitLineClamp": "1",
          color: "rgba(0, 0, 0, 0.9)"
        },
        priceStyle: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#ec4b30"
        },
        btnStyle: { index: 1, size: 1, backgroundColor: "#ec4b30" }
      },
      key: "da08b3fac1324"
    }
  ],
  fix: {}
};
