<template>
  <div class="notice-scroll">
    <img v-if="showIcon" class="icn" :src="icon" />
    <div class="item">
      <div class="content">
        {{ res.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    icon() {
      return this.res.icon
        ? this.res.icon
        : require("@/assets/icn-notice-3.png");
    },

    showIcon() {
      return this.res.iconType !== 9;
    }
  }
};
</script>

<style lang="scss">
@keyframes scrollAnimate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.notice-scroll {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icn {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .item {
    flex: 1;
    overflow: hidden;
    position: relative;
    .content {
      line-height: 2;
      white-space: nowrap;

      transform: translateX(100%);
      animation: scrollAnimate 15s linear infinite;
    }
  }
}
</style>
