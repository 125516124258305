<template>
  <div class="counter" :style="res.style">
    <div class="counter-box" :class="res.align" :style="res.boxStyle">
      <span class="time-str" :style="res.titleStyle">{{ res.title }}</span>
      <div v-if="res.type == 2" class="time-txt" :style="res.num2Style">
        {{ cnt.txt }}
      </div>
      <div v-else class="time">
        <template v-if="cnt.day > 0">
          <span class="n" :style="res.numStyle">{{ cnt.day }}</span> 天
        </template>
        <span class="n" :style="res.numStyle">{{ cnt.hour }}</span>
        :
        <span class="n" :style="res.numStyle">{{ cnt.min }}</span>
        :
        <span class="n" :style="res.numStyle">{{ cnt.sec }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const timeFormat = param => {
  return param < 10 ? "0" + param : param;
};

export default {
  data() {
    return {
      interval: null,

      cnt: {
        day: 0,
        hour: "00",
        min: "00",
        sec: "00",
        txt: ""
      }
    };
  },

  props: {
    res: Object
  },

  watch: {
    "res.endTime": function(endTime) {
      this.countDown(endTime);
    }
  },

  mounted() {
    if (!this.res) return;

    this.countDown(this.res.endTime);
  },

  methods: {
    countDown(_endTime) {
      if (!_endTime) {
        this.cnt = {
          day: 0,
          hour: "00",
          min: "00",
          sec: "00",
          txt: "00时00分00秒"
        };
        clearInterval(this.interval);

        return;
      }

      let endTime = new Date(_endTime).getTime();

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        let now = new Date().getTime();

        if (endTime - now > 0) {
          let time = (endTime - now) / 1000; // 获取天、时、分、秒
          let day = parseInt(time / (60 * 60 * 24));
          let hour = parseInt((time % (60 * 60 * 24)) / 3600);
          let min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
          let sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);

          const h = timeFormat(hour),
            m = timeFormat(min),
            s = timeFormat(sec);
          const txt = (day > 0 ? `${day}天` : "") + `${h}时${m}分${s}秒`;

          this.cnt = {
            day,
            hour: h,
            min: m,
            sec: s,
            txt
          };
        } else {
          // 活动已结束，全部设置为'00'
          this.cnt = {
            day: 0,
            hour: "00",
            min: "00",
            sec: "00",
            txt: "00时00分00秒"
          };
          clearInterval(this.interval);
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.counter {
  .counter-box {
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-style: solid;

    &.left {
      justify-content: flex-start;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    &.column {
      flex-direction: column;
    }
  }

  .time-str {
    margin-right: 5px;
    white-space: pre-wrap;
  }

  .counter-box.column .time-str {
    text-align: center;
  }

  .time {
    display: flex;
    align-items: center;
    color: #202020;

    .n {
      line-height: 1;
      background-color: rgb(32, 32, 32);
      color: #fff;
      margin: 0 2px;
      padding: 4px 6px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 700;
      min-height: 22px;
      min-width: 22px;
    }
  }
}
</style>
