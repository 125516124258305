<template>
  <div class="design-home">
    <!-- 组件和模板 -->
    <div class="card-container">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="组件">
          <div class="tool-block">
            <Toolbox />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="模板">
          <Mob />
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- <div class="tool-block">
      <Toolbox />
    </div> -->

    <div class="main-block">
      <Preview />
    </div>
    <div ref="configBlock" class="config-block">
      <Config />
    </div>
  </div>
</template>

<script>
import Config from "./config/index.vue";
import Preview from "./preview.vue";
import Toolbox from "./toolbox.vue";
import Mob from "./mob.vue";

import { mapState } from "vuex";

export default {
  data() {
    return {};
  },

  $refs: {
    configBlock: HTMLFormElement
  },
  computed: {
    ...mapState({
      current: state => state.design.current
    })
  },
  watch: {
    current() {
      this.scrollToTop();
    }
  },

  methods: {
    scrollToTop() {
      this.$refs.configBlock.scrollTo({
        top: 0,
        left: 0
        // behavior: "smooth"
      });
    }
  },

  async mounted() {},

  components: {
    Toolbox,
    Mob,

    Preview,
    Config
  }
};
</script>

<style lang="scss">
.design-home {
  display: flex;
  height: calc(100vh - var(--header-height));
  background: #ebedf0;

  .main-block {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    padding: 30px 0;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  // 组件和模板
  .card-container {
    background: #f5f5f5;
    display: flex;
    user-select: none;

    .ant-tabs-card {
      display: flex;
      flex-direction: column;

      .ant-tabs-content {
        margin-top: -16px;
        width: 166px;

        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        .ant-tabs-tabpane {
          background: #fff;
          padding: 0 10px;
        }
      }

      .ant-tabs-nav {
        width: 100%;

        & > div {
          width: 100%;
          display: flex;
        }
      }

      .ant-tabs-bar {
        border-color: #fff;

        .ant-tabs-tab {
          border-color: transparent;
          background: transparent;
          flex: 1 1;
          display: flex;
          justify-content: center;
          margin: 0 !important;
          border-radius: 0;
        }

        .ant-tabs-tab-active {
          border-color: #fff;
          background: #fff;
        }
      }
    }
  }

  .tool-block {
    width: 146px;
  }

  // 需注销下面样式
  // .tool-block {
  //   width: 146px;

  //   flex: 0 0 auto;
  //   overflow-y: scroll;
  //   overflow-x: hidden;
  //   position: relative;

  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }

  .config-block {
    flex: 0 0 auto;
    width: 400px;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 30px;
    user-select: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
