<template>
  <div class="movie-wrap" :style="res.style">
    <video
      :src="res.src"
      :object-fit="res.videoStyle.objectFit || 'contain'"
      :style="res.videoStyle"
    ></video>
    <div class="icon-wrap" :style="res.videoStyle">
      <a-icon class="icon" type="play-circle" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  }
};
</script>

<style lang="scss">
.movie-wrap {
  position: relative;

  video {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #000;
    border-style: solid;
    overflow: hidden;
    object-fit: contain;
  }

  .icon-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 50px;
      color: #ccc;
    }
  }
}
</style>
