<template>
  <div class="share-coupon" :style="res.style">
    <div class="wrap" :style="res.boxStyle">
      <div v-if="!res.hideLeft" class="price" :style="res.priceStyle">
        <span class="value">{{ res.price }}</span>
        <span class="text">{{ res.unit }}</span>
      </div>
      <section>
        <div class="title" :style="res.titleStyle">{{ res.title }}</div>
        <div class="desc" :style="res.descStyle">{{ res.subTitle }}</div>
      </section>
      <div class="btn" :style="res.btnStyle">
        <span>{{ res.btnTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {},

  methods: {}
};
</script>

<style lang="scss">
.share-coupon {
  background: #fff;
  padding: 10px;

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 18px 10px;
    border-radius: 6px;
    border: 1px solid #f5f5f5;

    .price {
      margin-right: 15px;
      flex: 0 0 auto;
      display: flex;
      align-items: baseline;
      flex-direction: row;
      font-size: 14px;

      .value {
        font-size: 36px;
        padding: 0 2px;
      }
    }

    section {
      flex: 1 1 auto;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .desc {
      font-size: 14px;
    }

    .btn {
      flex: 0 0 auto;
      width: 85px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      margin-left: 15px;
      background: #30992e;
    }
  }
}
</style>
