<template>
  <div class="notice-1">
    <div class="item">
      <div class="content">
        <img v-if="showIcon" class="icn" :src="icon" />
        <div class="desc">{{ res.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    icon() {
      return this.res.icon
        ? this.res.icon
        : require("@/assets/icn-notice-1@3x.png");
    },

    showIcon() {
      return this.res.iconType !== 9;
    }
  }
};
</script>

<style lang="scss">
.notice-1 {
  padding: 0;

  .item {
    .content {
      display: flex;
      align-items: center;

      .icn {
        width: 40px;
        height: 40px;
        margin: 12px;
        background: transparent;
        flex: 0 0 auto;
      }

      .desc {
        white-space: pre-wrap;
        // line-height: 1.43;
      }
    }
  }
}
</style>
