var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"module-tool"},[_c('header',[_vm._v("基础组件")]),_c('div',{staticClass:"list"},[_c('draggable',{attrs:{"move":_vm.checkMove,"clone":_vm.clone,"sort":false,"chosen-class":"drag-placeholder","group":{
          name: 'toolbox',
          pull: 'clone',
          put: false
        }},on:{"start":_vm.startDrag,"end":_vm.endDrag},model:{value:(_vm.DEFAULT_TOOL),callback:function ($$v) {_vm.DEFAULT_TOOL=$$v},expression:"DEFAULT_TOOL"}},_vm._l((_vm.DEFAULT_TOOL),function(tool){return _c('div',{key:tool.name,staticClass:"item normal",on:{"click":function($event){return _vm.push(tool)}}},[_c('img',{attrs:{"src":_vm.getImageUrl(tool.cls)}}),_c('div',[_vm._v(_vm._s(tool.name))])])}),0)],1)]),_c('div',{staticClass:"module-tool"},[_c('header',[_vm._v("营销组件")]),_c('div',{staticClass:"list"},[_c('draggable',{attrs:{"move":_vm.checkMove,"clone":_vm.clone,"sort":false,"chosen-class":"drag-placeholder","group":{
          name: 'toolbox',
          pull: 'clone',
          put: false
        }},on:{"start":_vm.startDrag,"end":_vm.endDrag},model:{value:(_vm.AD_TOOL),callback:function ($$v) {_vm.AD_TOOL=$$v},expression:"AD_TOOL"}},_vm._l((_vm.AD_TOOL),function(tool){return _c('div',{key:tool.name,staticClass:"item adv",on:{"click":function($event){return _vm.push(tool)}}},[_c('img',{attrs:{"src":_vm.getImageUrl(tool.cls)}}),_c('div',[_vm._v(_vm._s(tool.name))])])}),0)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }