<template>
  <a-form-model>
    <section>
      <a-form-model-item label="样式" v-bind="horizonLayout">
        <a-radio-group v-model="res.type" button-style="solid">
          <a-radio-button :value="2">宫格</a-radio-button>
          <a-radio-button :value="1">列表</a-radio-button>
          <a-radio-button :value="3">三列</a-radio-button>
          <a-radio-button :value="4">滚动</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="模块标题" v-bind="horizonLayout">
        <a-input v-model="res.title" placeholder="请输入模块标题" type="text" />
      </a-form-model-item>

      <a-form-model-item label="副标题" v-bind="horizonLayout">
        <a-input
          v-model="res.subTitle"
          :maxLength="30"
          placeholder="请输入模块副标题"
          type="text"
        />
      </a-form-model-item>

      <header class="group">数据源</header>
      <a-form-model-item label="数据源" v-bind="horizonLayout">
        <a-select v-model="res.ds" placeholder="请选择">
          <a-select-option :value="0">挑选商品</a-select-option>
          <a-select-option :value="1">指定分类</a-select-option>
          <a-select-option :value="2">全部商品</a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- 分类 -->
      <a-form-model-item
        v-if="res.ds === 1"
        label="分类"
        v-bind="horizonLayout"
      >
        <a-tree-select
          v-model="res.categoryId"
          style="width: 100%"
          :dropdown-style="{
            maxHeight: '400px',
            overflow: 'auto'
          }"
          :tree-data="categoryTree"
          placeholder="请选择"
          notFoundContent="请在小商店后台创建店铺分类"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>

      <a-form-model-item
        v-if="res.ds > 0"
        label="商品排序"
        v-bind="horizonLayout"
      >
        <a-select v-model="res.sort" placeholder="请选择">
          <a-select-option :value="0">默认排序</a-select-option>
          <a-select-option :value="1">最新上架</a-select-option>
          <a-select-option :value="2">价格从低到高</a-select-option>
          <a-select-option :value="3">价格从高到低</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item
        v-if="res.type != 4"
        label="显示数量"
        v-bind="horizonLayout"
      >
        <a-select v-model="res.lines" placeholder="请选择">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">一行</a-select-option>
          <a-select-option :value="2">二行</a-select-option>
          <a-select-option :value="3">三行</a-select-option>
          <a-select-option :value="4">四行</a-select-option>
          <a-select-option :value="5">五行</a-select-option>
          <a-select-option :value="6">六行</a-select-option>
          <a-select-option :value="7">七行</a-select-option>
          <a-select-option :value="8">八行</a-select-option>
        </a-select>
      </a-form-model-item>
    </section>

    <section v-if="(res.ds || 0) === 0">
      <header>
        <div class="label light">添加商品（拖动可排序）</div>
      </header>

      <draggable
        v-model="res.rows"
        @start="drag = true"
        @end="drag = false"
        v-bind="dragOptions"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div
            v-for="(row, index) in res.rows"
            :key="row.id"
            class="config-product"
          >
            <div class="main">
              <img class="cover" :src="row.img" />
              <div class="name">{{ row.title }}</div>
            </div>
            <div v-if="res.type == 2" class="sub">
              <div
                @click="toggleBigImg(index)"
                class="setbig"
                :class="!!row.big ? 'active' : null"
              ></div>
            </div>
            <img
              @click="delItem(index)"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
        </transition-group>
      </draggable>

      <a-form-model-item>
        <a-button style="width: 100%" @click="openProductDialog">
          <a-icon type="plus" /> 添加商品
        </a-button>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">商品卡片</header>

      <a-form-model-item
        v-if="res.type != 1"
        label="图片比例"
        v-bind="horizonLayout"
      >
        <a-radio-group v-model="res.imageStyle.rate" button-style="solid">
          <a-radio-button :value="1">1:1</a-radio-button>
          <a-radio-button :value="0.666">3:2</a-radio-button>
          <a-radio-button :value="1.333">3:4</a-radio-button>
          <a-radio-button :value="0.563">16:9</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="价格对齐" v-bind="horizonLayout">
        <a-radio-group v-model="res.priceAndBtnAlign" button-style="solid">
          <a-radio-button value="row between">
            <a-icon type="column-width"
          /></a-radio-button>
          <a-radio-button value="row left">
            <a-icon type="align-left" />
          </a-radio-button>
          <a-radio-button value="row center">
            <a-icon type="align-center" />
          </a-radio-button>
          <a-radio-button value="row right">
            <a-icon type="align-right" />
          </a-radio-button>
          <a-radio-button value="column">
            <a-icon type="column-height" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.marginBottom" :min="0" :max="15" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>
      <a-form-model-item label="卡片颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">
        <div class="label">
          标题
        </div>
        <div class="pending"></div>
        <div class="opt">
          <a-switch v-model="res.showTitle" />
        </div>
      </header>

      <template v-if="res.showTitle">
        <a-form-model-item label="字号" v-bind="horizonLayout">
          <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
            <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="粗细" v-bind="horizonLayout">
          <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
            <a-select-option
              v-for="f in fontWeightList"
              :key="f.id"
              :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="行数" v-bind="horizonLayout">
          <a-select
            v-model="res.titleStyle['-webkitLineClamp']"
            placeholder="请选择"
          >
            <a-select-option value="1">1行</a-select-option>
            <a-select-option value="2">2行</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.titleStyle.color" />
        </a-form-model-item>
      </template>

      <header class="group">
        <div class="label">
          价格
        </div>
        <div class="pending"></div>
        <div class="opt">
          <a-switch v-model="res.showPrice" />
        </div>
      </header>

      <template v-if="res.showPrice">
        <a-form-model-item label="字号" v-bind="horizonLayout">
          <a-select v-model="res.priceStyle.fontSize" placeholder="请选择">
            <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="粗细" v-bind="horizonLayout">
          <a-select v-model="res.priceStyle.fontWeight" placeholder="请选择">
            <a-select-option
              v-for="f in fontWeightList"
              :key="f.id"
              :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.priceStyle.color" />
        </a-form-model-item>
      </template>

      <header class="group">
        <div class="label">
          购买按钮
        </div>
        <div class="pending"></div>
        <div class="opt">
          <a-switch v-model="res.showBuyButton" />
        </div>
      </header>

      <template v-if="res.showBuyButton">
        <a-form-model-item label="大小" v-bind="horizonLayout">
          <a-radio-group
            v-model="res.btnStyle.size"
            @change="changeBuyButtonSize"
          >
            <a-radio :value="1">小</a-radio>
            <a-radio :value="0">中</a-radio>
            <a-radio :value="2">大</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item class="btnStyle">
          <a-radio-group
            v-model="res.btnStyle.index"
            @change="changeBuyButtonIndex"
          >
            <a-radio :value="0"
              ><span class="plainBuyBtn" :style="buyIconStyle"
                >立即购买</span
              ></a-radio
            >
            <a-radio v-for="i in 22" :key="i" :value="i">
              <div class="ic-buy" :class="'ic-buy-' + i" :style="buyIconStyle"
            /></a-radio>
          </a-radio-group>
        </a-form-model-item>

        <template v-if="res.btnStyle.index === 0">
          <a-form-model-item label="背景颜色" v-bind="horizonLayout">
            <color-picker v-model="res.btnStyle.backgroundColor" />
          </a-form-model-item>
          <a-form-model-item label="文字颜色" v-bind="horizonLayout">
            <color-picker v-model="res.btnStyle.color" />
          </a-form-model-item>
          <a-form-model-item label="边框颜色" v-bind="horizonLayout">
            <color-picker v-model="res.btnStyle.borderColor" />
          </a-form-model-item>
          <a-form-model-item label="圆角" v-bind="horizonLayout">
            <slider-input
              v-model="res.btnStyle.borderRadius"
              :min="0"
              :max="25"
            />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label="颜色" v-bind="horizonLayout">
            <color-picker v-model="res.btnStyle.backgroundColor" />
          </a-form-model-item>
        </template>
      </template>

      <header class="group">
        <div class="label">角标</div>
        <div class="pending"></div>
        <div class="opt">
          <a-switch v-model="res.showFlag" />
        </div>
      </header>
      <template v-if="res.showFlag">
        <a-form-model-item label="样式" v-bind="horizonLayout">
          <a-select v-model="res.flagStyle.type" placeholder="请选择">
            <a-select-option :value="1">样式一</a-select-option>
            <a-select-option :value="2">样式二</a-select-option>
            <a-select-option :value="3">样式三</a-select-option>
            <a-select-option :value="4">样式四</a-select-option>
            <a-select-option :value="5">样式五</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="文字" v-bind="horizonLayout">
          <a-radio-group
            v-model="res.flagStyle.index"
            @change="changeFlagIndex"
          >
            <a-radio :value="0">HOT</a-radio>
            <a-radio :value="1">NEW</a-radio>
            <a-radio :value="2">热卖</a-radio>
            <a-radio :value="3">新品</a-radio>
            <a-radio :value="-1">自定义</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="res.flagStyle.index === -1"
          label="自定义"
          v-bind="horizonLayout"
        >
          <a-input
            v-model="res.flagStyle.text"
            placeholder="请输入宣传文本"
            type="text"
            :max-length="res.flagStyle.type === 1 ? 3 : 4"
          />
        </a-form-model-item>
        <a-form-model-item label="字体色" v-bind="horizonLayout">
          <color-picker v-model="res.flagStyle.color" />
        </a-form-model-item>
        <a-form-model-item label="背景色" v-bind="horizonLayout">
          <color-picker v-model="res.flagStyle.backgroundColor" />
        </a-form-model-item>
      </template>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input v-model="res.style.paddingTop" :min="0" :max="100" />
      </a-form-model-item>
    </section>

    <!-- 商品选择器 -->
    <ProductDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="true"
    />
  </a-form-model>
</template>

<script>
import draggable from "vuedraggable";

import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import categoryMixin from "@/mixins/category";
import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

import { randomKey } from "@/utils/tool";

const BUTTON_SIZE = [
  // 中
  {
    fontSize: "13px",
    width: "72px",
    height: "30px",
    fontWeight: "bold"
  },
  // 小
  {
    fontSize: "10px",
    width: "64px",
    height: "24px",
    fontWeight: "normal"
  },
  // 大
  {
    fontSize: "14px",
    width: "92px",
    height: "38px",
    fontWeight: "bold"
  }
];

const BUTTON_ICON_SIZE = [
  // 中
  {
    width: "20px",
    height: "20px"
  },
  // 小
  {
    width: "16px",
    height: "16px"
  },
  // 大
  {
    width: "24px",
    height: "24px"
  }
];

export default {
  data() {
    return {
      showOptions: [
        { id: 1, name: "显示" },
        { id: 2, name: "不显示" }
      ],

      drag: false
    };
  },
  props: {
    res: Object
  },
  computed: {
    dragOptions() {
      return {
        animation: 200
      };
    },

    buyIconStyle() {
      return {
        backgroundColor: this.res.btnStyle.backgroundColor
      };
    }
  },

  mixins: [textMixin, categoryMixin, uploadMixin],

  methods: {
    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.res.rows.findIndex(x => x.id === val.id) > -1) {
          continue;
        }

        this.res.rows.push({ ...val, key: randomKey() });
      }
    },
    delItem(index) {
      this.res.rows.splice(index, 1);
    },
    toggleBigImg(index) {
      const item = this.res.rows[index];
      const big = !item.big;
      this.$set(this.res.rows, index, { ...item, big });
    },

    changeBuyButtonIndex(evt) {
      const index = evt.target.value;
      this.resetBtnStyle(index, this.res.btnStyle.size);
    },
    changeBuyButtonSize(evt) {
      const size = evt.target.value;
      this.resetBtnStyle(this.res.btnStyle.index, size);
    },

    resetBtnStyle(index, size) {
      const val = index === 0 ? BUTTON_SIZE[size] : BUTTON_ICON_SIZE[size];
      this.res.btnStyle = { ...this.res.btnStyle, ...val };
    },

    changeFlagIndex(evt) {
      const index = evt.target.value;
      const TextMap = ["HOT", "NEW", "热卖", "新品"];
      const text = index > -1 ? TextMap[index] : "HOT";
      this.res.flagStyle = { ...this.res.flagStyle, text };
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable,

    SliderInput,
    ColorPicker
  }
};
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.btnStyle {
  .ant-radio-wrapper {
    width: calc(25% - 8px);
    margin-top: 8px;

    .plainBuyBtn {
      font-size: 10px;
      background: #ec4b30;
      color: #fff;
      border-radius: 4px;
      padding: 4px;
    }

    .ic-buy {
      background: #ec4b30;
    }
  }
}
</style>
