<template>
  <div class="cat">
    <div class="side">
      <div
        v-for="(item, index) in categoryLevel1"
        :key="item.id"
        class="item"
        :class="level1.cls + (index === 0 ? ' current' : '')"
      >
        <span :style="index === 0 ? level1.style : ''">{{ item.name }}</span>
      </div>
    </div>

    <div class="main">
      <div v-if="categoryLevel2.length > 0" class="level">
        <div
          v-for="(item, index) in categoryLevel2"
          :key="item.id"
          class="item"
          :class="index === 0 ? 'current' : null"
          :style="index === 0 ? `color:${res.iconStyle.color}` : null"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="product-list" :style="style">
        <div v-if="productForLevel2.length > 0" class="list">
          <template v-for="item in productForLevel2">
            {{ item.name }}
            <div class="item" :key="item.key" :style="res.boxStyle">
              <div
                class="cover"
                :style="`background-image:url(${item.img});`"
              />
              <div class="content">
                <div class="name" :style="res.titleStyle">
                  {{ item.title }}
                </div>
                <div class="pending"></div>
                <div class="p">
                  <div class="price" :style="res.priceStyle">
                    ￥{{ item.price }}
                  </div>
                  <div class="pending"></div>

                  <div
                    v-if="res.showBuyButton"
                    class="ic-buy"
                    :class="btn.iconClass"
                    :style="btn.iconStyle"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="no-product">
          无商品
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      // cid1: 0,
      cid2: 0
    };
  },

  props: {
    res: Object
  },

  computed: {
    ...mapState({
      categoryList: state => state.categoryList,
      productList: state => state.productList,
      productList2: state => state.productList2
    }),

    realProductList() {
      return this.productList.concat(this.productList2);
    },

    level1() {
      const { type, color } = this.res.iconStyle || { type: 1 };

      let style = {};
      if (color) {
        switch (type) {
          // 左边距
          case 2:
            style = {
              borderColor: color,
              color
            };
            break;
          // 圆角底色
          case 3:
            style = {
              backgroundColor: color
            };
            break;
          // 默认
          case 1:
          default:
            style = {
              color
            };
            break;
        }
      }

      return {
        cls: `type-${type}`,
        style
      };
    },

    cid1() {
      if (this.categoryLevel1.length > 0) {
        return this.categoryLevel1[0].id;
      } else {
        return 0;
      }
    },

    categoryLevel1() {
      return this.categoryList.filter(x => x.level === 1);
    },
    categoryLevel2() {
      return [
        { id: 0, name: "全部" },
        ...this.categoryList.filter(x => x.fid === this.cid1)
      ];
    },
    productForLevel2() {
      switch (this.res.sort) {
        // 最新上架
        case 1:
          return this.realProductList
            .slice()
            .sort((a, b) => (a.id > b.id ? -1 : 1))
            .slice(0, 10);
        // 价格从低到高
        case 2:
          return this.realProductList
            .slice()
            .sort((a, b) => (a.price > b.price ? 1 : -1))
            .slice(0, 10);
        // 价格从高到低
        case 3:
          return this.realProductList
            .slice()
            .sort((a, b) => (a.price > b.price ? -1 : 1))
            .slice(0, 10);
        default:
          return this.realProductList.slice(0, 10);
      }
    },

    btn() {
      const index = this.res.btnStyle.index;
      const iconClass = `ic-buy-${index}`;

      const { width, height, backgroundColor } = this.res.btnStyle;
      const iconStyle = {
        width,
        height,
        backgroundColor
      };
      return {
        iconClass,
        iconStyle
      };
    },
    style() {
      let {
        style: { paddingTop },
        boxStyle: { marginBottom: rowMargin }
      } = this.res;
      paddingTop = parseInt(paddingTop);
      rowMargin = parseInt(rowMargin);

      let val = paddingTop - rowMargin;

      let marginBottom = "0px",
        paddingBottom = "0px";
      if (val > 0) {
        paddingBottom = val + "px";
        marginBottom = "0px";
      } else {
        paddingBottom = "0px";
        marginBottom = val + "px"; // Math.min(parseInt(value), 0) - 10 + 'px';
      }

      return { ...this.res.style, marginBottom, paddingBottom };
    }
  },

  methods: {},

  mounted() {
    if (this.categoryLevel1.length > 0) {
      this.cid1 = this.categoryLevel1[0].id;
    }
  }
};
</script>

<style lang="scss">
.cat {
  position: relative;
  display: flex;
  background: #fff;
  height: 500px;
  overflow: hidden;
  border-top: 1px solid #f5f6f7;

  .side-wrap {
    display: flex;

    overflow: hidden;
  }

  .side {
    flex: none;
    background: #f5f6f7;
    width: 100px;

    max-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      line-height: 1.2em;
      padding: 15px;

      &.current {
        font-weight: 700;
        background: #fff;

        &.type-2,
        &.type-3 {
          padding: 15px 8px;
        }

        &.type-2 span {
          border-left: 4px solid #000;
          padding-left: 4px;
        }
        &.type-3 span {
          background: #000;
          color: #fff;
          padding: 4px 6px;
          border-radius: 4px;
        }
      }
    }
  }

  .main {
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .level {
      flex: none;

      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        flex: none;
        padding: 10px 15px;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        line-height: 1em;
        background: #f5f6f7;

        &.current {
          font-weight: 700;
          background: #fbeff6;
          color: #ec4b30;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .product-list {
      flex: auto;
    }
  }

  .product-list {
    padding: 10px;
    background: #fff;

    .list {
      .item {
        display: flex;
        position: relative;
        border-radius: 6px;
        border: 1px solid #f5f5f5;
        background-color: #fff;
        margin-bottom: 10px;
        overflow: hidden;

        .cover {
          width: 80px;
          height: 80px;
          flex: 0 0 auto;
          margin-right: 10px;
          background: transparent;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .content {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          margin-right: 15px;
          padding-bottom: 10px;
          overflow: hidden;
          padding: 15px 0;

          .name {
            font-size: 14px;
            line-height: 1.29;
            color: rgba(0, 0, 0, 0.9);

            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 2.6;
          }

          .pending {
            flex: 1 1 auto;
          }

          .p {
            display: flex;
            align-items: center;

            .price {
              font-size: 16px;
              font-weight: 600;
              color: #ec4b30;
            }

            .buy {
              width: 72px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              font-weight: 600;
              color: #fff;
              border-radius: 6px;
              background-color: #ec4b30;
              border: 1px solid #fff;
            }

            .ic-buy {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .no-product {
      text-align: center;
      padding: 10px;
    }
  }
}
</style>
