<template>
  <div class="search-wrap" :style="res.style">
    <div class="search-box" :style="res.boxStyle">
      <div class="text-wrap" :style="res.textStyle">
        <div class="search-icon" :style="iconStyle" />
        <div class="search-text">{{ res.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  },

  computed: {
    iconStyle() {
      return {
        backgroundColor: this.res.textStyle.color
      };
    }
  }
};
</script>

<style lang="scss">
.search-wrap {
  overflow: hidden;
  z-index: 8;
  top: 0;
  width: 100%;

  .search-box {
    padding: 0 10px;
    font-size: 13px;
    background-color: #fff;

    .text-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      .search-icon {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background-color: #ccc;

        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;

        mask-image: url(data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M16.31%2015.561l4.114%204.115-.848.848-4.123-4.123a7%207%200%2011.857-.84zM16.8%2011a5.8%205.8%200%2010-11.6%200%205.8%205.8%200%200011.6%200z%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
      }

      .search-text {
        margin-left: 5px;
      }
    }
  }
}
</style>
