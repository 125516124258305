<template>
  <a-form-model class="counter-panel">
    <section>
      <a-form-model-item label="标题" v-bind="horizonLayout">
        <a-input
          v-model="res.title"
          :maxLength="20"
          placeholder="请输入标题"
          type="text"
        />
      </a-form-model-item>

      <a-form-model-item label="结束时间" v-bind="horizonLayout">
        <a-date-picker
          class="time-picker"
          valueFormat="YYYY-MM-DD HH:mm"
          v-model="res.endTime"
          :show-time="{ format: 'h:mm' }"
          format="YYYY-MM-DD HH:mm"
          placeholder="活动结束时间"
        />
      </a-form-model-item>
    </section>

    <section>
      <header class="group">版式</header>
      <a-form-model-item label="样式" v-bind="horizonLayout">
        <a-select v-model="res.type" placeholder="请选择">
          <a-select-option :value="1">计分板</a-select-option>
          <a-select-option :value="2">纯文字</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="对齐" v-bind="horizonLayout">
        <a-radio-group v-model="res.align" button-style="solid">
          <a-radio-button value="auto">
            <a-icon type="column-width"
          /></a-radio-button>
          <a-radio-button value="left">
            <a-icon type="align-left" />
          </a-radio-button>
          <a-radio-button value="center">
            <a-icon type="align-center" />
          </a-radio-button>
          <a-radio-button value="right">
            <a-icon type="align-right" />
          </a-radio-button>
          <a-radio-button value="column">
            <a-icon type="column-height" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">活动标题</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="斜体" v-bind="horizonLayout">
        <a-radio-group v-model="res.titleStyle.fontStyle" button-style="solid">
          <a-radio-button value="normal">无</a-radio-button>
          <a-radio-button value="italic">
            <a-icon type="italic" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.titleStyle.color" />
      </a-form-model-item>

      <header class="group">数字</header>
      <template v-if="res.type == 2">
        <a-form-model-item label="字号" v-bind="horizonLayout">
          <a-select v-model="res.num2Style.fontSize" placeholder="请选择">
            <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="粗细" v-bind="horizonLayout">
          <a-select v-model="res.num2Style.fontWeight" placeholder="请选择">
            <a-select-option
              v-for="f in fontWeightList"
              :key="f.id"
              :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.num2Style.color" />
        </a-form-model-item>
      </template>
      <template v-else>
        <a-form-model-item label="字号" v-bind="horizonLayout">
          <a-select v-model="res.numStyle.fontSize" placeholder="请选择">
            <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="粗细" v-bind="horizonLayout">
          <a-select v-model="res.numStyle.fontWeight" placeholder="请选择">
            <a-select-option
              v-for="f in fontWeightList"
              :key="f.id"
              :value="f.id"
              >{{ f.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="颜色" v-bind="horizonLayout">
          <color-picker v-model="res.numStyle.color" />
        </a-form-model-item>
        <a-form-model-item label="底色" v-bind="horizonLayout">
          <color-picker v-model="res.numStyle.backgroundColor" />
        </a-form-model-item>
      </template>

      <header class="group">卡片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.padding" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>
      <a-form-model-item label="卡片颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";
import uploadMixin from "@/mixins/upload";

import textMixin from "@/mixins/text";

export default {
  data() {
    return {};
  },
  props: {
    res: Object
  },
  computed: {},

  mixins: [textMixin, uploadMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    SliderInput,
    ColorPicker
  }
};
</script>

<style lang="scss">
.counter-panel {
  .time-picker {
    width: 100%;
  }

  .config-panel & .config-product {
    margin: 0;
    padding: 0 10px;
    cursor: auto;
  }

  .config-panel & .config-product:hover {
    opacity: 1;
  }
}
</style>
