<template>
  <div class="banner" :style="res.style">
    <a-carousel autoplay :dots="dots" :vertical="res.direction === 1">
      <div
        v-for="item in res.rows"
        :key="item.key"
        class="item"
        :style="res.imageStyle"
      >
        <div class="swipe-item">
          <img v-if="item.img" ref="banner" :src="item.img" @load="imgLoad" />
          <div v-else class="empty">
            <img class="icon" src="@/assets/icn-banner-default@3x.png" />
          </div>
        </div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return { height: 200 };
  },
  props: {
    res: Object
  },
  computed: {
    dots() {
      if (typeof this.res.dots === "undefined") return true;

      return this.res.dots;
    }
  },
  methods: {
    imgLoad() {
      this.height = this.$refs.banner[0].height;
    }
  }
};
</script>

<style lang="scss">
.banner {
  .item {
    overflow: hidden;
    border-style: solid;
    border-width: 0px;

    .empty {
      min-height: 180px;
      height: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 46px;
        height: 60px;
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
