import { mapMutations } from "vuex";

const DecoSvc = require("@/service/decoration");
const PageSvc = require("@/service/page");
const PinSvc = require("@/service/pin");
const KanSvc = require("@/service/kan");
const ShareSvc = require("@/service/share");
const PrizeSvc = require("@/service/prize");

import loadCouponMixin from "./loadCoupon";

export default {
  mixins: [loadCouponMixin],

  methods: {
    ...mapMutations([
      "setCategoryList",
      "setPageList",
      "setProductList",
      "setThirdProductList",
      "setPinList",
      "setKanList",
      "setShareList",
      "setPrizeList"
    ])
  },

  async mounted() {
    // 商品
    try {
      const { list } = await DecoSvc.loadProductList();
      this.setProductList(list);

      // 带货商品
      const res = await DecoSvc.loadThirdProductList();
      this.setThirdProductList(res.list);
    } catch (err) {
      console.error(err);
      this.$message.error("加载商品出错，错误：" + err.message);
    }

    // 店铺分类
    try {
      const { list } = await DecoSvc.loadShopCategoryList();
      this.setCategoryList(list);
    } catch (err) {
      console.error(err);
      this.$message.error("加载店铺分类出错，错误：" + err.message);
    }

    // 页面
    try {
      const list = await PageSvc.list();
      this.setPageList(list);
    } catch (err) {
      console.error(err);
      this.$message.error("加载页面出错，错误：" + err.message);
    }

    // 拼团
    try {
      const { res } = await PinSvc.list({
        page: 1,
        pageSize: 1000
      });
      this.setPinList(res);
    } catch (err) {
      console.error(err);
      this.$message.error("加载拼团活动出错，错误：" + err.message);
    }

    // 砍价
    try {
      const { res } = await KanSvc.list({
        page: 1,
        pageSize: 1000
      });
      this.setKanList(res);
    } catch (err) {
      console.error(err);
      this.$message.error("加载砍价活动出错，错误：" + err.message);
    }

    // 分享有礼
    try {
      const { res } = await ShareSvc.list({
        page: 1,
        pageSize: 1000
      });
      this.setShareList(res);
    } catch (err) {
      console.error(err);
      this.$message.error("加载分享有礼出错，错误：" + err.message);
    }

    // 抽奖
    try {
      const { res } = await PrizeSvc.list({
        page: 1,
        pageSize: 1000
      });
      this.setPrizeList(res);
    } catch (err) {
      console.error(err);
      this.$message.error("加载抽奖出错，错误：" + err.message);
    }
  }
};
