<template>
  <div
    class="group-grid"
    :class="res.title ? 'has-header' : ''"
    :style="myStyle"
  >
    <div v-if="res.title" class="header">
      <span class="label">{{ res.title }}</span>
      <span class="desc">{{ res.subTitle }}</span>
    </div>
    <div class="list" :class="res.title ? 'has-header' : ''">
      <div
        v-for="(item, index) in rows"
        :key="item.id"
        class="item"
        :style="boxStyle(index)"
        :class="item.big ? 'big' : null"
      >
        <div class="cover" :style="imageStyle(index)" />
        <div v-if="res.showTitle" class="name" :style="res.titleStyle">
          {{ item.title }}
        </div>
        <div
          v-if="res.showPrice || res.showBuyButton"
          class="price-n-btn"
          :class="res.priceAndBtnAlign"
        >
          <div v-if="res.showPrice" class="price" :style="res.priceStyle">
            ￥{{ item.price }}
          </div>
          <template v-if="res.showBuyButton">
            <div v-if="btn.plain" class="buy" :style="res.btnStyle">
              立即购买
            </div>
            <div v-else class="buy-icon-wrap ">
              <div
                class="ic-buy"
                :class="btn.iconClass"
                :style="btn.iconStyle"
              />
            </div>
          </template>
        </div>
        <div
          v-if="res.showFlag"
          class="flag"
          :class="`type${res.flagStyle.type}`"
          :style="flag.style"
        >
          {{ res.flagStyle.text }}
          <div class="flag-arrow" :style="flag.arrow" />
        </div>
      </div>
      <div v-if="rows.length <= 0" class="empty">
        <img src="@/assets/icn-grid-default-2@3x.png" />
      </div>
    </div>
    <div v-if="showMore" class="more">
      <div class="more-btn">显示更多</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    res: Object,
    flag: Object,

    rows: Array,
    showMore: Boolean,
    btn: Object,
    myStyle: Object
  },

  computed: {},

  methods: {
    boxStyle(index) {
      const boxStyle = this.res.boxStyle;
      const row = this.rows[index];

      let width = "100%";

      if (!row.big) {
        const value = boxStyle.marginBottom;
        width = `calc(50% - ${parseInt(value) / 2}px)`;
      }

      return { ...boxStyle, width };
    },
    imageStyle(index) {
      let width = "100%";
      const row = this.rows[index];

      const {
        style: { paddingLeft },
        boxStyle: { marginBottom },
        imageStyle: { rate }
      } = this.res;

      if (row.big) {
        width = 375 - parseInt(paddingLeft) * 2;
      } else {
        const gap = parseInt(marginBottom) / 2;
        width = (375 - parseInt(paddingLeft) * 2) / 2 - gap;
      }

      // 高度按比例
      const height = width * rate;

      return {
        width: width + "px",
        height: height + "px",
        backgroundImage: `url(${row.img})`
      };
    }
  }
};
</script>

<style lang="scss">
.group-grid {
  padding: 10px;
  background: #fff;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.has-header {
      margin-top: 15px;
    }

    .item {
      position: relative;
      width: calc(50% - 5px);
      margin-right: 0px;
      margin-bottom: 10px;
      border-radius: 6px;
      border: 1px solid #f5f5f5;
      background-color: #fff;
      padding-bottom: 15px;
      overflow: hidden;

      .cover {
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .name {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 1.29;

        text-align: center;
        padding: 0 7px;
        margin-top: 10px;

        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 2.6;
      }

      .price {
        font-size: 15px;
        font-weight: 600;
        color: #ec4b30;
        line-height: 1.07;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        padding: 0 7px;
        margin-top: 10px;
      }

      .buy {
        width: 72px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: #ec4b30;
        color: #fff;
        line-height: 1;
        font-size: 13px;
        font-weight: 600;
        margin: 10px auto 0;
        border: 1px solid #fff;
      }

      .buy-icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto 0;
      }

      .price-n-btn.row {
        .price {
          margin: 0;
          padding-left: 0;
        }
        .buy-icon-wrap,
        .buy {
          margin: 0;
        }
      }
    }

    .item.big {
      width: 100%;
      margin-right: 0;

      .cover {
        width: 100%;
      }
    }

    .item:nth-child(even),
    .item:last-child {
      margin-right: 0;
    }

    .empty img {
      width: 100%;
    }
  }
}
</style>
