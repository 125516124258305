<template>
  <a-form-model class="pin-panel">
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
    >
      <p slot="description">
        首次使用请前往「营销互动」 -> 「拼团活动」，创建活动。<router-link
          :to="{ name: 'Pin' }"
          >点击前往拼团活动 >>
        </router-link>
      </p>
    </a-alert>
    <div></div>

    <section>
      <a-form-model-item label="样式" v-bind="horizonLayout">
        <a-radio-group v-model="res.type" button-style="solid">
          <a-radio-button :value="1">列表</a-radio-button>
          <a-radio-button :value="2">宫格</a-radio-button>
          <a-radio-button :value="3">三列</a-radio-button>
          <a-radio-button :value="4">滚动</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="标题" v-bind="horizonLayout">
        <a-input
          v-model="res.title"
          :maxLength="10"
          placeholder="请输入标题"
          type="text"
        />
      </a-form-model-item>
    </section>

    <header class="group">数据源</header>
    <a-form-model-item label="数据源" v-bind="horizonLayout">
      <a-select v-model="res.ds" placeholder="请选择">
        <a-select-option :value="0">挑选活动</a-select-option>
        <a-select-option :value="1">进行中的拼团活动</a-select-option>
      </a-select>
    </a-form-model-item>

    <section v-if="res.ds == 0">
      <header>
        <div class="label light">添加拼团活动（拖动可排序）</div>
      </header>

      <draggable
        v-model="res.rows"
        handle="header"
        @start="drag = true"
        @end="drag = false"
        v-bind="{
          animation: 200
        }"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <section
            v-for="(row, index) in res.rows"
            :key="row.id"
            class="compact"
          >
            <header>
              <div class="label">
                <a-icon type="menu" class="drag-handler" /> 商品
              </div>
              <div class="pending"></div>
              <div @click="delItem(index)" class="opt">
                <a-icon type="close" />
              </div>
            </header>
            <div class="config-product">
              <div class="main" :title="row.title">
                <img class="cover" :src="row.img" />
                <div class="attr">
                  <div class="name">{{ row.title }}</div>
                  <div class="price-row">
                    <div class="price">￥{{ row.price }}</div>

                    <span>
                      <span>拼团价：</span>
                      ￥{{ row.pinPrice }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </transition-group>
      </draggable>

      <a-form-model-item>
        <a-button style="width: 100%" @click="openProductDialog">
          <a-icon type="plus" /> 添加拼团活动
        </a-button>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">促销卡片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="卡片颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">活动标题</header>
      <a-form-model-item label="对齐" v-bind="horizonLayout">
        <a-radio-group v-model="res.titleStyle.textAlign" button-style="solid">
          <a-radio-button value="left">
            <a-icon type="align-left" />
          </a-radio-button>
          <a-radio-button value="center">
            <a-icon type="align-center" />
          </a-radio-button>
          <a-radio-button value="right">
            <a-icon type="align-right" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="斜体" v-bind="horizonLayout">
        <a-radio-group v-model="res.titleStyle.fontStyle" button-style="solid">
          <a-radio-button value="normal">无</a-radio-button>
          <a-radio-button value="italic">
            <a-icon type="italic" />
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="行间距" v-bind="horizonLayout">
        <slider-input v-model="res.titleStyle.lineHeight" :min="10" :max="60" />
      </a-form-model-item>
      <a-form-model-item label="字间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.titleStyle.letterSpacing"
          :min="0"
          :max="25"
        />
      </a-form-model-item>

      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.titleStyle.color" />
      </a-form-model-item>

      <header class="group">图片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input
          v-model="res.imageStyle.borderRadius"
          :min="0"
          :max="25"
        />
      </a-form-model-item>

      <header class="group">商品标题</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.productTitleStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select
          v-model="res.productTitleStyle.fontWeight"
          placeholder="请选择"
        >
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="行数" v-bind="horizonLayout">
        <a-select
          v-model="res.productTitleStyle['-webkitLineClamp']"
          placeholder="请选择"
        >
          <a-select-option value="1">1行</a-select-option>
          <a-select-option value="2">2行</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.productTitleStyle.color" />
      </a-form-model-item>

      <header class="group">价格</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.priceStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.priceStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.priceStyle.color" />
      </a-form-model-item>

      <header class="group">按钮</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.backgroundColor" />
      </a-form-model-item>
      <a-form-model-item label="文字颜色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.color" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.borderColor" />
      </a-form-model-item>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.btnStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="文字" v-bind="horizonLayout">
        <a-input v-model="res.btnText" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>

    <!-- 商品选择器 -->
    <PinDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="true"
    />
  </a-form-model>
</template>

<script>
import draggable from "vuedraggable";

import SliderInput from "@/components/slider-input";
import ColorPicker from "@/components/color-picker";

import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";
import uploadMixin from "@/mixins/upload";

import { randomKey } from "@/utils/tool";

export default {
  data() {
    return {
      drag: false,
      tipsKey: "hide-pin-tips"
    };
  },
  props: {
    res: Object
  },
  computed: {},

  mixins: [textMixin, tipsMixin, uploadMixin],

  methods: {
    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(vals) {
      // 去重
      for (const val of vals) {
        if (this.res.rows.findIndex(x => x.id === val.id) > -1) {
          continue;
        }

        const { id, productId, title, img, price, pinPrice, num } = val;
        this.res.rows.push({
          id,
          productId,
          title,
          img,
          price,
          pinPrice,
          num,

          key: randomKey()
        });
      }
    },
    delItem(index) {
      this.res.rows.splice(index, 1);
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable,

    SliderInput,
    ColorPicker
  }
};
</script>

<style lang="scss">
.pin-panel {
  .time-picker {
    width: 100%;
  }

  .config-panel & .config-product {
    margin: 0;
    padding: 0 10px;
    cursor: auto;
  }

  .config-panel & .config-product:hover {
    opacity: 1;
  }

  .attr {
    flex: 1;
    overflow: hidden;

    .price-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
    }
  }
}
</style>
