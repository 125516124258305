<template>
  <div class="card" :style="res.style">
    <template v-for="row in res.cards">
      <div :key="row.key" class="item" :class="'type-' + row.rows.length">
        <div
          v-for="(item, idx) in row.rows"
          :key="item.key"
          class="block"
          :class="'block-' + idx"
          :style="res.imageStyle"
        >
          <img v-if="item.img" :src="item.img" class="block-item" />
          <div v-else class="empty">
            <img class="icon" src="@/assets/icn-banner-default@3x.png" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    res: Object
  }
};
</script>

<style lang="scss">
.card {
  .item.type-1 {
    .block {
      display: flex;
      overflow: hidden;
      border-style: solid;
      border-width: 0px;
    }
  }

  .item.type-2 {
    display: flex;

    .block {
      flex: 1 1 50%;
      display: flex;
      overflow: hidden;
      border-style: solid;
      border-width: 0px;
    }

    .block.block-1 {
      .cover {
        height: 100% !important;
      }
    }
  }

  .item {
    .empty {
      min-height: 180px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .icon {
        width: 46px;
        height: 60px;
      }
    }

    .block-item {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .cover {
      width: 100%;
      background: transparent;
    }
  }
}
</style>
