<template>
  <a-form-model class="navi-panel">
    <!-- <a-form-model-item label="样式">
        <a-radio-group v-model="res.type">
          <a-radio :value="1">宫格入口</a-radio>
          <a-radio :value="2" disabled>更多样式</a-radio>
        </a-radio-group>
      </a-form-model-item> -->

    <draggable
      v-model="res.rows"
      handle="header"
      @start="drag = true"
      @end="drag = false"
      v-bind="{
        animation: 200
      }"
    >
      <transition-group
        type="transition"
        tag="div"
        :name="!drag ? 'flip-list' : null"
      >
        <section
          v-for="(row, index) in res.rows"
          :key="row.key"
          class="compact"
        >
          <header>
            <div class="label">
              <a-icon type="menu" class="drag-handler" /> 导航
            </div>
            <div class="pending"></div>
            <div @click="delRow(index)" class="opt">
              <a-icon type="close" />
            </div>
          </header>

          <a-row :gutter="4">
            <a-col :span="6">
              <a-upload
                :data="{ index: index }"
                listType="picture-card"
                :action="UPLOAD_URL"
                :headers="{ t: getToken }"
                withCredentials
                accept="image/*"
                :showUploadList="false"
                @change="handleUpload"
                :before-upload="beforeUpload"
              >
                <img v-if="row.icon" :src="row.icon" style="width:80px" />
                <div v-else>
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传图片
                  </div>
                </div>
              </a-upload>
            </a-col>
            <a-col :span="18">
              <a-form-model-item label="名称" v-bind="horizonLayout">
                <a-input
                  v-model="row.title"
                  :maxLength="20"
                  placeholder="填写分类名称"
                />
              </a-form-model-item>
              <a-form-model-item label="跳转" v-bind="horizonLayout">
                <a-select v-model="row.target" placeholder="请选择">
                  <a-select-option
                    v-for="t in targetList"
                    :key="t.id"
                    :value="t.id"
                    >{{ t.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <!-- 选择器 -->
              <a-form-model-item label="地址" v-bind="horizonLayout">
                <!-- 商品 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="row.target == 1"
                  v-model="row.productId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="p in productList"
                    :key="p.id"
                    :value="p.id"
                    >{{ p.title }}
                  </a-select-option>
                </a-select>

                <!-- 带货商品 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="row.target == 6"
                  v-model="row.productId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="p in productList2"
                    :key="p.id"
                    :value="p.id"
                    >{{ p.title }}
                  </a-select-option>
                </a-select>

                <!-- 优惠券 -->
                <a-select
                  v-if="row.target == 2"
                  v-model="row.couponId"
                  placeholder="请选择"
                  notFoundContent="请在小商店后台创建券"
                >
                  <a-select-option
                    v-for="c in couponList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.name }}
                  </a-select-option>
                </a-select>

                <!-- 分类 -->
                <a-tree-select
                  v-if="row.target == 3"
                  v-model="row.categoryId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="categoryTree"
                  placeholder="请选择"
                  notFoundContent="请在小商店后台创建分类"
                  tree-default-expand-all
                >
                </a-tree-select>

                <!-- 页面 -->
                <a-select
                  v-if="row.target == 4"
                  v-model="row.pageId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="c in pageList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.name }}
                  </a-select-option>
                </a-select>

                <!-- 拼团 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="row.target == 7"
                  v-model="row.pinId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="c in pinList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.title }}
                  </a-select-option>
                </a-select>

                <!-- 砍价 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="row.target == 8"
                  v-model="row.kanId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="c in kanList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.title }}
                  </a-select-option>
                </a-select>

                <!-- 分享有礼 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="row.target == 9"
                  v-model="row.shareId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="c in shareList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.title }}
                  </a-select-option>
                </a-select>

                <!-- 抽奖 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="row.target == 14"
                  v-model="row.prizeId"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="c in prizeList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.title }}
                  </a-select-option>
                </a-select>

                <!-- 招募推荐官 -->
                <a-input
                  v-if="row.target == 11"
                  v-model="row.planId"
                  :max-length="10"
                  type="number"
                  placeholder="请复制招募计划 planId"
                />

                <!-- 小程序 -->
                <a-input
                  v-if="row.target == 15"
                  v-model="row.minapp"
                  :max-length="255"
                  placeholder="appid#query 或 #小程序链接"
                />

                <!-- 网页 -->
                <a-input
                  v-if="row.target == 12"
                  v-model="row.url"
                  :max-length="200"
                  placeholder="请输入网页地址"
                />

                <!-- 拨打电话 -->
                <a-input
                  v-if="row.target == 20"
                  v-model="row.tel"
                  :max-length="100"
                  placeholder="请输入电话号码"
                />
                <!-- 复制文本 -->
                <a-input
                  v-if="row.target == 21"
                  v-model="row.text"
                  :max-length="100"
                  placeholder="请输入文本"
                />
                <a-upload
                  v-if="row.target == 22"
                  :data="{ index: 100+index }"
                  :action="UPLOAD_URL"
                  :headers="{ t: getToken }"
                  withCredentials
                  accept="image/*"
                  :showUploadList="false"
                  @change="handleUpload"
                  :before-upload="beforeUpload"
                  >
                  <img
                    v-if="row.qrcode"
                    class="upload-image-small"
                    :src="row.qrcode"
                  />
                  <a-button-group>
                    <a-button>
                      <a-icon type="upload" />
                      {{ row.qrcode ? "更换" : "上传" }}图片
                    </a-button>
                  </a-button-group>
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
        </section>
      </transition-group>
    </draggable>

    <a-form-model-item>
      <a-button style="width: 100%" @click="addRow">
        <a-icon type="plus" /> 新增分类
      </a-button>
    </a-form-model-item>

    <section>
      <header class="group">图标</header>
      <a-form-model-item label="图标比例" v-bind="horizonLayout">
        <a-radio-group
          v-model="res.iconStyle.rate"
          @change="
            evt =>
              (res.iconStyle.height = 54 * parseFloat(evt.target.value) + 'px')
          "
          button-style="solid"
        >
          <a-radio-button value="1">1:1</a-radio-button>
          <a-radio-button value="0.666">3:2</a-radio-button>
          <a-radio-button value="1.333">3:4</a-radio-button>
          <a-radio-button value="0.563">16:9</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.iconStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.iconStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.iconStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.iconStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.iconStyle.borderColor" />
      </a-form-model-item>

      <header class="group">标题</header>
      <a-form-model-item label="标题字号" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题粗细" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="图文间距" v-bind="horizonLayout">
        <slider-input v-model="res.titleStyle.marginTop" :min="0" :max="60" />
      </a-form-model-item>
      <a-form-model-item label="标题下边距" v-bind="horizonLayout">
        <slider-input
          v-model="res.titleStyle.marginBottom"
          :min="0"
          :max="60"
        />
      </a-form-model-item>
      <a-form-model-item label="标题颜色" v-bind="horizonLayout">
        <color-picker v-model="res.titleStyle.color" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
const DEFAULT_CATEGORY = {
  title: "分类",
  icon: "",

  target: 3,
  productId: null,
  couponId: null,
  categoryId: null,
  pageId: null
};

import draggable from "vuedraggable";

import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

import textMixin from "@/mixins/text";
import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import uploadMixin from "@/mixins/upload";
import targetMixin from "@/mixins/target";

import { randomKey } from "@/utils/tool";

export default {
  data() {
    return {
      rowIndex: 0,

      drag: false
    };
  },
  props: {
    res: Object
  },
  computed: {
    dragOptions() {
      return {
        animation: 200
      };
    }
  },

  mixins: [
    textMixin,

    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin,
    uploadMixin,
    targetMixin
  ],

  methods: {
    addRow() {
      this.res.rows.push({ ...DEFAULT_CATEGORY, key: randomKey() });
    },
    delRow(index) {
      this.res.rows.splice(index, 1);

      // 默认一条记录
      if (this.res.rows.length <= 0) {
        this.addRow();
      }
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        // 背景图片
        if (index === 9999) {
          this.res.backgroundImage = url;
          this.res.style.backgroundImage = `url(${url})`;
          return;
        }

        if(index >= 100){
          const idx = index - 100
          const obj = this.res.rows[idx];
          const newValue = { ...obj, qrcode: url };
          this.$set(this.res.rows, idx, newValue);
        } else {
          const obj = this.res.rows[index];
          const newValue = { ...obj, icon: url };
          this.$set(this.res.rows, index, newValue);
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable,

    ColorPicker,
    SliderInput
  }
};
</script>

<style lang="scss">
.navi-panel {
  section.compact {
    .ant-upload.ant-upload-select-picture-card {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
