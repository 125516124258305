<template>
  <a-form-model>
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
    >
      <p slot="description">
        首次使用请前往「营销互动」 -> 「抽奖活动」，创建活动。<router-link
          :to="{ name: 'PrizeList' }"
          >点击前往抽奖活动 >>
        </router-link>
      </p>
    </a-alert>

    <section>
      <a-form-model-item label="活动" v-bind="horizonLayout">
        <a-button @click="openProductDialog">
          {{ res.prizeId ? "已选1个活动" : "选择抽奖活动" }}
        </a-button>
      </a-form-model-item>

      <a-form-model-item label="样式" v-bind="horizonLayout">
        <a-radio-group v-model="res.type" button-style="solid">
          <a-radio-button :value="1">大图</a-radio-button>
          <a-radio-button :value="2">小图</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
    </section>

    <section>
      <header class="group">卡片</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.padding" :min="0" :max="15" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>

      <a-form-model-item label="卡片背景" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">文字颜色</header>
      <a-form-model-item label="标题" v-bind="horizonLayout">
        <color-picker v-model="res.titleStyle.color" />
      </a-form-model-item>
      <a-form-model-item label="描述" v-bind="horizonLayout">
        <color-picker v-model="res.descStyle.color" />
      </a-form-model-item>

      <header class="group">按钮</header>
      <a-form-model-item label="文字颜色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.color" />
      </a-form-model-item>
      <a-form-model-item label="按钮背景" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="value => (res.style.paddingBottom = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
    <!-- 商品选择器 -->
    <PrizeDialog
      id="productDialog"
      ref="productDialog"
      v-on:selected="addItem"
      :multiple="false"
    />
  </a-form-model>
</template>

<script>
import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";
import uploadMixin from "@/mixins/upload";

import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

export default {
  data() {
    return {
      tipsKey: "hide-prize-tips"
    };
  },

  props: { res: Object },

  computed: {},

  mixins: [textMixin, tipsMixin, uploadMixin],

  methods: {
    openProductDialog() {
      this.$refs.productDialog.show();
    },

    addItem(val) {
      const { id, shareImg, title, prizeText } = val;

      this.res.prizeId = id;
      this.res.img = shareImg;
      this.res.title = title;
      this.res.prizeText = `奖品：${prizeText}`;
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    ColorPicker,
    SliderInput
  }
};
</script>

<style></style>
