<template>
  <div class="app-mob">
    <div
      v-for="row in list"
      :key="row.id"
      class="item"
      @click="applyMob(row.id)"
    >
      <img slot="cover" :src="row.img" />
      <div>{{ row.name }}</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

const TemplateSvc = require("@/service/template");

const STYLE_LIST = [
  "style",
  "boxStyle",
  "imageStyle",
  "iconStyle",
  "textStyle",
  "titleStyle",
  "priceStyle",
  "btnStyle",
  "flagStyle",
  "videoStyle",
  "numStyle",
  "num2Style",
  "valueStyle",
  "descStyle"
];

export default {
  data() {
    return {
      list: []
    };
  },

  computed: {},

  methods: {
    ...mapMutations({ setDesignData: "setDesignData" }),

    // 套用模板
    async applyMob(id) {
      // try {
      let tmp = await TemplateSvc.getData(id);
      tmp = JSON.parse(tmp.res);

      let data = JSON.parse(JSON.stringify(this.$store.state.design));

      // 没有任何内容时，直接复制模板
      if (
        (data.list || []).length <= 0 &&
        Object.keys(data.fix || {}).length <= 0
      ) {
        this.setDesignData(tmp);
        return;
      }

      // 记录不同类型出现顺序，实际保存下一个位置
      let idxOfCls = {};

      for (let key of ["list", "fix"]) {
        for (let i = 0, n = data[key].length; i < n; i++) {
          let row = data[key][i];

          // 类型、布局一致
          // let _f = tmp.list.filter(
          //   x =>
          //     x.cls === row.cls && (x.res || {}).type === (row.res || {}).type
          // );
          // if (_f.length <= 0) {
          // 找不到完全一致，则仅匹配类型
          let _f = tmp.list.filter(x => x.cls === row.cls);
          // }

          if (_f.length > 0) {
            // 按类型顺序取配置样式，没有则取第一个
            const idx = idxOfCls[row.cls] || 0;
            const f = _f[idx] || _f[0]; // 直接取第一个组件
            idxOfCls[row.cls] = idx + 1;

            if (!f.res) continue;

            // 单独特性 TODO 测试
            switch (row.cls) {
              case "group":
                {
                  for (const spec of [
                    "type",
                    "showTitle",
                    "showSubTitle",
                    "showPrice",
                    "showFlag",
                    "showBuyButton",
                    "priceAndBtnAlign",
                    "lines"
                  ]) {
                    row.res[spec] = f.res[spec] || row.res[spec];
                  }
                }
                break;
              case "title-text":
                {
                  for (const spec of ["backgroundImage"]) {
                    row.res[spec] = f.res[spec] || row.res[spec];
                  }
                }
                break;
            }

            for (const style of STYLE_LIST) {
              if (f.res[style]) {
                row.res[style] = { ...row.res[style], ...f.res[style] };
              }
            }
          }

          data[key][i] = row;
        }
      }

      this.setDesignData(data);
      // } catch (err) {
      //   console.error(err);
      //   this.$message.error("应用模板失败，错误：" + err.message);
      // }
    }
  },

  async mounted() {
    try {
      this.list = await TemplateSvc.list();
    } catch (err) {
      console.error(err);
    }
  }
};
</script>

<style lang="scss">
.app-mob {
  background-color: #fff;
  padding: 20px 0;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-radius: 10px;

    font-size: 13px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: rgba(0, 0, 0, 0.66);

    img {
      width: 106px;
      margin-bottom: 6px;
    }

    &:hover {
      background-color: #ddd;
    }
  }
}
</style>
