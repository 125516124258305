<template>
  <Grid v-if="res.type == 1" :res="res" />
</template>

<script>
import Grid from "./grid";

export default {
  props: {
    res: Object
  },
  components: {
    Grid
  }
};
</script>

<style></style>
