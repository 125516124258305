<template>
  <div class="preview-wrap">
    <div v-if="isEmpty" class="steps">
      <h2>装修流程</h2>
      <img src="@/assets/steps@2x.png" />
    </div>

    <draggable
      v-model="list"
      @start="drag = true"
      @end="endDrag"
      :move="checkMove"
      animation="200"
      :group="{
        name: 'preview',
        pull: ['preview'],
        put: ['preview', 'toolbox']
      }"
    >
      <transition-group
        tag="div"
        class="list-group"
        type="transition"
        :name="!drag ? 'flip-list' : null"
      >
        <div
          v-for="(item, index) in list"
          :key="item.key"
          @click.stop="select(index, $event)"
          class="module"
          :class="index == current ? 'active' : null"
        >
          <component :is="item.cls" :res="item.res" />
          <div v-if="canCopy(item.cls)" class="tool">
            <a-button
              @click.stop="copy(index)"
              title="复制"
              type="dashed"
              icon="copy"
              size="small"
            />
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
const VueScrollTo = require("vue-scrollto");

import Banner from "./banner/index.vue";
import Card from "./card/index.vue";
import Notice from "./notice/index.vue";
import Friend from "./friend.vue";
import Live from "./live.vue";
import Coupon from "./coupon.vue";
import Group from "./group/index.vue";
import Category from "./category/index.vue";
import Navi from "./navi/index.vue";
import Product from "./product.vue";
import TitleText from "./title-text.vue";
import Spacer from "./spacer.vue";
import Search from "./search.vue";
import Richtext from "./richtext.vue";
import Movie from "./movie.vue";
import LiveBar from "./live-bar.vue";
import TimeSale from "./time-sale/index.vue";
import Counter from "./counter.vue";
import Cat from "./cat.vue";
import Pin from "./pin/index.vue";
import Kan from "./kan/index.vue";
import Vip from "./vip.vue";
import HotZone from "./hotzone.vue";
import Share from "./share.vue";
import Qun from "./qun.vue";
import Cube from "./cube.vue";
import Prize from "./prize/index.vue";

import draggable from "vuedraggable";

import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      drag: false
    };
  },
  props: {},

  computed: {
    ...mapState({
      designData: state => state.design,
      current: state => state.design.current
    }),
    list: {
      get() {
        return this.$store.state.design.list;
      },
      set(value) {
        this.$store.commit("sortList", value);
      }
    },

    isEmpty() {
      const { list, fix } = this.designData;
      return list.length <= 0 && Object.keys(fix).length <= 0;
    },

    dragOptions() {
      return {
        animation: 200
      };
    }
  },

  methods: {
    ...mapMutations({
      selectElement: "selectElement",
      copy: "copyElement"
    }),

    select(index, event) {
      // 根据所在位置自动判断是否自动滚动到元素
      const {
        y: innerY,
        bottom: innerBottom
      } = event.currentTarget.getBoundingClientRect();
      const container =
        event.currentTarget.parentElement.parentElement.parentElement
          .parentElement.parentElement;
      const {
        y: outerY,
        bottom: outerBottom
      } = container.getBoundingClientRect();

      if (innerY < outerY || innerBottom > outerBottom) {
        // 自动滚动到顶部
        this.$nextTick(() => {
          var options = {
            container: ".module-preview",
            easing: "ease-in-out",
            offset: -60
          };
          VueScrollTo.scrollTo(event.currentTarget, 500, options);
        });
      }

      this.selectElement(index);
    },

    checkMove: function(evt) {
      const srcCls = evt.draggedContext.element.cls,
        destCls = evt.relatedContext.element.cls;

      // 全部商品只能放置在最后
      const CLS = ["product", "cat"];
      return !CLS.includes(srcCls) && !CLS.includes(destCls);
    },
    endDrag(evt) {
      this.drag = false;
      this.selectElement(evt.newDraggableIndex);
    },

    canCopy(cls) {
      return !["product", "cat"].includes(cls);
    }
  },

  components: {
    draggable,

    Banner,
    Card,
    Notice,
    Friend,
    Live,
    Coupon,
    Group,
    Category,
    Navi,
    Product,
    TitleText,
    Spacer,
    Search,
    Richtext,
    Movie,
    LiveBar,
    TimeSale,
    Counter,
    Cat,
    Pin,
    Kan,
    Vip,
    HotZone,
    Share,
    Qun,
    Cube,
    Prize
  }
};
</script>

<style lang="scss">
.preview-wrap {
  .steps {
    display: flex;
    flex-direction: column;
    margin-top: 64px;

    position: absolute;
    left: 0;
    right: 0;

    h2 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
    img {
      margin: 20px 20px 0;
      width: 335px;
    }
  }

  .list-group:empty,
  .list-group > div:empty {
    padding: 1rem;
    text-align: center;
  }

  .list-group:empty:before,
  .list-group > div:empty:before {
    content: "";
    display: block;
    height: 400px;
  }

  .module {
    // margin-bottom: 7px;
    cursor: pointer;
    outline: 0;
    position: relative;

    &:hover {
      opacity: 0.8;
    }
    &.active {
      padding: 2px;
      background-image: linear-gradient(90deg, silver 50%, transparent 50%),
        linear-gradient(90deg, silver 50%, transparent 50%),
        linear-gradient(0deg, silver 50%, transparent 50%),
        linear-gradient(0deg, silver 50%, transparent 50%);
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
      background-position: left top, right bottom, left bottom, right top;
      animation: border-dance 1s infinite linear;
    }

    .tool {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &:hover .tool {
      display: inherit;
    }
  }

  .drag-placeholder {
    background: rgb(255, 254, 218);
    overflow: hidden;
    opacity: 0.8;

    img {
      display: none;
    }

    & > div {
      text-align: center;
      line-height: 3em;
      font-weight: bold;
    }
  }
}
</style>
