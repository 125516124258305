<template>
  <div class="count-down">
    <span class="time-str">{{ tips }}</span>
    <div class="time">
      <template v-if="cnt.day > 0">
        <span class="n">{{ cnt.day }}</span> 天
      </template>
      <template v-if="cnt.hour != '00'">
        <span class="n">{{ cnt.hour }}</span>
        :
      </template>
      <span class="n">{{ cnt.min }}</span>
      :
      <span class="n">{{ cnt.sec }}</span>
    </div>
  </div>
</template>

<script>
const timeFormat = param => {
  return param < 10 ? "0" + param : param;
};

export default {
  props: {
    startTime: String,
    endTime: String,
    state: String
  },

  model: {
    prop: "state",
    event: "change"
  },

  data() {
    return {
      interval: null,

      tips: "已结束",
      cnt: {
        day: 0,
        hour: "00",
        min: "00",
        sec: "00"
      }
    };
  },

  watch: {
    startTime: function(val) {
      this.countDown(val, this.endTime);
    },
    endTime: function(val) {
      this.countDown(this.startTime, val);
    }
  },

  mounted() {
    this.countDown(this.startTime, this.endTime);
  },

  methods: {
    countDown(_startTime, _endTime) {
      if (!_startTime && !_endTime) {
        this.tips = "已结束";
        this.cnt = {
          day: 0,
          hour: "00",
          min: "00",
          sec: "00"
        };
        clearInterval(this.interval);

        this.$emit("change", "end");
        return;
      }

      let startTime = new Date(_startTime).getTime();
      let endTime = new Date(_endTime).getTime();

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        let now = new Date().getTime();

        let ts = "";
        let evt = "";
        if (startTime - now > 0) {
          ts = startTime;
          this.tips = "距开始";
          evt = "start";
        } else if (endTime - now > 0) {
          ts = endTime;
          this.tips = "距结束";
          evt = "counting";
        } else {
          ts = null;
          this.tips = "已结束";
          evt = "end";
        }

        if (ts) {
          let time = (ts - now) / 1000; // 获取天、时、分、秒
          let day = parseInt(time / (60 * 60 * 24));
          let hour = parseInt((time % (60 * 60 * 24)) / 3600);
          let min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
          let sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
          this.cnt = {
            day,
            hour: timeFormat(hour),
            min: timeFormat(min),
            sec: timeFormat(sec)
          };
        } else {
          // 活动已结束，全部设置为'00'
          this.cnt = {
            day: 0,
            hour: "00",
            min: "00",
            sec: "00"
          };
          clearInterval(this.interval);
        }

        this.$emit("change", evt);
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.count-down {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;

  .time-str {
    margin-right: 5px;
  }

  .time {
    display: flex;
    align-items: center;
    color: #202020;

    .n {
      width: 22px;
      height: 22px;
      background-color: rgb(32, 32, 32);
      color: #fff;
      margin: 0 2px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 700;
    }
  }
}
</style>
