<template>
  <a-row :gutter="10" type="flex" justify="space-between">
    <a-col flex="1">
      <a-slider :value="size" @change="handleInput" :min="min" :max="max" />
    </a-col>
    <a-col>
      <a-input-number
        :formatter="handleFormat"
        :value="size"
        @change="handleInput"
        :min="min"
        :max="max"
      />
    </a-col>
  </a-row>
</template>

<script>
export default {
  data() {
    return {};
  },

  model: {
    prop: "sizeText",
    event: "change"
  },

  props: {
    sizeText: String,

    min: Number,
    max: Number,

    suffix: {
      type: String,
      default: "px"
    }
  },

  computed: {
    size() {
      return parseInt(this.sizeText.split(this.suffix)[0]);
    }
  },

  methods: {
    handleFormat(value) {
      return `${value}${this.suffix}`;
    },

    handleInput(val) {
      this.$emit("change", val + this.suffix);
    }
  }
};
</script>

<style></style>
