<template>
  <a-form-model>
    <a-alert
      v-if="!hideTips"
      style="margin-bottom:10px;"
      message="提示"
      type="info"
      closable
      @close="onCloseTips"
    >
      <p slot="description">
        请先在小商店后台创建优惠券，然后在这里选择优惠券。
      </p>
    </a-alert>
    <div></div>
    <a-form-model-item label="样式" v-bind="horizonLayout">
      <a-radio-group v-model="res.type" button-style="solid">
        <a-radio-button :value="1">样式一</a-radio-button>
        <a-radio-button :value="2">样式二</a-radio-button>
        <a-radio-button :value="3">样式三</a-radio-button>
      </a-radio-group>
    </a-form-model-item>

    <draggable
      v-model="res.rows"
      handle="header"
      @start="drag = true"
      @end="drag = false"
      v-bind="{
        animation: 200
      }"
    >
      <transition-group
        type="transition"
        tag="div"
        :name="!drag ? 'flip-list' : null"
      >
        <section
          v-for="(coupon, index) in res.rows"
          :key="coupon.key"
          class="compact"
        >
          <header>
            <div class="label">
              <a-icon type="menu" class="drag-handler" /> 优惠券
            </div>
            <div class="pending"></div>
            <div @click="removeItem(index)" class="opt">
              <a-icon type="close" />
            </div>
          </header>

          <a-form-model-item
            label="选择券"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-select
              v-model="coupon.id"
              @change="changeCoupon(index, $event)"
              placeholder="请选择"
              notFoundContent="请在小商店后台创建优惠券"
            >
              <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            label="券名称"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-input
              v-model="coupon.name"
              :maxLength="20"
              placeholder="填写优惠券名称"
              type="text"
            />
          </a-form-model-item>

          <a-form-model-item
            label="优惠详情"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
          >
            <a-row v-if="[1, 101].includes(coupon.type)" :gutter="10">
              <a-col :span="12">
                <a-input
                  v-model="coupon.num1"
                  :maxLength="20"
                  type="text"
                  min="0"
                  prefix="满"
                  suffix="件"
                />
              </a-col>
              <a-col :span="12">
                <a-input
                  v-model="coupon.num2"
                  :maxLength="20"
                  type="text"
                  min="0"
                  prefix="打"
                  suffix="折"
                />
              </a-col>
            </a-row>
            <a-row v-if="[2, 102].includes(coupon.type)" :gutter="10">
              <a-col :span="12">
                <a-input
                  v-model="coupon.num1"
                  :maxLength="20"
                  type="text"
                  min="0"
                  prefix="满"
                  suffix="元"
                />
              </a-col>
              <a-col :span="12">
                <a-input
                  v-model="coupon.num2"
                  :maxLength="20"
                  type="text"
                  min="0"
                  prefix="减"
                  suffix="元"
                />
              </a-col>
            </a-row>
            <div v-if="[3, 103].includes(coupon.type)">
              <a-input
                v-model="coupon.num2"
                :maxLength="20"
                type="text"
                min="0"
                suffix="折"
              />
            </div>
            <div v-if="[4, 104].includes(coupon.type)">
              <a-input
                v-model="coupon.num2"
                :maxLength="20"
                type="text"
                min="0"
                prefix="减"
                suffix="元"
              />
            </div>
          </a-form-model-item>
        </section>
      </transition-group>
    </draggable>

    <a-form-model-item>
      <a-button style="width: 100%" @click="addItem">
        <a-icon type="plus" /> 新增优惠券
      </a-button>
    </a-form-model-item>

    <section>
      <header class="group">券面</header>
      <a-form-model-item label="圆角" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderRadius" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="间距" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.padding" :min="0" :max="50" />
      </a-form-model-item>
      <a-form-model-item label="阴影强度" v-bind="horizonLayout">
        <slider-input
          v-model="res.boxStyle.boxShadowValue"
          :min="0"
          :max="25"
          @change="
            value =>
              (res.boxStyle.boxShadow =
                value != '0px' ? `0 1px ${value} rgba(0, 0, 0,0.1)` : 'none')
          "
        />
      </a-form-model-item>
      <a-form-model-item label="边框宽度" v-bind="horizonLayout">
        <slider-input v-model="res.boxStyle.borderWidth" :min="0" :max="25" />
      </a-form-model-item>
      <a-form-model-item label="边框颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.borderColor" />
      </a-form-model-item>
      <a-form-model-item label="券面颜色" v-bind="horizonLayout">
        <color-picker v-model="res.boxStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">券名称</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.titleStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="行间距" v-bind="horizonLayout">
        <slider-input v-model="res.titleStyle.lineHeight" :min="10" :max="60" />
      </a-form-model-item>
      <a-form-model-item label="字间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.titleStyle.letterSpacing"
          :min="0"
          :max="25"
        />
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.titleStyle.color" />
      </a-form-model-item>

      <header class="group">折扣</header>
      <a-form-model-item label="字号" v-bind="horizonLayout">
        <a-select v-model="res.valueStyle.fontSize" placeholder="请选择">
          <a-select-option v-for="f in fontSizeList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="粗细" v-bind="horizonLayout">
        <a-select v-model="res.valueStyle.fontWeight" placeholder="请选择">
          <a-select-option v-for="f in fontWeightList" :key="f.id" :value="f.id"
            >{{ f.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="行间距" v-bind="horizonLayout">
        <slider-input v-model="res.valueStyle.lineHeight" :min="10" :max="60" />
      </a-form-model-item>
      <a-form-model-item label="字间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.valueStyle.letterSpacing"
          :min="0"
          :max="25"
        />
      </a-form-model-item>
      <a-form-model-item label="颜色" v-bind="horizonLayout">
        <color-picker v-model="res.valueStyle.color" />
      </a-form-model-item>
      <!-- <a-form-model-item label="详情" v-bind="horizonLayout">
        <color-picker v-model="res.descStyle.color" />
      </a-form-model-item> -->

      <header class="group">按钮</header>
      <a-form-model-item label="文字色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.color" />
      </a-form-model-item>
      <a-form-model-item label="背景色" v-bind="horizonLayout">
        <color-picker v-model="res.btnStyle.backgroundColor" />
      </a-form-model-item>

      <header class="group">背景</header>
      <a-form-model-item label="背景颜色" v-bind="horizonLayout">
        <color-picker v-model="res.style.backgroundColor" />
      </a-form-model-item>
      <a-form-item label="背景图片" v-bind="horizonLayout">
        <a-upload
          :data="{ index: 9999 }"
          listType="picture-card"
          :action="UPLOAD_URL"
          :headers="{ t: getToken }"
          withCredentials
          accept="image/*"
          :showUploadList="false"
          @change="handleUpload"
          :before-upload="beforeUpload"
        >
          <div v-if="res.backgroundImage" class="imageBox">
            <img :src="res.backgroundImage" style="width:80px" />
            <img
              @click.stop="removeBackImage"
              class="del-icon highlight"
              src="@/assets/icn-delete-popup@3x.png"
            />
          </div>
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传图片
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-model-item label="填充模式" v-bind="horizonLayout">
        <a-radio-group v-model="res.style.backgroundSize" button-style="solid">
          <a-radio-button value="cover">封面</a-radio-button>
          <a-radio-button value="contain">包含</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <header class="group">间距</header>
      <a-form-model-item label="左右间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingLeft"
          @change="value => (res.style.paddingRight = value)"
          :min="0"
          :max="100"
        />
      </a-form-model-item>
      <a-form-model-item label="上下间距" v-bind="horizonLayout">
        <slider-input
          v-model="res.style.paddingTop"
          @change="
            value => {
              const val = parseInt(value);
              if (val >= 10) {
                res.style.paddingBottom = val - 10 + 'px';
                res.style.marginBottom = '0px';
              } else {
                res.style.paddingBottom = '0px';
                res.style.marginBottom = val - 10 + 'px'; // Math.min(parseInt(value), 0) - 10 + 'px';
              }
              // console.log(value, parseInt(value));
            }
          "
          :min="0"
          :max="100"
        />
      </a-form-model-item>
    </section>
  </a-form-model>
</template>

<script>
const DEFAULT_COUPON = {
  name: "",
  type: 1,
  num1: "",
  num2: ""
};

import draggable from "vuedraggable";

import ColorPicker from "@/components/color-picker";
import SliderInput from "@/components/slider-input";

import couponMixin from "@/mixins/coupon";
import textMixin from "@/mixins/text";
import tipsMixin from "@/mixins/tips";
import uploadMixin from "@/mixins/upload";

import { randomKey } from "@/utils/tool";

export default {
  data() {
    return {
      drag: false,
      tipsKey: "hide-coupon-tips"
    };
  },

  props: {
    res: Object
  },

  computed: {},

  mixins: [textMixin, couponMixin, tipsMixin, uploadMixin],

  async mounted() {},

  methods: {
    addItem() {
      this.res.rows.push({ ...DEFAULT_COUPON, key: randomKey() });
    },
    removeItem(index) {
      this.res.rows.splice(index, 1);

      // 默认一条记录
      if (this.res.rows.length <= 0) {
        this.addItem();
      }
    },

    changeCoupon(index, couponId) {
      const coupon = this.res.rows[index];
      const { name, type, num1, num2 } = this.couponList.filter(
        x => x.id === couponId
      )[0];
      this.$set(this.res.rows, index, { ...coupon, name, type, num1, num2 });
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.res.backgroundImage = url;
        this.res.style.backgroundImage = `url(${url})`;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    draggable,

    ColorPicker,
    SliderInput
  }
};
</script>

<style></style>
